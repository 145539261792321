import React, { useEffect, useState, createContext } from "react";
import { useSearchParams } from "react-router-dom";

const LockedModeDefaultData = {
	lockedMode: false,
};

export const LockedModeContext = createContext(LockedModeDefaultData);

const LockedModeProvider = props => {
    const [searchParams] = useSearchParams();

    const [lockedMode, setLockedMode] = useState(false);

	useEffect(() => {
		const embedMode = searchParams.get("view");
		if (embedMode) {
			setLockedMode(true);
		}
	}, []);

	return (
		<LockedModeContext.Provider value={{ lockedMode }}>
			{props.children}
		</LockedModeContext.Provider>
	);
};

export default LockedModeProvider;
