import moment from "moment";
import { Box, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import OutboundIcon from "@material-ui/icons/Outbound";
import { numberFormat } from "src/helpers/number.helper";
import { useCurrencyState } from "src/state";
import Countdown from "src/components/CountdownTimer";

const ListingBuyBlock = ({
	listing,
	minBidAmount,
	isInProgress,
	isOwner,
	isWrongChain,
	onClickBuyButton,
	onClickApproveButton,
}) => {
	const { getExRateInUSD } = useCurrencyState();

	const listingActive = moment().diff(moment(listing.endDate + " 22:59:59")) < 0;

	return (
		<>
			{!listing.purchased && (
				<div className="browse-bid-detail">
					<div className="browse-bid-box">
						{listing.listingType === "sale" ? (
							<>
								<h2>
									Listing Price:{" "}
									<span>
										{numberFormat(listing.amount, 0)} {listing.currency}
									</span>
								</h2>
								<p className="theme-description">
									${numberFormat(listing.amount * getExRateInUSD(listing.currency))}
								</p>
							</>
						) : (
							<>
								<Box sx={{ display: "flex", justifyContent: "space-between" }}>
									<h2>
										Current Bid:{" "}
										<span>
											{listing.currency} {minBidAmount}
										</span>
									</h2>
									<Tooltip
										title="The highest bidder will win the item at the end of the auction."
										placement="top"
									>
										<OutboundIcon
											sx={{
												verticalAlign: "middle",
												ml: 1,
											}}
										/>
									</Tooltip>
								</Box>
								<p className="theme-description">
									${numberFormat(minBidAmount * getExRateInUSD(listing.currency))}
								</p>
							</>
						)}
						{!isOwner && !isWrongChain && (!listing.endDate || listingActive) && (
							<Box my={2} className="browse-buy-btn">
								<button className="theme-btn" onClick={!isInProgress ? onClickBuyButton : () => {}}>
									{isInProgress ? (
										<CircularProgress size={20} color="inherit" />
									) : listing.listingType === "sale" ? (
										`Buy Now - ${numberFormat(listing.amount, 0)} ${listing.currency}`
									) : (
										`Bid Now - ${Math.round((minBidAmount + 0.01) * 100) / 100} ${listing.currency}`
									)}
								</button>
							</Box>
						)}
						{isOwner && listing.listingType === "auction" && (
							<Box my={2} className="browse-buy-btn">
								{listingActive ? (
									<Typography
										sx={{
											textAlign: "center",
										}}
									>
										** Your Auction is in progress. **
									</Typography>
								) : !isWrongChain ? (
									<Box my={2} className="browse-buy-btn">
										<button
											className="theme-btn"
											onClick={!isInProgress && !isWrongChain ? onClickApproveButton : () => {}}
										>
											{isInProgress ? (
												<CircularProgress size={20} color="inherit" />
											) : (
												`Approve Winner`
											)}
										</button>
									</Box>
								) : (
									""
								)}
							</Box>
						)}
						{listing.endDate && (
							<Box mt={2} className="browse-detail-timer">
								{listingActive ? (
									<>
										<div className="browse-time-left">
											<Countdown date={new Date(listing.endDate)} />
										</div>
										<div className="browse-time-right">
											<h2>Time is Running Out!</h2>
										</div>
									</>
								) : (
									<div className="browse-time-right">
										<h2>This listing is no longer available!</h2>
									</div>
								)}
							</Box>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default ListingBuyBlock;
