export const CURRENCIES = [
  {
    symbol: "BUSD",
    contract: {
      1: "0x4fabb145d64652a948d72533023f6e7a623c7c53",
      56: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      97: "0x7Cc0B51E8DEDe17c85238Ff3721eA8dBCC00798f",
      137: "0x9fb83c0635de2e815fd1c21b3a292277540c2e8d",
    },
  },
  {
    symbol: "USDT",
    contract: {
      1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      4: "0xd92e713d051c37ebb2561803a3b5fbabc4962431",
      56: "0x55d398326f99059ff775485246999027b3197955",
      137: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      80001: "0x4aaeb0c6523e7aa5adc77ead9b031ccdea9cb1c3",
    },
  },
  {
    symbol: "USDC",
    contract: {
      1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      56: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      137: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      80001: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    },
  },
  {
    symbol: "BUSD",
    contract: {
      56: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    }
  },
  {
    symbol: "MUSD",
    contract: {
      97: "0x6BCdf8B9aD00F2f6a1EA1F537d27DdF92eF99f88"
    }
  },
  {
    symbol: "TRON",
    contract: {
      56: "0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B"
    }
  },
  { 
    symbol: "WBNB",
    contract: {
      56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
  },
  {
    symbol: "WETH",
    contract: {
      1: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      3: "0x7E0480Ca9fD50EB7A3855Cf53c347A1b4d6A2FF5",
      56: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      137: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
      80001: "0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa",
    },
  },
  {
    symbol: "DERC20",
    contract: {
      1: "",
      137: "",
      80001: "0xfe4f5145f6e09952a5ba9e956ed0c25e3fa4c7f1",
    },
  },
  {
    symbol: "MATIC",
    contract: {
      1: "",
      137: "",
      80001: "0x2d7882bedcbfddce29ba99965dd3cdf7fcb10a1e",
    },
  },
  {
    symbol: "TST",
    contract: {
      1: "",
      137: "",
      80001: "0x2d7882bedcbfddce29ba99965dd3cdf7fcb10a1e",
    },
  },
];
