import moment from "moment";
import { numberToString } from "src/helpers/number.helper";
import styles from "./timeline.module.css";

const Timeline = ({ title = "Project Timeline", startsAt, scheudle }) => {
	return (
		<section className={`${styles.ps_timeline_sec} container`}>
			<h2>{title}</h2>
			<div className={styles.container}>
				<ol className={styles.ps_timeline}>
					<li style={{ width: `${100 / (scheudle.length + 1)}%` }}>
						<div className={styles.item_container}>
							<div className={styles.img_handler_bot}>{moment.unix(startsAt).format("DD MMM YYYY")}</div>
							<div className={styles.ps_top}>
								<p>
									<strong>Contract Starts</strong>
								</p>
							</div>
						</div>
						<span className={styles.ps_sp_bot}>01</span>
					</li>
					{scheudle.map((item, index) => (
						<li key={`${index}-schedule`} style={{ width: `${100 / (scheudle.length + 1)}%` }}>
							<div className={styles.item_container}>
								<div className={index % 2 ? styles.img_handler_bot : styles.img_handler_top}>
									{moment.unix(item.timestamp).format("DD MMM YYYY")}
								</div>
								<div className={index % 2 ? styles.ps_top : styles.ps_bot}>
									<p>
										<strong>
											{index === scheudle.length - 1 ? "Final" : numberToString(index + 1)} Payout
										</strong>
										{item.description && (
											<>
												<br />
												{item.description}
											</>
										)}
									</p>
								</div>
							</div>
							<span className={index % 2 ? styles.ps_sp_bot : styles.ps_sp_top}>
								{("0" + (index + 2)).slice(-2)}
							</span>
						</li>
					))}
				</ol>
			</div>
		</section>
	);
};

export default Timeline;
