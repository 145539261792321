import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import BlockchainProvider from "src/providers/BlockchainProvider";
import LockedModeProvider from "src/providers/LockedModeProvider";
import GlobalStyles from "src/components/GlobalStyles";
import Modal from "src/components/Modal";
import theme from "src/themes/default";
import routes from "src/routes";
import { useErrorState, useSessionState } from "src/state";
import "./App.css";
import { fetchDefaultSession } from "./db/firebase/users";
import { useEffect } from "react";

const App = () => {
	const { loggedInUser, setLoggedInUser } = useSessionState();

	useEffect(() => {
		console.log('here here')
		fetchDefaultSession().then(user => {
			if (user && user.userID)
				setLoggedInUser(user);
		})
	}, [])

	const routing = useRoutes(routes(loggedInUser));
	const { isError, errorMessage, clearError } = useErrorState();

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<LockedModeProvider>
				<BlockchainProvider>{routing}</BlockchainProvider>
			</LockedModeProvider>
			<Modal open={isError} onClose={clearError} title="Sorry!" summary={errorMessage} showOkButton={true} />
		</ThemeProvider>
	);
};

export default App;
