import { createState, useState } from "@hookstate/core";
import { logout as firebaseLogout } from "src/plugins/FirebaseAuth";

import { fetchUserProfile as dbFetchUserProfile, saveUserProfile } from "src/db/firebase/users";

// get from localstorage if previously logged in
const persistantState = JSON.parse(localStorage.getItem("loggedInAs"));

const defaultSessionState = persistantState ? persistantState : { user: null };

const sessionState = createState({ ...defaultSessionState });

export function useSessionState() {
	const state = useState(sessionState);

	const setUserState = data => {
		state.merge({ user: { ...data } });

		//set to localstorage
		localStorage.setItem("loggedInAs", JSON.stringify(state.value));
	};

	return {
		get loggedInUser() {
			return state.user.value;
		},
		get profile() {
			// alias for logged in user
			return state.user.value;
		},
		get farmerProfile() {
			return state.user.farmerProfile ? state.user.farmerProfile.value : {};
		},
		get farmLandProfile() {
			return state.user.farmLandProfile ? state.user.farmLandProfile.value : {};
		},
		setLoggedInUser(user) {
			setUserState(user);
		},
		setConnectedToWallet(walletAddress) {
			const data = { ...state.user.value };
			const wallets = data.wallets ? [...data.wallets] : [];

			if (walletAddress && !wallets.find(item => item === walletAddress)) wallets.push(walletAddress);

			setUserState({ ...state.user.value, wallets, connectedToWallet: walletAddress ? true : false });

			//save to db
			saveUserProfile(data.userID, state.user.value);
		},
		setProfile(userID, data) {
			const updatedData = JSON.parse(JSON.stringify({ ...state.user.value, ...data }));
			setUserState({ ...updatedData, userID });

			//save to db
			saveUserProfile(userID, updatedData);
		},
		setFarmerProfile(userID, data) {
			const updatedData = JSON.parse(JSON.stringify({ ...state.user.value, farmerProfile: data }));
			setUserState(updatedData);

			//save to db
			saveUserProfile(userID, updatedData);
		},
		setFarmLandProfile(userID, data) {
			const updatedData = JSON.parse(JSON.stringify({ ...state.user.value, farmLandProfile: data }));
			setUserState(updatedData);

			//save to db
			saveUserProfile(userID, updatedData);
		},
		updateProfile(userID, data) {
			const updatedData = JSON.parse(JSON.stringify({ ...state.user.value, ...data }));
			setUserState(updatedData);
			saveUserProfile(userID, updatedData);
		},
		async fetchUserProfile(userID) {
			const profile = await dbFetchUserProfile(userID);
			setUserState({ ...profile, userID });
			return profile;
		},
		logout() {
			state.set({ user: null });

			firebaseLogout();

			//set to localstorage
			localStorage.setItem("loggedInAs", JSON.stringify(state.value));
			window.location.href = "/"
		},
	};
}
