import { Box, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ListingItem from "src/components/frontend/ListingItem";
import TokenItem from "./TokenItem";

const ListingContainer = styled("div")(({ theme }) => ({
	// padding: "25px 0",
}));

const Listings = ({
	listings,
	tokens,
	title,
	showAddButton,
	owner,
	embedded,
	showConnectButton,
	onClickAddButton,
	onClickConnectButton,
	...props
}) => {
	return (
		<ListingContainer>
			<Box>
				{title && <h2 style={{ marginBottom: "15px" }}>{title}</h2>}
				<Grid container spacing={3}>
					{listings &&
						listings.map((listing, index) =>
							listing ? (
								<ListingItem
									embedded={embedded}
									listing={listing}
									{...props}
									gridOptions={{ xs: 12, sm: 6, md: 3 }}
								/>
							) : null
						)}
					{tokens &&
						tokens.map((token, index) =>
							token && !token.burnt ? (
								<TokenItem
									token={token}
									embedded={embedded}
									{...props}
									key={index}
									gridOptions={{ xs: 12, sm: 6, md: 3 }}
								/>
							) : null
						)}
					{showAddButton ? (
						<Grid item xs={12} sm={6} md={3}>
							<Box
								className="item-group add-new-block"
								sx={{ boxShadow: "none !important" }}
								onClick={onClickAddButton}
							>
								<Box className="item-group-content">
									<AddIcon />
									<Typography>Add Token</Typography>
								</Box>
							</Box>
						</Grid>
					) : showConnectButton ? (
						<Grid item xs={12} sm={6} md={3}>
							<Box
								className="item-group add-new-block"
								sx={{ boxShadow: "none !important" }}
								onClick={onClickConnectButton}
							>
								<Box className="item-group-content">
									<AddIcon />
									<Typography>
										Connect with wallet
										<br /> {owner}
									</Typography>
								</Box>
							</Box>
						</Grid>
					) : null}
				</Grid>
			</Box>
		</ListingContainer>
	);
};

export default Listings;
