import { Box } from "@material-ui/core";
import { useProjectsState } from "src/state";
import Loading from "src/components/Loading";
import Projects from "src/components/frontend/Projects";

const Browse = () => {
	const { promised: isProjectsLoading, projects } = useProjectsState();

	return (
		<>
			<section className="most-viewed-items-area page-paddings">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="section-title text-center">
								<h2 data-watermark="Projects">Projects</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
							</div>
						</div>
					</div>
					<Box sx={{ py: 3 }}>
						{!isProjectsLoading ? (
							<Projects projects={projects} />
						) : (
							<div className="user-no-record-found">
								<Loading />
							</div>
						)}
					</Box>
				</div>
			</section>
		</>
	);
};

export default Browse;
