import { useState } from "react";
import { Box, Button, IconButton, Link, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DragAndDrop from "src/components/DragDropPicker";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
	fileInput: {
		display: "none",
	},
}));

const ImagePicker = ({
	name: imagePickerName,
	value: defaultPickerValue = null,
	withDragDrop = false,
	singleColumn = false,
	isVideo = false,
	onFileSelect = null,
	onDelete = null,
	accept = "image/*",
	recommendedSize = "350 x 350",
	buttonText = "Select",
	buttonProps = {},
}) => {
	const classes = useStyles();
	//const [selectedImage, setSelectedImage] = useState("");
	const [selectedFile, setSelectedFile] = useState("");
	const [selectedFileType, setSelectedFileType] = useState("");
	const [defaultValue, setDefaultValue] = useState(defaultPickerValue);

	const handleFileDrop = files => {
		for (let i = 0; i < files.length; i++) {
			if (!files[i].name) return;
			if (files[i].type.match("^image/")) {
				setSelectedFile(URL.createObjectURL(files[i]));
				setSelectedFileType("image");
			} else if (files[i].type.match("^video/")) {
				setSelectedFile(URL.createObjectURL(files[i]));
				setSelectedFileType("video");
			} else {
				setSelectedFile(files[i].name);
				setSelectedFileType("");
			}

			if (onFileSelect) onFileSelect(imagePickerName, files[i]);
			// pick only first selected file
			break;
		}
	};

	const handleFileSelect = event => {
		event.preventDefault();
		const file = event.target.files[0];
		if (!file) return;

		if (file.type.match("^image/") && withDragDrop) {
			setSelectedFile(URL.createObjectURL(file));
			setSelectedFileType("image");
		} else if (file.type.match("^video/") && withDragDrop) {
			setSelectedFile(URL.createObjectURL(file));
			setSelectedFileType("video");
		} else {
			setSelectedFile(file.name);
			setSelectedFileType("");
		}

		if (onFileSelect) onFileSelect(imagePickerName, file);
	};

	const handleDelete = event => {
		if (onDelete) onDelete(imagePickerName);
		setSelectedFile("");
		setSelectedFileType("");
		setDefaultValue(null);
	};

	return (
		<Grid
			container
			direction={singleColumn ? "column" : "row"}
			justifyContent="flex-start"
			alignItems={singleColumn ? "center" : "flex-start"}
			mb={1}
			spacing={2}
		>
			{withDragDrop && (
				<Grid item xs={12} md={6}>
					<DragAndDrop handleDrop={handleFileDrop}>
						<Box
							sx={{
								height: 150,
								width: 150,
								overflow: "hidden",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							{selectedFileType === "video" || (defaultValue && isVideo) ? (
								<video width="150" height="150" autoPlay loop muted controls>
									<source src={selectedFile ? selectedFile : defaultValue} type="video/mp4"></source>
								</video>
							) : selectedFileType === "image" || defaultValue ? (
								<img
									id="selected-image"
									width="150"
									src={selectedFile ? selectedFile : defaultValue}
									alt=""
								/>
							) : (
								<Typography variant="caption" align="center">
									Drag and Drop File
									<br />
									{recommendedSize}
								</Typography>
							)}
						</Box>
					</DragAndDrop>
				</Grid>
			)}
			<Grid item xs={12} md={6}>
				<label style={{ marginRight: "15px" }}>
					<input
						accept={accept}
						multiple
						type="file"
						className={classes.fileInput}
						onChange={handleFileSelect}
					/>
					<Button variant="outlined" color="secondary" component="span" {...buttonProps}>
						{buttonText}
					</Button>
				</label>
				{onDelete && (defaultValue || selectedFile) && (
					<IconButton onClick={handleDelete} size="small">
						<DeleteIcon />
					</IconButton>
				)}
				{selectedFile && (selectedFileType === "" || !withDragDrop) && (
					<Typography variant="body2">{selectedFile}</Typography>
				)}
				{!selectedFile && defaultValue && !withDragDrop && (
					<Link href={defaultValue} target="_blank">
						View File
					</Link>
				)}
			</Grid>
		</Grid>
	);
};

export default ImagePicker;
