import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Alert, Box, Container } from "@material-ui/core";
import Logo from "../../components/Logo";
import { admin_link } from "src/helpers/links.helper";
import { useSessionState } from "src/state";
import EmailSignup from "src/components/access/EmailSignup";

import "src/layouts/assets/mainlayout/css/login.css";

const Signup = () => {
	const navigate = useNavigate();
	const sessionState = useSessionState();

	const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
		if (sessionState.loggedInUser) {
			if (sessionState.loggedInUser.type === 'admin')
				navigate(admin_link("/"), { replace: true });
			else
				navigate("/", { replace: true });
		}
	}, [])

	const handleSignupSuccess = userInfo => {
		const user = {
			firstName: userInfo.first_name,
			lastName: userInfo.last_name,
			username: userInfo.displayName,
			email: userInfo.email,
			emailVerified: userInfo.emailVerified,
			profileVerified: false
		};

		sessionState.setProfile(userInfo.uid, user);

		//navigate(admin_link("/"), { replace: true });
		
		// redirect user to verification
		navigate("/verify", { replace: true });
	};

	const handleSignupFailed = message => {
		setErrorMessage(message);
	};

	return (
		<>
			<Box className="fxt-template-animation nft-login-template">
				<Box className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-xl-6 col-lg-7 col-sm-12 col-12 fxt-bg-color">
							<div className="fxt-content">
								<div className="fxt-home-link">
									<RouterLink to="/">
										<i className="fas fa-home"></i> Home
									</RouterLink>
								</div>
								<div className="fxt-header">
									<RouterLink to="/" className="fxt-logo">
										<Logo mode="small" />
									</RouterLink>
									<p>Begin Now! Register On {process.env.REACT_APP_SITENAME}</p>
								</div>
								<div className="fxt-form">
									<EmailSignup
										onSignupSuccess={handleSignupSuccess}
										onSignupFailed={handleSignupFailed}
									/>
									{errorMessage && <Alert severity="error">{errorMessage}</Alert>}
								</div>
								<Box my={2} className="fxt-footer">
									<div className="fxt-transformY-50 fxt-transition-delay-9">
										<p>
											Already have an account?
											<RouterLink to="/login" className="switcher-text2 inline-text">
												Login
											</RouterLink>
										</p>
									</div>
								</Box>
							</div>
						</div>
					</div>
				</Box>
			</Box>
		</>
	);
};

export default Signup;
