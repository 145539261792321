import { useRef, useState } from "react";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	MenuItem,
	Select,
	Typography,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";
import { readBlobToBuffer, uploadFileToIPFS } from "src/helpers/file.helper";
import { cachedVersion } from "src/helpers/file.helper";
import { hash_to_ipfs_link } from "src/helpers/links.helper";
import Tabs from "src/components/Tabs";
import TextField from "src/components/FormikTextField";
import ImagePicker from "src/components/ImagePicker";
import { useCategoryState } from "src/state";
import MultilineField from "src/components/MultilineField";
import { get as getValue } from "lodash";

const ProjectForm = ({ defaultData, onSave, isForFrontend }) => {
	const { promised: isCategoryLoading, categories } = useCategoryState();

	const milestones = useRef(defaultData ? defaultData.project_timeline : []);
	const paymentSchedule = useRef(defaultData ? defaultData.payment_schedule : []);

	const formik = useFormik({
		initialValues: {
			category: getValue(defaultData, "category", ""),
			project_name: getValue(defaultData, "project_name", ""),
			project_tagline: getValue(defaultData, "project_tagline", ""),
			project_country: getValue(defaultData, "project_country", ""),
			project_address: getValue(defaultData, "project_address", ""),
			owner_name: getValue(defaultData, "owner_name", ""),
			project_summary: getValue(defaultData, "project_summary", ""),
			social_linkedin: getValue(defaultData, "social_linkedin", ""),
			social_instagram: getValue(defaultData, "social_instagram", ""),
			social_twitter: getValue(defaultData, "social_twitter", ""),
			usp_1: getValue(defaultData, "usp_1", ""),
			usp_2: getValue(defaultData, "usp_2", ""),
			usp_3: getValue(defaultData, "usp_3", ""),
			usp_4: getValue(defaultData, "usp_4", ""),
			usp_5: getValue(defaultData, "usp_5", ""),
			usp_6: getValue(defaultData, "usp_6", ""),
			registration_cert_updated: getValue(defaultData, "registration_cert_updated", ""),
			licenses: getValue(defaultData, "licenses", ""),
			tax_license_no: getValue(defaultData, "tax_license_no", ""),
			distribution_partners: getValue(defaultData, "distribution_partners", ""),
			prove_assets_registration: getValue(defaultData, "prove_assets_registration", ""),
			project_tnc: getValue(defaultData, "project_tnc", ""),
			reserve_for_lp: getValue(defaultData, "reserve_for_lp", false),
			reserve_for_lp_percentage: getValue(defaultData, "reserve_for_lp_percentage", 0),
			reserve_for_lp_months: getValue(defaultData, "reserve_for_lp_months", 0),
		},
		onSubmit: async (values, formikHelpers) => {
			try {
				//upload Images/files if attached
				if (selectedFilesBuffers.current) {
					for (const key in selectedFilesBuffers.current) {
						let fileHash = null;
						if (selectedFilesBuffers.current[key]) {
							fileHash = await uploadFileToIPFS(selectedFilesBuffers.current[key]);
						}
						values[key] = hash_to_ipfs_link(fileHash);
					}
				}

				//add in the milestones
				values["project_timeline"] = milestones.current;
				if (!isForFrontend) values["payment_schedule"] = paymentSchedule.current ?? [];

				formikHelpers.setSubmitting(false);

				// return back the data to parent page
				onSave && onSave(values);
			} catch (error) {
				formikHelpers.setSubmitting(false);
				console.error(error);
				return;
			}
		},
		validationSchema: Yup.object().shape({
			project_name: Yup.string().max(100).required("Project Name is required"),
			category: Yup.string().required("Please select a category"),
		}),
	});

	const selectedFilesBuffers = useRef({});

	const handleFileSelect = (filename, file) => {
		if (file) {
			readBlobToBuffer(file).then(fileBuffer => (selectedFilesBuffers.current[filename] = fileBuffer));
		}
	};

	const handleFileDelete = filename => {
		// remove from file buffers
		delete selectedFilesBuffers.current[filename];

		formik.values[filename] = null;
	};

	const setMilestones = newValues => {
		milestones.current = newValues;
	};

	const setPaymentSchedule = newValues => {
		paymentSchedule.current = newValues;
	};

	return (
		<FormikProvider value={formik}>
			<Form>
				<Tabs>
					<Box label={`Project Info`}>
						<Box mb={6}>
							<Typography variant="h3" mb={2}>
								Project Category
							</Typography>
							{!isCategoryLoading && (
								<Select
									fullWidth
									name="category"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.category}
								>
									{categories.map((item, index) => (
										<MenuItem key={index} value={item.id}>
											{item.name}
										</MenuItem>
									))}
								</Select>
							)}
						</Box>
						<Typography variant="h3" mb={2}>
							Project Details
						</Typography>
						<Grid container spacing={4} rowSpacing={1}>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Project Name"
									margin="normal"
									name="project_name"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Project Tagline"
									margin="normal"
									name="project_tagline"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Project Country"
									margin="normal"
									name="project_country"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Project Address"
									margin="normal"
									name="project_address"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Project Owner Name"
									margin="normal"
									name="owner_name"
									type="text"
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<Grid container spacing={4} rowSpacing={1} mt={0}>
							<Grid item md={12}>
								<TextField
									fullWidth
									multiline
									rows={8}
									label="Project Summary"
									margin="normal"
									name="project_summary"
									type="text"
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<Typography variant="h3" mt={6} mb={2}>
							Project Timeline
						</Typography>
						<Typography>Add in your project schedule, one milestone at a time</Typography>
						<Box sx={{ color: "#989898" }}>
							<MultilineField
								items={milestones.current}
								onChange={setMilestones}
								fields={[
									{ name: "title", title: "Milestone Name" },
									{ name: "value", title: "Estimated Time (in Months or Years)" },
								]}
								addButtonText="Add another Milestone"
								addButtonAlignment="left"
							/>
						</Box>
						<Typography variant="h3" mt={6} mb={2}>
							Social Links
						</Typography>
						<Grid container spacing={4} rowSpacing={1}>
							<Grid item md={4}>
								<TextField
									fullWidth
									label="LinkedIn"
									margin="normal"
									name="social_linkedin"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={4}>
								<TextField
									fullWidth
									label="Instagram"
									margin="normal"
									name="social_instagram"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={4}>
								<TextField
									fullWidth
									label="Twitter"
									margin="normal"
									name="social_twitter"
									type="text"
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</Box>
					<Box label="Project USP">
						<Grid container spacing={4} rowSpacing={1}>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="USP 1"
									margin="normal"
									name="usp_1"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="USP 2"
									margin="normal"
									name="usp_2"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="USP 3"
									margin="normal"
									name="usp_3"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="USP 4"
									margin="normal"
									name="usp_4"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="USP 5"
									margin="normal"
									name="usp_5"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="USP 6"
									margin="normal"
									name="usp_6"
									type="text"
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</Box>
					<Box label="Project Authenticity">
						<Grid container spacing={4} rowSpacing={1}>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Biz registration cert updated"
									margin="normal"
									name="registration_cert_updated"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Licenses"
									margin="normal"
									name="licenses"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Tax License No."
									margin="normal"
									name="tax_license_no"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Distribution Partners"
									margin="normal"
									name="distribution_partners"
									type="text"
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									fullWidth
									label="Prove of Assets Registration"
									margin="normal"
									name="prove_assets_registration"
									type="text"
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</Box>
					<Box label="Terms & Conditions">
						<Grid container spacing={4} rowSpacing={1}>
							<Grid item md={12}>
								<TextField
									fullWidth
									multiline
									rows={20}
									label="Project T&C"
									margin="normal"
									name="project_tnc"
									type="text"
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</Box>
					<Box label={`Project Gallery`}>
						<Grid container spacing={4}>
							<Grid item md={3}>
								<Typography variant="h3" mb={2}>
									Project Image
								</Typography>
								<ImagePicker
									withDragDrop
									singleColumn
									name="project_image"
									onFileSelect={handleFileSelect}
									onDelete={handleFileDelete}
									value={cachedVersion(defaultData?.project_image)}
								/>
							</Grid>
							<Grid item md={3}>
								<Typography variant="h3" mb={2}>
									Banner Image
								</Typography>
								<ImagePicker
									withDragDrop
									singleColumn
									name="banner_image"
									onFileSelect={handleFileSelect}
									onDelete={handleFileDelete}
									value={cachedVersion(defaultData?.banner_image)}
								/>
							</Grid>
							<Grid item md={3}>
								<Typography variant="h3" mb={2}>
									Timeline Image
								</Typography>
								<ImagePicker
									withDragDrop
									singleColumn
									name="timeline_image"
									onFileSelect={handleFileSelect}
									onDelete={handleFileDelete}
									value={cachedVersion(defaultData?.timeline_image)}
								/>
							</Grid>
							<Grid item md={3}>
								<Typography variant="h3" mb={2}>
									Use of Funds
								</Typography>
								<ImagePicker
									withDragDrop
									singleColumn
									name="use_funds"
									onFileSelect={handleFileSelect}
									onDelete={handleFileDelete}
									value={cachedVersion(defaultData?.use_funds)}
								/>
							</Grid>
						</Grid>
						<Box mb={6}>
							<Grid container spacing={4}>
								<Grid item md={3}>
									<Typography variant="h3" mt={6} mb={2}>
										Project Map
									</Typography>
									<ImagePicker
										withDragDrop
										singleColumn
										name="project_map"
										onFileSelect={handleFileSelect}
										onDelete={handleFileDelete}
										value={cachedVersion(defaultData?.project_map)}
									/>
								</Grid>
								<Grid item md={3}>
									<Typography variant="h3" mt={6} mb={2}>
										Other Images
									</Typography>
									<ImagePicker
										withDragDrop
										singleColumn
										name="project_gallery_1"
										onFileSelect={handleFileSelect}
										onDelete={handleFileDelete}
										value={cachedVersion(defaultData?.project_gallery_1)}
									/>
								</Grid>
								<Grid item md={3}>
									<Typography variant="h3" mt={6} mb={2}>
										&nbsp;
									</Typography>
									<ImagePicker
										withDragDrop
										singleColumn
										name="project_gallery_2"
										onFileSelect={handleFileSelect}
										onDelete={handleFileDelete}
										value={cachedVersion(defaultData?.project_gallery_2)}
									/>
								</Grid>
								<Grid item md={3}>
									<Typography variant="h3" mt={6} mb={2}>
										&nbsp;
									</Typography>
									<ImagePicker
										withDragDrop
										singleColumn
										name="project_gallery_3"
										onFileSelect={handleFileSelect}
										onDelete={handleFileDelete}
										value={cachedVersion(defaultData?.project_gallery_3)}
									/>
								</Grid>
							</Grid>
						</Box>
					</Box>
					<Box label={`Project Docs`}>
						<Box mb={6}>
							<Grid container spacing={4}>
								<Grid item md={2}>
									<Typography variant="body1" mb={2}>
										Doc 1
									</Typography>
									<ImagePicker
										name="project_docs_1"
										accept="*"
										onFileSelect={handleFileSelect}
										value={defaultData?.project_docs_1}
									/>
								</Grid>
								<Grid item md={2}>
									<Typography variant="body1" mb={2}>
										Doc 2
									</Typography>
									<ImagePicker
										name="project_docs_2"
										accept="*"
										onFileSelect={handleFileSelect}
										value={defaultData?.project_docs_2}
									/>
								</Grid>
								<Grid item md={2}>
									<Typography variant="body1" mb={2}>
										Doc 3
									</Typography>
									<ImagePicker
										name="project_docs_3"
										accept="*"
										onFileSelect={handleFileSelect}
										value={defaultData?.project_docs_3}
									/>
								</Grid>
								<Grid item md={2}>
									<Typography variant="body1" mb={2}>
										Doc 4
									</Typography>
									<ImagePicker
										name="project_docs_4"
										accept="*"
										onFileSelect={handleFileSelect}
										value={defaultData?.project_docs_4}
									/>
								</Grid>
								<Grid item md={2}>
									<Typography variant="body1" mb={2}>
										Doc 5
									</Typography>
									<ImagePicker
										name="project_docs_5"
										accept="*"
										onFileSelect={handleFileSelect}
										value={defaultData?.project_docs_5}
									/>
								</Grid>
							</Grid>
						</Box>
					</Box>
					{!isForFrontend && (
						<Box label="Payment Schedule">
							<Box mb={6}>
								<Typography>
									Specify the schedule for release of payments to the project owner.
								</Typography>
								<Typography variant="body2" mt={3}>
									* All dates will be based on the <strong>Campaign Commencement Date</strong>
								</Typography>
								<Box sx={{ color: "#989898" }}>
									<MultilineField
										items={paymentSchedule.current}
										onChange={setPaymentSchedule}
										fields={[
											{ name: "title", title: "Percentage", type: "percentage" },
											{ name: "value", title: "After (months)", type: "number" },
										]}
										addButtonText="Add Payment Schedule"
										addButtonAlignment="left"
									/>
								</Box>
							</Box>
							<Box mb={6}>
								<FormControlLabel
									control={
										<Checkbox
											name="reserve_for_lp"
											defaultChecked={formik.values.reserve_for_lp}
											onChange={formik.handleChange}
										/>
									}
									label="Reserve funds from the project to LP"
								/>
								{formik.values.reserve_for_lp && (
									<Box>
										<Grid container columnSpacing={4} sx={{ alignItems: "center" }}>
											<Grid item xs={5}>
												<TextField
													fullWidth
													label="Percentage"
													margin="normal"
													name="reserve_for_lp_percentage"
													type="number"
													variant="outlined"
												/>
											</Grid>
											<Grid item xs={5}>
												<TextField
													fullWidth
													label="For (months)"
													margin="normal"
													name="reserve_for_lp_months"
													type="number"
													variant="outlined"
												/>
											</Grid>
										</Grid>
									</Box>
								)}
							</Box>
						</Box>
					)}
				</Tabs>
				<Box sx={{ px: 3, mb: 2 }}>
					<Button
						color="primary"
						size="large"
						type="submit"
						variant="contained"
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{formik.isSubmitting ? <CircularProgress size={20} /> : "Save Details"}
					</Button>
				</Box>
			</Form>
		</FormikProvider>
	);
};

export default ProjectForm;
