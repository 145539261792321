import { ipfs_link } from "src/helpers/links.helper";
import db, { FirebaseFieldValue } from "src/plugins/Firebase";

const firestore_collection_name = "collections";
const firestore_token_name = "tokens";

const setupCollectionData = dbItem => {
	const itemData = dbItem.data();

	return {
		name: itemData.name,
		symbol: itemData.symbol,
		address: dbItem.id,
		owner: itemData.owner,
		totalItems: itemData.token_count ?? 0,
		itemCreationData: itemData.item_meta ? itemData.item_meta : null,
		chainID: itemData.chain_id,
		contractType: itemData.contract_type,
		pending: itemData.pending ? true : false,
		createdAt: itemData.created_at,
		updatedAt: itemData.updated_at ? itemData.updated_at : itemData.created_at,
		description: itemData.description ?? null,
		categoryID: itemData.category ?? null,
		royalty: itemData.royalty ?? 0,
		youtube: itemData.youtube ?? "",
		unit: itemData.unit ?? null,
		enabled: itemData.enabled ?? false,
		projectID: itemData.project_id,
		campaignCommencementDate: itemData.commencement_date ?? "",
		metadata: itemData.metadata ?? {},
		offeringSize: itemData.offering_size ?? null,
		payoutSchedule: itemData.payout_schedule ?? [],
		archived: itemData.archived ?? false,
	};
};

export const setupTokenData = (dbID, itemData) => ({
	id: dbID,
	sku: itemData.sku,
	itemName: itemData.sku,
	imageUrl: ipfs_link(itemData.image_url),
	animationUrl: itemData.animation_url && itemData.animation_url !== "" ? ipfs_link(itemData.animation_url) : null,
	animationFileType: itemData.animation_file_type ?? "",
	imageIPFSUrl: ipfs_link(itemData.image_url),
	metadataUrl: ipfs_link(itemData.metadata_url),
	certificateUrl: ipfs_link(itemData.certificate_url),
	descriptionUrl: ipfs_link(itemData.description_url),
	minter: itemData.minter,
	owner: itemData.owner,
	tokenID: itemData.token_id ? parseInt(itemData.token_id) : null,
	collectionID: itemData.collection,
	burnt: itemData.burnt ? true : false,
	createdAt: itemData.created_at,
});

// Fetch Records

export const fetchCollections = async () => {
	const dbResponse = db.collection(firestore_collection_name).orderBy("symbol", "asc");
	const dbData = await dbResponse.get();

	const data = [];

	dbData.docs.forEach(item => {
		data.push(setupCollectionData(item));
	});

	return data;
};

export const fetchCollection = async collectionID => {
	const dbData = await db.collection(firestore_collection_name).doc(collectionID).get();

	return setupCollectionData(dbData);
};

export const fetchCollectionTokens = async collectionID => {
	const dbResponse = await db.collection(firestore_token_name).where("collection", "==", collectionID).orderBy("sku", "asc").get();

	const data = [];

	dbResponse.docs.forEach(item => {
		const itemData = item.data();
		data.push(setupTokenData(item.id, itemData));
	});

	return data;
};

export const fetchUserTokens = async userID => {
	const dbResponse = await db
		.collection(firestore_token_name)
		.where("user_id", "==", userID)
		.orderBy("sku", "asc")
		.get();

	const data = [];

	dbResponse.docs.forEach(item => {
		const itemData = item.data();
		if (itemData.token_id && !itemData.burnt) data.push(setupTokenData(item.id, itemData));
	});

	return data;
};

// Insert Records

export const addNewCollection = async ({ address, pending, ...data }) => {
	let dbID = null;

	if (pending) {
		// create a temporary collection, that will need to be deleted and replaced
		// upon a contract address is received
		await db
			.collection(firestore_collection_name)
			.add({
				...data,
				created_at: new Date().getTime(),
				pending: true,
			})
			.then(docRef => {
				dbID = docRef.id;
			});
	} else {
		dbID = data.address;

		db.collection(firestore_collection_name)
			.doc(address)
			.set({
				...data,
				created_at: new Date().getTime(),
			});
	}

	return dbID;
};

export const updateCollectionSettings = (collectionID, data) => {
	db.collection(firestore_collection_name).doc(collectionID).update({
		category: data.category,
		description: data.description,
		royalty: data.royalty,
		youtube: data.youtube,
		unit: data.unit,
	});
};

export const archiveCollection = collectionID => {
	db.collection(firestore_collection_name).doc(collectionID).update({
		archived: true,
	});
};

export const deleteCollection = collectionID => {
	db.collection(firestore_collection_name).doc(collectionID).delete();
};

export const addNewCollectionToken = async (collectionID, data, metadata) => {
	let dbID = null;

	await db
		.collection(firestore_token_name)
		.add({
			collection: collectionID,
			token_id: data.token_id ?? null,
			image_url: data.image_url,
			animation_url: data.animation_url,
			animation_file_type: data.animation_file_type,
			metadata_url: data.metadata_url,
			certificate_url: data.certificate_url ?? null,
			minter: data.minter,
			owner: data.owner,
			sku: data.sku,
			created_at: new Date().getTime(),
		})
		.then(docRef => {
			dbID = docRef.id;

			//increment the collections token count
			db.collection(firestore_collection_name)
				.doc(collectionID)
				.update({
					token_count: FirebaseFieldValue.increment(1),
					updated_at: new Date().getTime(),
					item_meta: metadata,
				});
		});

	return dbID;
};

export const updateCollectionToken = async (collectionID, dbID, data) => {
	await db
		.collection(firestore_token_name)
		.doc(dbID)
		.update({
			...data,
		});
};

export const deleteCollectionToken = async (collectionID, dbID) => {
	await db.collection(firestore_token_name).doc(dbID).delete();

	//decrement the collections token count
	db.collection(firestore_collection_name)
		.doc(collectionID)
		.update({
			token_count: FirebaseFieldValue.increment(-1),
			updated_at: new Date().getTime(),
		});
};

// export const copyTokensToRootCollection = async () => {
// 	const dbResponse = await db
// 		.collection(firestore_token_name)
// 		.get();

// 	const data = [];

// 	dbResponse.docs.forEach(async item => {
// 		await db
// 			.collection(firestore_token_name)
// 			.doc(item.id)
// 			.update({
// 				user_id: "hIb8PtvZqQUdOyqm7rMStq31nSj2"
// 			});
// 	});

// 	return data;
// }
