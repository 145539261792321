import React, { useState, useContext, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
	Box,
	Button,
	TextField,
	Typography,
	Select,
	MenuItem,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import {
	networkName,
	shortAccount,
	getAvailableCurrencies,
} from "src/helpers/blockchain.helper";
import { useCurrencyState, useSettingState, useCollectionState } from "src/state";
import { round } from "src/helpers/number.helper";

const FeeBlock = ({ marketplaceFee }) => {
	const siteName = process.env.REACT_APP_SITENAME;

	return (
		<>
			<Typography mb="2" variant="h5">
				Fees
			</Typography>
			<Grid container sx={{ mt: 1, mb: 2 }}>
				<Grid xs={9} item>
					<Typography>{siteName} transaction fee</Typography>
				</Grid>
				<Grid xs={3} item sx={{ textAlign: "right" }}>
					<Typography>{marketplaceFee}%</Typography>
				</Grid>
			</Grid>
		</>
	);
};

const EarningBlock = ({ title, marketplaceFee, currency, amount }) => {
	const { getExRateInUSD } = useCurrencyState();

	return (
		<Box fontWeight="fontWeightBold">
			<Grid
				container
				sx={{
					mt: 1,
					mb: 2,
					fontWeight: "bold",
				}}
			>
				<Grid xs={6} item>
					<Typography>{title}</Typography>
				</Grid>
				<Grid xs={6} item sx={{ textAlign: "right" }}>
					<Typography>
						{currency}{" "}
						{round(
							amount * (1 - parseFloat(marketplaceFee) / 100),
							4
						)}
						<br />
						<small>
							(USD{" "}
							{round(
								amount *
									(1 - parseFloat(marketplaceFee) / 100) *
									getExRateInUSD(currency)
							)}
							)
						</small>
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

EarningBlock.defaultProps = {
	title: "Total Earnings",
};

const ListingTypeHeader = ({ heading, onReset }) => (
	<Grid container mb={3} gap={3} sx={{ alignItems: "center", justifyContent: "space-between" }}>
		<Grid item>
			<Typography variant="h3">{heading}</Typography>
		</Grid>
		<Grid item>
			<Typography variant="body2" onClick={onReset}>
				Close
			</Typography>
		</Grid>
	</Grid>
);

const ListingForm = ({ collectionID, onCreate, ...props }) => {
	const [step, setStep] = useState(1);
	const [collection, setCollection] = useState({});
	const blockchainInfo = useContext(BlockchainContext);
	const { getMarketplaceFee } = useSettingState();

	const marketplaceFee = blockchainInfo
		? getMarketplaceFee(blockchainInfo.networkId)
		: 2.5;

	const { promised: isCollectionLoading, getCollection, updateSettings } = useCollectionState();

	useEffect(() => {
		if (!isCollectionLoading) {
			const collection = getCollection(collectionID);
			setCollection(collection);
		}
	}, [isCollectionLoading, collectionID])

	return collection.name ? (
		<Formik
			initialValues={{
				listing_type: "sale",
				currency: "BUSD",
				amount: collection ? parseFloat(collection.offeringSize) : "",
				end_date: moment().add(30, "days").format("YYYY-MM-DD"),
				start_date: moment().format("YYYY-MM-DD"),
				auction_method: "highest_bidder",
			}}
			validationSchema={Yup.object().shape({
				listing_type: Yup.string()
					.max(255)
					.required("Listing type is required"),
				amount: Yup.number().required("Amount is required"),
				end_date: Yup.string(),
				start_date: Yup.string(),
			})}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				onCreate &&
					onCreate({
						listingType: values.listing_type,
						currency: values.currency,
						amount: values.amount,
						startDate: values.start_date,
						endDate: values.end_date,
						auctionMethod: values.auction_method,
					});
			}}
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
			}) => (
				<form onSubmit={handleSubmit}>
					{step === 1 && (
						<FormControl
							fullWidth
							error={Boolean(
								touched.listing_type && errors.listing_type
							)}
							component="fieldset"
						>
							<FormLabel sx={{ mb: 2 }} component="legend">
								Select the type of listing you would like to
								create
							</FormLabel>
							<Select
								fullWidth
								name="listing_type"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.listing_type}
								sx={{ mb: 2 }}
							>
								<MenuItem value="sale">Sale</MenuItem>
								<MenuItem value="auction">Auction</MenuItem>
							</Select>
							{errors.listing_type && (
								<FormHelperText>
									{errors.listing_type}
								</FormHelperText>
							)}
							{!errors.listing_type && (
								<Button
									variant="contained"
									onClick={() => setStep(2)}
								>
									Next
								</Button>
							)}
						</FormControl>
					)}
					{step === 2 && values.listing_type === "sale" && (
						<Fade in={true} mountOnEnter unmountOnExit>
							<FormControl
								fullWidth
								error={Boolean(
									touched.listing_type && errors.listing_type
								)}
								component="fieldset"
							>
								<ListingTypeHeader
									heading="Sales Listing"
									onReset={() => setStep(1)}
								/>
								<Typography variant="h5">Price</Typography>
								<Grid
									container
									sx={{ mt: 1 }}
									columnSpacing={2}
								>
									<Grid xs={3} item>
										<Select
											fullWidth
											name="currency"
											value={values.currency}
											onBlur={handleBlur}
											onChange={handleChange}
										>
											{getAvailableCurrencies(
												blockchainInfo.networkId
											).map(option => (
												<MenuItem
													key={option}
													value={option}
												>
													{option}
												</MenuItem>
											))}
										</Select>
									</Grid>
									<Grid xs={9} item>
										<TextField
											error={Boolean(
												touched.amount && errors.amount
											)}
											fullWidth
											helperText={
												touched.amount && errors.amount
											}
											label="Amount"
											name="amount"
											onBlur={handleBlur}
											onChange={handleChange}
											type="number"
											value={values.amount}
											variant="outlined"
											margin="normal"
											sx={{ my: 0 }}
										/>
									</Grid>
								</Grid>

								<Box sx={{ mt: 3 }}>
									<FeeBlock marketplaceFee={marketplaceFee} />
									<hr />
									<EarningBlock
										marketplaceFee={marketplaceFee}
										amount={values.amount}
										currency={values.currency}
									/>
								</Box>

								<TextField
									error={Boolean(
										touched.end_date && errors.end_date
									)}
									fullWidth
									name="end_date"
									type="date"
									helperText={
										touched.end_date && errors.end_date
									}
									label="Sale Ends On"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.end_date}
									variant="outlined"
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										min: moment().format("YYYY-MM-DD"),
									}}
								/>

								<Box sx={{ pt: 2 }}>
									<Button
										color="primary"
										disabled={
											isSubmitting ||
											!touched.amount ||
											(errors.amount &&
												errors.amount.length > 0) ||
											(errors.end_date &&
												errors.end_date.length > 0)
										}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										Create
									</Button>
								</Box>
							</FormControl>
						</Fade>
					)}
					{step === 2 && values.listing_type === "auction" && (
						<Fade in={true} mountOnEnter unmountOnExit>
							<FormControl
								fullWidth
								error={Boolean(
									touched.listing_type && errors.listing_type
								)}
								component="fieldset"
							>
								<ListingTypeHeader
									heading="Auction Listing"
									onReset={() => setStep(1)}
								/>
								<Typography mb="2" variant="h5">
									Auction Method
								</Typography>
								<Select
									fullWidth
									name="auction_method"
									value={values.auction_method}
									onBlur={handleBlur}
									onChange={handleChange}
								>
									<MenuItem value="highest_bidder">
										Sell to highest bidder
									</MenuItem>
								</Select>
								<Typography sx={{ mt: 2 }} variant="h5">
									Starting Price
								</Typography>
								<Grid container spacing={2}>
									<Grid xs={3} item>
										<Select
											fullWidth
											name="currency"
											value={values.currency}
											onBlur={handleBlur}
											onChange={handleChange}
										>
											{getAvailableCurrencies(
												blockchainInfo.networkId
											).map(option => (
												<MenuItem
													key={option}
													value={option}
												>
													{option}
												</MenuItem>
											))}
										</Select>
									</Grid>
									<Grid xs={9} item>
										<TextField
											error={Boolean(
												touched.amount && errors.amount
											)}
											fullWidth
											helperText={
												touched.amount && errors.amount
											}
											label="Amount"
											name="amount"
											onBlur={handleBlur}
											onChange={handleChange}
											type="number"
											value={values.amount}
											variant="outlined"
											margin="normal"
											sx={{ my: 0 }}
										/>
									</Grid>
								</Grid>
								<Typography sx={{ mt: 2 }} variant="h5">
									Duration
								</Typography>
								<Grid container spacing={2}>
									<Grid item>
										<TextField
											error={Boolean(
												touched.start_date &&
													errors.start_date
											)}
											fullWidth
											name="start_date"
											type="date"
											helperText={
												touched.start_date &&
												errors.start_date
											}
											label="Starts On"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.start_date}
											variant="outlined"
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												min: moment().format(
													"YYYY-MM-DD"
												),
											}}
										/>
									</Grid>
									<Grid item>
										<TextField
											error={Boolean(
												touched.end_date &&
													errors.end_date
											)}
											fullWidth
											name="end_date"
											type="date"
											helperText={
												touched.end_date &&
												errors.end_date
											}
											label="Ends On"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.end_date}
											variant="outlined"
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												min: values.start_date
													? values.start_date
													: moment().format(
															"YYYY-MM-DD"
													  ),
											}}
										/>
									</Grid>
								</Grid>
								<Box mt={3}>
									<FeeBlock marketplaceFee={marketplaceFee} />
									<hr />
									<EarningBlock
										title="Minimum Earning"
										marketplaceFee={marketplaceFee}
										amount={values.amount}
										currency={values.currency}
									/>
								</Box>
								<Box sx={{ pt: 2 }}>
									<Button
										color="primary"
										disabled={
											isSubmitting ||
											!touched.amount ||
											(errors.amount &&
												errors.amount.length > 0) ||
											(errors.end_date &&
												errors.end_date.length > 0)
										}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										Create
									</Button>
								</Box>
							</FormControl>
						</Fade>
					)}
				</form>
			)}
		</Formik>
	) : null;
};

export default ListingForm;
