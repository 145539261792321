import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SquareBlock from "src/components/frontend/SquareBlock";
import { cachedVersion } from "src/helpers/file.helper";

const ProjectItemName = styled("h2")(({ theme }) => ({
	fontSize: "24px",
	fontWeight: "700",
	[theme.breakpoints.up("sm")]: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
}));

const ProjectItemDescription = styled("p")(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		// whiteSpace: "nowrap",
		// textOverflow: "ellipsis",
		// overflow: "hidden",
		margin: 0,
		lineHeight: 1.2,
	},
}));

const useStyles = makeStyles(() => {
	return {
		projectImage: {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			verticalAlign: "bottom",
		},
	};
});

const ProjectItem = ({ project, gridOptions, ...props }) => {
	const classes = useStyles();

	if (!project || !project.project_image) return null;

	return (
		<Grid item {...(gridOptions ? gridOptions : { xs: 12, sm: 6, lg: 4 })}>
			<Link to={`/project/${project.id}`}>
				<Box className="item-group">
					<Box className="item-group-content">
						<SquareBlock>
							<img src={cachedVersion(project.project_image)} className={classes.projectImage} />
						</SquareBlock>
						<Box className="item-group-text">
							<ProjectItemName>{project.project_name}</ProjectItemName>
							<ProjectItemDescription>{project.project_tagline}</ProjectItemDescription>
						</Box>
					</Box>
				</Box>
			</Link>
		</Grid>
	);
};

export default ProjectItem;
