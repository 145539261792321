import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "src/components/Loading";
import ProjectForm from "src/components/projects/ProjectForm";
import { useSessionState, useProjectsState, useCategoryState } from "src/state";

const ProjectsAdd = () => {
	const { id: projectID } = useParams();

	const { profile } = useSessionState();
	const { promised: loadingProjects, createProject, updateProject, getProject } = useProjectsState();
	const { updateCategoryItemCount } = useCategoryState();

	const navigate = useNavigate();

	const [isSavingData, setIsSavingData] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [defaultData, setDefaultData] = useState();

	// useEffect(() => {
	// 	if (!profile || !profile.verified) {			
	// 		navigate("/profile/projects");
	// 	}
	// }, []);

	useEffect(() => {
		if (projectID && !loadingProjects) {
			const data = getProject(projectID);
			//check project is created by the current user
			if (data && data.created_by === profile.userID) {
				setDefaultData({ ...data });
				setShowForm(true);
			} else {
				navigate("/profile/projects");
			}
		} else if (!projectID) {
			setShowForm(true);
		}
	}, [projectID, loadingProjects]);

	const handleProjectSave = async values => {
		setIsSavingData(true);

		if (projectID) {
			//check if category changed
			if (defaultData.category !== values.category) {
				defaultData.category && updateCategoryItemCount(defaultData.category, -1);
				values.category && updateCategoryItemCount(values.category);
			}

			await updateProject(projectID, values);
		} else {
			await createProject({ ...values, created_by: profile.userID, pending_approval: true });
			values.category && updateCategoryItemCount(values.category);
		}

		setIsSavingData(false);

		navigate("/profile/projects");
	};

	return (
		<>
			<section className="min-item-area page-paddings">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="section-title text-center">
								<h2 data-watermark="Items">{projectID ? "Edit" : "List Your"} Project</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="user-panel-main">
								<div className="user-store-area">
									<div className="row">
										<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
											{showForm ? (
												<ProjectForm
													onSave={handleProjectSave}
													defaultData={
														defaultData && defaultData.project_name
															? { ...defaultData }
															: null
													}
													isForFrontend={true}
												/>
											) : (
												<div className="user-no-record-found">
													<Loading />
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{isSavingData && <Loading />}
		</>
	);
};

export default ProjectsAdd;
