import moment from "moment";
import { contractLinkURL, tokenLinkURL, networkName, shortAccount } from "src/helpers/blockchain.helper";
import db, { FirebaseFieldValue } from "src/plugins/Firebase";

const firestore_collection_name = "transactions";

export const setupTransactionData = (dbID, itemData) => {
	const formatedDescription = () => {
		let text = itemData.description;
		text = text.replace(
			"{shortAccount}",
			`<a href=${contractLinkURL(itemData.chain_id, itemData.wallet)} target="_blank">${shortAccount(itemData.wallet_from)}</a>`
		);
		text = text.replace(
			"{tokenIDLink}",
			`<a href=${tokenLinkURL(itemData.chain_id, itemData.collection, itemData.token_id)} target="_blank">${itemData.token_id}</a>`
		);
		text = text.replace("{listingLink}", "");
		text = text.replace("{blockchain}", networkName(itemData.chain_id));

		return text;
	};

	return {
		date: moment(itemData.created_at).format("DD MMM YYYY"),
		description: formatedDescription(),
		type: itemData.type
	};
};

// Fetch Records
export const fetchTransactions = async ({ userID, skip, take = 20 }) => {
	const dbResponse = db.collection(firestore_collection_name);
	if (userID) dbResponse.where("user_id", "==", userID);

	const dbData = await dbResponse.orderBy("created_at", "desc").get();

	const data = [];

	dbData.docs.forEach(item => {
		data.push(setupTransactionData(item.id, item.data()));
	});

	return data;
};

export const addNewTransaction = async ({
	collection,
	user_id,
	wallet_from,
	wallet_to,
	description,
	transaction_id,
	chain_id,
	project_id,
	token_id,
	created_at,
	type,
}) => {
	let dbID = null;

	await db
		.collection(firestore_collection_name)
		.add({
			collection: collection ?? null,
			user_id: user_id ?? "",
			wallet_from: wallet_from ?? null,
			wallet_to: wallet_to ?? null,
			description,
			transaction_id: transaction_id ?? null,
			chain_id: chain_id ?? null,
			project_id: project_id ?? null,
			token_id: token_id ?? null,
			created_at,
			type,
		})
		.then(docRef => {
			dbID = docRef.id;
		});

	return dbID;
};

// export const setupCollectionTransactions = async () => {
// 	// get all collections and setup transactions
// 	const dbResponse = db.collection("collections").orderBy("symbol", "asc");
// 	const dbData = await dbResponse.get();

// 	dbData.docs.forEach(item => {
// 		const itemData = item.data();

// 		addNewTransaction({
// 			collection: item.id,
// 			user_id: "hIb8PtvZqQUdOyqm7rMStq31nSj2",
// 			wallet_from: itemData.owner,
// 			description: `{shortAccount} created NFT Collection "${itemData.symbol}" (ERC${itemData.contract_type}) on {blockchain}`,
// 			chain_id: itemData.chain_id,
// 			project_id: itemData.project_id,
// 			created_at: itemData.created_at,
// 			type: "create",
// 		});
// 	});
// };

// export const setupTokenTransactions = async () => {
// 	// get all collections and setup transactions
// 	const dbResponse = db.collection("tokens").where("burnt", "==", true);
// 	const dbData = await dbResponse.get();

// 	const collections = [];
// 	const collectionResponse = db.collection("collections");
// 	const collectionData = await collectionResponse.get();

// 	collectionData.docs.forEach(collection => (collections[collection.id] = collection.data()));

// 	dbData.docs.forEach(item => {
// 		const itemData = item.data();

// 		addNewTransaction({
// 			collection: itemData.collection,
// 			user_id: "hIb8PtvZqQUdOyqm7rMStq31nSj2",
// 			wallet_from: itemData.minter,
// 			description: `{shortAccount} burned ${
// 				collections[itemData.collection].symbol
// 			} ({tokenIDLink}) on {blockchain}`,
// 			chain_id: collections[itemData.collection].chain_id,
// 			project_id: collections[itemData.collection].project_id,
// 			token_id: itemData.token_id,
// 			created_at: itemData.created_at,
// 			type: "burn",
// 		});
// 	});
// };

// export const setupListingTransactions = async () => {
// 	// get all collections and setup transactions
// 	const dbResponse = db.collection("collections").orderBy("symbol", "asc");
// 	const dbData = await dbResponse.get();

// 	const targetCollections = [
// 		"0xE43dB72BbDB322Af740E9cCE662cE17F30644cF4",
// 		"0x5F73A559CB8B629c7953Ef1eB61a787C7233E04a",
// 		"0x07eaf59946991E529D5d8B90CB351daE09b3f883",
// 	];

// 	dbData.docs.forEach(async item => {
// 		if (!targetCollections.includes(item.id)) return;

// 		const collectionData = item.data();

// 		const listingResponse = db.collection("listings").where("collection_id", "==", item.id);
// 		const listingData = await listingResponse.get();

// 		listingData.docs.forEach(listing => {
// 			const itemData = listing.data();

// 			addNewTransaction({
// 				collection: itemData.collection_id,
// 				user_id: "hIb8PtvZqQUdOyqm7rMStq31nSj2",
// 				wallet_from: itemData.creator,
// 				description: `{shortAccount} listed ${collectionData.symbol} ({tokenIDLink}) for sale {listingLink}`,
// 				chain_id: itemData.chain_id,
// 				project_id: collectionData.project_id,
// 				token_id: itemData.token_id,
// 				created_at: itemData.created_at,
// 				type: "list",
// 			});
// 		});
// 	});
// };
