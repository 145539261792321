export const BlockGuide = () => (
	<>
		{/* <!-- mintable guides --> */}
		<section className="mintable-guides-area page-paddings">
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h6>Guides</h6>
							<h2 data-watermark="Guides">Guides For NFT Marketplace</h2>
							<div className="em_bar">
								<div className="em_bar_bg"></div>
							</div>
							<p className="subtitle">
								Get to know how to use NFT – Marketplace, how to sell or buy, and how to create your
								store on our platform.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
						<div className="mintable-guides-box text-center">
							<div className="mintable-guides-media">
								<img src="/assets/images/no-history.png" alt="" />
							</div>
							<div className="mintable-guides-info">
								<h3 className="theme-title">Step 1</h3>
								<p className="theme-description">
									Connect your web3 blockchain wallet
								</p>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
						<div className="mintable-guides-box text-center">
							<div className="mintable-guides-media">
								<img src="/assets/images/mintale2.png" alt="" />
							</div>
							<div className="mintable-guides-info">
								<h3 className="theme-title">Step 2</h3>
								<p className="theme-description">
									Find and contribute to your favorite project
								</p>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
						<div className="mintable-guides-box text-center">
							<div className="mintable-guides-media">
								<img src="/assets/images/success.png" alt="" />
							</div>
							<div className="mintable-guides-info">
								<h3 className="theme-title">Step 3</h3>
								<p className="theme-description">
									Sit back and relaxed, while waiting for your sales of produce returns
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!-- mintable guides --> */}
	</>
);
