import { createState, useState, postpone, none } from "@hookstate/core";
import {
	fetchCollections,
	addNewCollection,
	deleteCollection,
	updateCollectionSettings,
	archiveCollection as archiveCollectionDB
} from "src/db/firebase/collections";

// export interface Collection {
//     address: string;
//     name: string;
//     symbol: string;
//     owner: string;
//     token_count: number;
//     chain_id: number;
//     item_meta?: object;
//     created_at: number;
//     updated_at?: number;
// }

const collectionsState = createState(fetchCollections());
collectionsState.batch(state => {
	if (state.promised) return postpone;
});

export function useCollectionState() {
	const state = useState(collectionsState);

	return {
		get promised() {
			return state.promised;
		},
		get collections() {
			return !state.promised ? state.get() : [];
		},
		getProjectCollections(projectID) {
			return !state.promised ? state.get().filter(item => item.projectID === projectID) : [];
		},
		getCollection(collectionID) {
			const collection = !state.promised ? state.get().find(item => item.address === collectionID) : {};

			return collection;
		},
		async createCollection({ address, ...data }) {
			data.metadata = JSON.parse(data.metadata);
			
			//send to db
			const dbID = await addNewCollection({
				address,
				contract_type: data.contractType,
				project_id: data.projectID,
				name: data.name,
				symbol: data.symbol,
				owner: data.owner,
				commencement_date: data.campaignCommencementDate,
				offering_size: data.offeringSize,
				payout_schedule: data.payoutSchedule.map(payout => ({ timestamp: payout[0], percentage: payout[1], description: payout[2] ?? ""})),
				metadata: data.metadata,
				chain_id: data.chainID,
				pending: data.pending ?? false,
				royalty: data.royalty ?? 0,
			});

			data.address = address ?? dbID;
			data.itemCreationData = {};
			data.totalItems = 0;

			state.merge([data]);

			return dbID;
		},
		removeCollection(collectionID) {
			const idx = state.value.findIndex(i => i.address === collectionID);
			if (idx >= 0) {
				state[idx].set(none);
			}

			//send to db
			deleteCollection(collectionID);
		},
		incrementTokenCount(collectionID, byVaule = 1) {
			if (!state.promised) {
				const idx = state.findIndex(i => i.address.get() === collectionID);
				if (idx >= 0) {
					state[idx].totalItems.set(p => p + byVaule);
					state[idx].updatedAt.set(new Date().getTime());
				}
			}
		},
		updateMeta(collectionID, metadata) {
			if (!state.promised) {
				const idx = state.findIndex(i => i.address.get() === collectionID);
				if (idx >= 0) {
					state[idx].itemCreationData.set(metadata);
				}
			}
		},
		updateSettings(collectionID, settings) {
			//ToDo: check for category change and increment/decrement the listing count
			if (!state.promised) {
				const idx = state.findIndex(i => i.address.get() === collectionID);
				const settingData = {
					category: settings.category,
					description: settings.description,
					royalty: settings.royalty,
					youtube: settings.youtube,
					unit: settings.unit,
				};
				if (idx >= 0) {
					state[idx].merge(settingData);
				}
			}

			updateCollectionSettings(collectionID, settings);
		},
		archiveCollection(collectionID) {
			if (!state.promised) {
				const idx = state.findIndex(i => i.address.get() === collectionID);
				const settingData = {
					archived: true
				};

				if (idx >= 0) {
					state[idx].merge(settingData);
				}
			}

			archiveCollectionDB(collectionID);
		}
	};
}
