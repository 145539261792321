import { Outlet, useLocation, Link as RouterLink } from "react-router-dom";
import { experimentalStyled, Button } from "@material-ui/core";
import { useGlobalState, useSessionState, useSettingState } from "src/state";
import Modal from "src/components/Modal";
import MainNavbar from "./components/MainNavbar";
import MainFooter from "./components/MainFooter";
import "./assets/mainlayout/css/styles.css";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({}));

const MainLayoutWrapper = experimentalStyled("div")({});

const MainLayoutContainer = experimentalStyled("div")({});

const MainLayoutContent = experimentalStyled("div")({});

const MainLayout = () => {
	const location = useLocation();
	const { promised: isLoadingSettings, getSetting } = useSettingState();
	const { getValue, setValue } = useGlobalState();
	const { loggedInUser } = useSessionState();
	// useEffect(() => {
	//   if (!isLoadingSettings) {
	//     const favicon_url = getSetting('website_icon');
	//     if (favicon_url) {
	//       const favicon = document.getElementById("favicon");
	//       favicon.href = favicon_url;
	//     }
	//   }
	// }, [isLoadingSettings])
	const handleModalClose = () => {
		setValue("showVerificationMessage", false);
	};

	const pageClassName = () => {
		switch (true) {
			case location.pathname === "/":
				return "page-home";
				break;
			case location.pathname.search("/profile") >= 0:
				return "page-user-profile";
				break;
			default:
				return "page-inner";
				break;
		}
	};

	return isLoadingSettings ? null : (
		<MainLayoutRoot
			className={`${pageClassName()} ${getValue("showSearchBlock") ? "search-active" : ""} ${
				getValue("aModalOpened") ? "modal-open" : ""
			} ${loggedInUser && !loggedInUser.profileVerified && loggedInUser.profileSubmitted ? "unverfied-account" : ""}`}
		>
			<MainNavbar />
			<MainLayoutWrapper>
				<MainLayoutContainer>
					<MainLayoutContent>
						<Outlet />
					</MainLayoutContent>
				</MainLayoutContainer>
			</MainLayoutWrapper>
			<MainFooter />
			<Modal
				open={getValue("showVerificationMessage")}
				onClose={handleModalClose}
				content={
					<>
						<h1>Perform KYC!</h1>
						<p>
							You need to complete the Know Your Customer (KYC) process to create or purchase NFTs. Click
							the button below to get started.
						</p>
						<RouterLink to="/verify">
							<Button variant="contained" color="primary" fullWidth>
								Get Verified
							</Button>
						</RouterLink>
					</>
				}
			/>
		</MainLayoutRoot>
	);
};

export default MainLayout;
