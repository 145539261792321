import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Container, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { useGlobalState, useSessionState } from "src/state";
import { AddToWalletIcon } from "src/components/frontend/icons";

const Connect = () => {
	const blockchainInfo = useContext(BlockchainContext);
	const { getRedirectToAfterConnect, setRedirectToAfterConnect } = useGlobalState();

	const navigate = useNavigate();

	const { profile } = useSessionState();

	const connect = () => {
		if (blockchainInfo) blockchainInfo.connectToBlockchain();
	};

	useEffect(() => {
		if (blockchainInfo.account && profile) {
			if (window.location.hash && window.location.hash === "#buy" && profile.username === "") {
				navigate("/profile/settings", { replace: true });
			} else {
				const redirectTo = getRedirectToAfterConnect();
				if (redirectTo) {
					setRedirectToAfterConnect(null);
					navigate(redirectTo, { replace: true });
				} else {
					navigate("/", { replace: true });
				}
			}
		}
	}, [blockchainInfo, profile]);

	return (
		<Box className="user-panel-main-box">
			<Box className="container">
				<Box className="user-panel-main">
					<Container maxWidth="sm">
						<Typography align="center" variant="h2" sx={{ my: 2 }}>
							Connect your Wallet
						</Typography>
						<Typography align="center">
							Connect with one of our available wallet info providers or create a new one.
						</Typography>
						<List
							sx={{
								width: "100%",
								my: 3,
								bgcolor: "background.paper",
								color: "#333",
								borderRadius: "20px",
							}}
						>
							<ListItem onClick={connect} className="anchor">
								<ListItemAvatar>
									<Avatar>
										<AddToWalletIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Metamask" secondary="popular" />
							</ListItem>
						</List>
						<Typography variant="caption" align="center">
							Unable to find your preferred wallet provider? Bear with us, we are adding more to the list.
						</Typography>
						<Typography variant="h1">&nbsp;</Typography>
					</Container>
				</Box>
			</Box>
		</Box>
	);
};

export default Connect;
