import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { shortAccount } from "src/helpers/blockchain.helper";
import { initial } from "src/helpers/string.helper";
import metamaskIcon from "src/themes/default/assets/img/metamask-icon.svg";
import { Typography } from "@material-ui/core";
import { LinkableLocal } from "src/components/Linkable";

const UserHeaderMenu = ({ loggedInUser, onLogout }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const blockchainInfo = useContext(BlockchainContext);

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
		return false;
	};

	return (
		<div className="user-panel-header-top-right clearfix">
			<div className="user-panel-profile">
				<h3>
					Welcome! <span>{loggedInUser.username}</span>{" "}
					<a onClick={toggleDropdown}>
						<span>{initial(loggedInUser.username)}</span>
					</a>
				</h3>
				{dropdownOpen && (
					<div className="profile-dropdown animate__animated animate__fadeIn">
						<ul className="user-links">
							<li>
								<LinkableLocal
									isLinked={!blockchainInfo || !blockchainInfo.account}
									to="/connect-wallet"
									onClick={toggleDropdown}
									style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
								>
									{!blockchainInfo || !blockchainInfo.account
										? "Connect to Wallet"
										: `Connected with ${shortAccount(blockchainInfo.account)}`}
										<img alt="Metamask" src={metamaskIcon} width={25} />
								</LinkableLocal>
							</li>
							<li>
								<Link to="/profile/settings" onClick={toggleDropdown}>
									My Account
								</Link>
							</li>
							<li>
								<Link to="/profile/favourites" onClick={toggleDropdown}>
									My Favourites
								</Link>
							</li>
							<li>
								<Link to="/profile/projects" onClick={toggleDropdown}>
									My Projects
								</Link>
							</li>
							<li>
								<Link to="/faq" onClick={toggleDropdown}>
									Help
								</Link>
							</li>
							<li>
								<a onClick={onLogout} className="user-logout">
									Logout
								</a>
							</li>
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};

export default UserHeaderMenu;
