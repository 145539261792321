import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, List } from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  Collections as CollectionsIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
  Description as DescriptionIcon,
  Category as CategoryIcon
} from "@material-ui/icons";
import NavItem from "./NavItem";
import Logo from "src/components/Logo";
import { admin_base_path } from "src/routes";
import { useSessionState } from "src/state";

const items = [
  {
    href: "/dashboard",
    icon: DashboardIcon,
    title: "Dashboard",
  },
  {
    href: "/collections",
    icon: CollectionsIcon,
    title: `${process.env.REACT_APP_COLLECTION_TITLE}s`,
  },
  process.env.REACT_APP_HAVE_PROJECTS ? {
    href: "/projects",
    icon: DescriptionIcon,
    title: "Projects",
  } : null,
  {
    href: "/categories",
    icon: CategoryIcon,
    title: "Categories",
  },
  {
    href: "/users",
    icon: PeopleIcon,
    title: "Users",
  },
  {
    href: "/transactions",
    icon: ReceiptIcon,
    title: "Transactions",
  },
  {
    href: "/settings",
    icon: SettingsIcon,
    title: "Settings",
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { logout } = useSessionState();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2
        }}
      >
        <Logo />
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => item?.title && (
            <NavItem
              href={admin_base_path + item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ backgroundColor: "background.default", p: 2 }}>
        <NavItem
          onClick={logout}
          key="Logout"
          title="Logout"
          icon={LogoutIcon}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 256,
            display: { lg: "none", xs: "block" },
          },
        }}
      >
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: 256,
            //top: 64,
            height: "100%",
            display: { xs: "none", lg: "block" },
          },
        }}
      >
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
