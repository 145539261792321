import { useContext } from "react";
import Logo from "src/components/Logo";
import { useGlobalState } from "src/state";
import { Link as RouterLink } from "react-router-dom";
import { LockedModeContext } from "src/providers/LockedModeProvider";

const SearchBlock = () => {
	const { setValue } = useGlobalState();

	return (
		<>
			{/* <!-- header search --> */}
			<div className="header-search-box animate__animated animate__fadeIn">
				<div className="header-search-box-main">
					<div className="nft-input-box validate-input" data-validate="Name is required">
						<input className="nft-input" type="text" name="name" placeholder="Search for anything" />
						<span className="focus-nft-input"></span>
						<a href="search.html" className="theme-btn">
							Search
						</a>
					</div>
				</div>
			</div>
			<div className="search-close" onClick={() => setValue("showSearchBlock", false)}>
				<i className="fas fa-times"></i>
			</div>
			{/* <!-- header search --> */}
		</>
	);
};

const MainFooter = () => {
	const lockModeInfo = useContext(LockedModeContext);
	return (
		<>
			{/* <!-- footer --> */}
			<footer className="footer-main">
				{!lockModeInfo.lockedMode && (
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
									<div className="footer-widget">
										<div style={{ maxWidth: "250px" }}>
											<Logo />
										</div>
										<p className="theme-description">
											We are forward looking WEB 3 Agri-technology NFT Marketplace to help
											Agri-projects in the value chain to increase their potential growth.
										</p>
										<div className="footer-links clearfix">
											<div className="footer-icon-box">
												<a href="#" title="Facebook" style={{ color: "#3b5998" }}>
													<i className="fab fa-facebook-f"></i>
												</a>
											</div>
											<div className="footer-icon-box">
												<a href="#" title="Twitter" style={{ color: "#00acee" }}>
													<i className="fab fa-twitter"></i>
												</a>
											</div>
											<div className="footer-icon-box">
												<a href="#" title="Linkedin" style={{ color: "#0e76a8" }}>
													<i className="fab fa-linkedin-in"></i>
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
									<div className="footer-widget">
										<h3 className="theme-title">My account</h3>
										<div className="footer-menu-services">
											<ul className="menu-service-menu">
												<li>
													<RouterLink to="/profile/projects/add">Create a Project</RouterLink>
												</li>
												<li>
													<RouterLink to="/profile/projects">My Projects</RouterLink>
												</li>
												<li>
													<RouterLink to="/profile/settings">My Profile</RouterLink>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
									<div className="footer-widget">
										<h3 className="theme-title">Need Help?</h3>
										<div className="footer-menu-services">
											<ul className="menu-service-menu">
												<li>
													<RouterLink to="/faq">FAQ</RouterLink>
												</li>
												<li>
													<RouterLink to="/contact">Contact us</RouterLink>
												</li>
											</ul>
										</div>
									</div>
								</div>
								{/* <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12">
								<div className="footer-widget">
									<h3 className="theme-title">Buy an Item</h3>
									<div className="footer-menu-services">
										<ul className="menu-service-menu">
											<li>
												<a href="#">Browse Digital Items</a>
											</li>
											<li>
												<a href="#">Browse Stores</a>
											</li>
											<li>
												<a href="#">Where to buy NFTs</a>
											</li>
										</ul>
									</div>
								</div>
							</div> */}
								<div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12">
									<div className="footer-widget">
										<h3 className="theme-title">Company</h3>
										<div className="footer-menu-services">
											<ul className="menu-service-menu">
												<li>
													<RouterLink to="/about">About Us</RouterLink>
												</li>
												<li>
													<RouterLink to="/privacy">Privacy Policy</RouterLink>
												</li>
												<li>
													<RouterLink to="/terms">Terms of use</RouterLink>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="footer-copyright">
					<div className="container">
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="copyright-text text-center">
									<p>
										Copyright 2022 <a href="/">{process.env.REACT_APP_SITENAME}</a> All Rights
										Reserved.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* <!-- footer --> */}
			<SearchBlock />
		</>
	);
};

export default MainFooter;
