import { createState, useState } from "@hookstate/core";

const defaultErrorState = {
  showError: false,
  errorMessage: ""
};

const errorState = createState({ ...defaultErrorState });

export function useErrorState() {
  const state = useState(errorState);

  return {
    get isError() {
      return state.showError.value;
    },
    get errorMessage() {
      return state.errorMessage.value;
    },
    setErrorMessage(message, onButtonClick = null) {
      state.set({
        showError: true,
        errorMessage: message,
        buttonClickHandler: onButtonClick
      });
    },
    clearError() {
      // check if got button click handler
      if (state.buttonClickHandler.value)
        state.buttonClickHandler.value()
        
      state.set({ ...defaultErrorState });
    },
  };
}
