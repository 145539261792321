import { useEffect, useRef, useState, useContext } from "react";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import { Box, Button, CircularProgress, FormControlLabel, FormGroup, Switch, Typography } from "@material-ui/core";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { readBlobToBuffer, uploadFileToIPFS } from "src/helpers/file.helper";
import jsonObjectTemplate from "src/metadata/ERC721";
import TextField from "src/components/FormikTextField";
import { isValidAddress, networkName, shortAccount } from "src/helpers/blockchain.helper";
import { hash_to_ipfs_link } from "src/helpers/links.helper";
import ImagePicker from "src/components/ImagePicker";
import { toTitleText } from "src/helpers/string.helper";
import moment from "moment";

const ST = require("stjs");

const ItemForm = ({
	defaultData,
	projectData,
	collectionData,
	onCreateItem,
	toValidateSku,
	isForFrontend,
	...props
}) => {
	const blockchainInfo = useContext(BlockchainContext);
	const [selectedImage, setSelectedImage] = useState("");
	const [selectedAnimationFile, setSelectedAnimationFile] = useState("");
	const [haveAnimation, setHaveAnimation] = useState(false);

	const selectedImageBuffer = useRef(null);
	const selectedAnimationBuffer = useRef(null);
	const selectedAnimationFileType = useRef(null);

	useEffect(() => {
		return function () {
			if (selectedImage) {
				URL.revokeObjectURL(selectedImage);
				selectedImageBuffer.current = null;
			}
		};
	}, [selectedImage]);

	const handleFileSelect = (filename, file) => {
		if (file && file.type.match("^image/")) {
			readBlobToBuffer(file).then(imageBuffer => (selectedImageBuffer.current = imageBuffer));
			setSelectedImage(URL.createObjectURL(file));
		}
	};

	const handleAnnimationSelect = (filename, file) => {
		if (file && (file.type.match("^image/gif") || file.type.match("^video/"))) {
			readBlobToBuffer(file).then(imageBuffer => (selectedAnimationBuffer.current = imageBuffer));
			setSelectedAnimationFile(URL.createObjectURL(file));

			const fileType = file.type.match("^video/") ? "video" : "image";
			selectedAnimationFileType.current = fileType;
		}
	};

	const handleGotAnimation = () => setHaveAnimation(!haveAnimation);

	const formik = useFormik({
		initialValues: {
			item_sku: "",
			item_name: defaultData ? defaultData.item_name : "",
			digital_asset_number: "",
			wallet_address: "",
		},
		onSubmit: async (values, formikHelpers) => {
			if (projectData && projectData.project_name) {
				values.description = projectData.project_summary;
				values.external_url = `${process.env.REACT_APP_EXTERNAL_URL_BASE}project/${projectData.id}`;
			}

			const jsonObj = ST.select(values).transformWith(jsonObjectTemplate).root();

			const skipKeys = [
				"id",
				"created_at",
				"created_by",
				"collection_count",
				"pending_approval",
				"category",
				"project_desc",
				"project_details",
				"reserve_for_lp",
				"reserve_for_lp_percentage",
				"reserve_for_lp_months",
				"payment_schedule",
			];

			if (projectData && projectData.project_name) {
				for (const key in projectData) {
					if (skipKeys.includes(key) || projectData[key] === "") continue;

					let value = "";
					try {
						if (typeof projectData[key] === "object") {
							projectData[key].map(item => (value += `${item.title}: ${item.value}` + "\n "));
						} else value = projectData[key];
					} catch (e) {
						value = projectData[key];
					}

					jsonObj.attributes.push({
						key,
						trait_type: toTitleText(key),
						value: value,
					});
				}
			}

			// include collection data as well
			if (collectionData) {
				if (collectionData.campaignCommencementDate) {
					jsonObj.attributes.push({
						key: "commencement_date",
						trait_type: "Contract Start Date",
						value: collectionData.campaignCommencementDate,
					});

					jsonObj.attributes.push({
						key: "expiry_date",
						trait_type: "Contract End Date",
						value: moment
							.unix(collectionData.campaignCommencementDate)
							.add(collectionData.metadata.sales_return_period, "months")
							.subtract(1, "day")
							.format("DD MMM YYYY"),
					});
				}

				jsonObj.attributes.push({
					key: "offering_size",
					trait_type: "Offering Size",
					value: collectionData.offeringSize,
				});

				let payoutSchedule = "";
				collectionData.payoutSchedule.map(
					item =>
						(payoutSchedule +=
							`On ${moment.unix(item.timestamp).format("DD MMM YYYY")}: ${item.percentage}` +
							"%\n " +
							(item.description ? `(${item.description})` + "\n " : ""))
				);

				jsonObj.attributes.push({
					key: "payout_schedule",
					trait_type: "Payout Schedule",
					value: payoutSchedule,
				});

				if (collectionData.metadata && collectionData.metadata.capital_request) {
					for (const key in collectionData.metadata) {
						jsonObj.attributes.push({
							key,
							trait_type: toTitleText(key),
							value: collectionData.metadata[key],
						});
					}
				}
			}

			// add the digital asset number
			if (values.digital_asset_number) {
				jsonObj.attributes.push({
					key: "digital_asset_number",
					trait_type: "Digital Asset No.",
					value: values.digital_asset_number,
				});
			}

			// sort the attributes for easy reference
			const sortedJSONAttributes = jsonObj.attributes.sort((a, b) => a.key.localeCompare(b.key));

			jsonObj.attributes = sortedJSONAttributes;

			try {
				const imageHash = await uploadFileToIPFS(selectedImageBuffer.current);

				let animationHash = null;
				if (haveAnimation && selectedAnimationBuffer.current) {
					animationHash = await uploadFileToIPFS(
						selectedAnimationBuffer.current,
						selectedAnimationFileType.current
					);
					jsonObj.animation_url = hash_to_ipfs_link(animationHash);
				}

				// add the image url to metadata
				jsonObj.image = hash_to_ipfs_link(imageHash);

				// upload the metadata to ipfs
				const jsonBuffer = Buffer.from(JSON.stringify(jsonObj));
				const jsonHash = await uploadFileToIPFS(jsonBuffer, 'json');

				formikHelpers.setSubmitting(false);
				// return back the data to parent page
				onCreateItem &&
					onCreateItem({
						item_sku: values.item_sku,
						metadata_hash: jsonHash,
						image_url: jsonObj.image,
						animation_url: jsonObj.animation_url ?? null,
						animation_file_type: selectedAnimationFileType.current,
						metadata: values,
						wallet_address: values.wallet_address,
						//certificate_hash: certificateHash,
					});
			} catch (error) {
				formikHelpers.setSubmitting(false);
				console.error(error);
				return;
			}
		},
		validationSchema: Yup.object().shape({
			item_sku: Yup.string()
				.max(30)
				.required("Please fill in the Physical Asset No.")
				.test("item_sku", "This Physical Asset No. has already been added", function (item_sku) {
					return !toValidateSku || toValidateSku(item_sku);
				}),
			item_name: Yup.string().max(100).required("Item Name is required"),
			wallet_address: Yup.string().test("is_valid", "This address is not valid", function (value) {
				return !value || isValidAddress(value);
			}),
		}),
	});

	return (
		<FormikProvider value={formik}>
			<Form>
				<Typography mb={2} variant="h5">
					Please ensure the details you adding are correct, as these can not be modified once created.
				</Typography>
				<ImagePicker
					withDragDrop
					name="project_image"
					onFileSelect={handleFileSelect}
					value={selectedImage}
					buttonProps={{ color: "primary", variant: "contained" }}
				/>
				<FormGroup>
					<FormControlLabel
						control={<Switch onChange={handleGotAnimation} />}
						label="Have animation or video?"
					/>
				</FormGroup>
				{haveAnimation && (
					<ImagePicker
						withDragDrop
						name="animation_file"
						onFileSelect={handleAnnimationSelect}
						value={selectedAnimationFile}
						buttonProps={{ color: "primary", variant: "contained" }}
						accept="image/gif, video/*"
					/>
				)}
				<TextField
					fullWidth
					helperText="This must be a unique code, as the NFT will be created based on this"
					label="Physical Asset No."
					margin="normal"
					name="item_sku"
					type="text"
					variant="outlined"
				/>
				<TextField
					fullWidth
					label="Item Name"
					margin="normal"
					name="item_name"
					type="text"
					variant="outlined"
				/>
				<TextField
					fullWidth
					label="Digital Asset Number"
					margin="normal"
					name="digital_asset_number"
					type="text"
					variant="outlined"
				/>
				{!isForFrontend && (
					<TextField
						fullWidth
						label="Mint on behalf of another Wallet Address"
						helperText="If specified, the NFT will be owned by the given wallet address"
						margin="normal"
						name="wallet_address"
						type="text"
						variant="outlined"
					/>
				)}
				<Box sx={{ pt: 2 }}>
					<Button
						color="primary"
						disabled={
							formik.isSubmitting ||
							!formik.isValid ||
							(!formik.touched.item_sku && !formik.isInitialValid)
						}
						fullWidth
						size="large"
						type="submit"
						variant="contained"
					>
						{formik.isSubmitting ? <CircularProgress size={20} /> : "Create"}
					</Button>
				</Box>
				<Typography variant="caption" component="p" sx={{ mt: 2 }} color="error" align="center">
					You are connected to {networkName(blockchainInfo ? blockchainInfo.networkId : 0)} with account{" "}
					{shortAccount(blockchainInfo ? blockchainInfo.account : null)}
				</Typography>
			</Form>
		</FormikProvider>
	);
};
export default ItemForm;
