import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Alert, Box, Container } from "@material-ui/core";
import Logo from "../../components/Logo";
import { admin_link } from "src/helpers/links.helper";
import { useSessionState, useGlobalState } from "src/state";
import EmailLogin from "src/components/access/EmailLogin";

import "src/layouts/assets/mainlayout/css/login.css";

const Login = () => {
	const navigate = useNavigate();
	const { loggedInUser, fetchUserProfile } = useSessionState();
	const { getRedirectToAfterConnect, setRedirectToAfterConnect } = useGlobalState();

	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		const redirectTo = getRedirectToAfterConnect();

		if (loggedInUser) {
			console.log('loggedInUser', loggedInUser)
			if (loggedInUser.type === "admin") navigate(admin_link("/"), { replace: true });
			else {
				//check if user is verified, or redirect to verification process
				if (!loggedInUser.profileVerified) {
					if (!loggedInUser.profileSubmitted) navigate("/verify", { replace: true });
					else navigate("/profile/settings", { replace: true });
					return;
				}

				if (redirectTo) {
					setRedirectToAfterConnect(null);
					navigate(redirectTo, { replace: true });
				} else {
					navigate("/", { replace: true });
				}
			}
		}
	}, [loggedInUser]);

	const handleLoginSuccess = userInfo => {
		// fetch the users profile
		fetchUserProfile(userInfo.uid);
		// loggedInUser = { username: userInfo.email };
		// navigate(admin_link("/"), { replace: true });
	};

	const handleLoginFailed = message => {
		setErrorMessage(message);
	};

	return (
		<>
			<Box className="fxt-template-animation nft-login-template">
				<Box className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-xl-6 col-lg-7 col-sm-12 col-12 fxt-bg-color">
							<div className="fxt-content">
								<div className="fxt-home-link">
									<RouterLink to="/">
										<i className="fas fa-home"></i> Home
									</RouterLink>
								</div>
								<div className="fxt-header">
									<RouterLink to="/" className="fxt-logo">
										<Logo mode="small" />
									</RouterLink>
									<p>
										Welcome Back!
										<br />
										Please enter your Registered Email &amp; Password
									</p>
								</div>
								<div className="fxt-form">
									<EmailLogin onLoginSuccess={handleLoginSuccess} onLoginFailed={handleLoginFailed} />
									{errorMessage && <Alert severity="error">{errorMessage}</Alert>}
								</div>
								<Box my={2} className="fxt-footer">
									<div className="fxt-transformY-50 fxt-transition-delay-9">
										<p>
											Don't have an account?
											<RouterLink to="/signup" className="switcher-text2 inline-text">
												Register
											</RouterLink>
										</p>
									</div>
								</Box>
							</div>
						</div>
					</div>
				</Box>
			</Box>
		</>
	);
};

export default Login;
