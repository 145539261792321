import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import RefreshIcon from "@material-ui/icons/Refresh";
import CallMadeIcon from "@material-ui/icons/CallMade";
import SearchBar from "src/components/SearchBar";
import { contractLinkURL } from "src/helpers/blockchain.helper";
import { LinkableExternal } from "../Linkable";
import moment from "moment";

const _Toolbar = ({
	collection,
	project = null,
	onPressAddButton,
	onPressConfigButton,
	onPressRefreshButton,
	onSearch,
	...props
}) => (
	<Box {...props}>
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<Typography variant="h2" mr={1} color="textPrimary">
					{process.env.REACT_APP_HAVE_PROJECTS && project && `${project.project_name} - `}
					{collection.symbol} - Items
				</Typography>
				<LinkableExternal to={contractLinkURL(collection.chainID, collection.address)} isLinked={true}>
					<CallMadeIcon color="disabled" />
				</LinkableExternal>
			</Box>
			<Box>
				<Button onClick={onPressConfigButton}>
					<SettingsIcon />
				</Button>
				{(!collection.campaignCommencementDate || moment.unix(collection.campaignCommencementDate).isSameOrAfter(moment())) && (
					<Button color="primary" variant="contained" onClick={onPressAddButton}>
						Add Item
					</Button>
				)}
			</Box>
		</Box>
		<Box sx={{ mt: 3 }}>
			<Card>
				<CardContent style={{ paddingBottom: 16 }}>
					<Grid container spacing={2} sx={{ justifyContent: "space-between", alignItems: "center" }}>
						<Grid item xs={8}>
							<SearchBar
								sx={{ maxWidth: 500 }}
								placeholder="Search Collection Items"
								onSearch={onSearch}
							/>
						</Grid>
						<Grid item>
							<Button onClick={onPressRefreshButton}>
								<RefreshIcon />
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Box>
	</Box>
);

export default _Toolbar;
