import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Pagination } from "@material-ui/core";
import Loading from "src/components/Loading";
import { useSessionState, useTransactionState } from "src/state";

const ProfileTransactions = () => {
	const { profile } = useSessionState();
	const { promised: isTransactionLoading, transactions } = useTransactionState({ userID: profile.userID });

	const [pageData, setPageData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);

	const itemsPerPage = 20;

	useEffect(() => {
		if (!isTransactionLoading) {
			const totalPages = Math.ceil(transactions.length / itemsPerPage);
			setTotalPages(totalPages);

			paginate(1);
		}
	}, [isTransactionLoading]);

	const paginate = pageNo => {
		if (!pageNo) pageNo = 1;
		if (!transactions || !transactions.length) return;

		console.log(transactions);

		const pageData = transactions.slice((pageNo - 1) * itemsPerPage, pageNo * itemsPerPage);
		setPageData(pageData);
	};

	const handlePagination = (event, page) => {
		paginate(page);
		window.scrollTo(0, 0);
	};

	return (
		<>
			<section className="min-item-area page-paddings">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="section-title text-center">
								<h2 data-watermark="Items">My Transactions</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="user-panel-main">
								<div className="user-store-area">
									{isTransactionLoading ? (
										<div className="user-no-record-found">
											<Loading />
										</div>
									) : transactions.length === 0 ? (
										<div className="user-no-record-found">
											<div className="user-no-record-icon">
												<i className="fas fa-store-alt"></i>
											</div>
											<h3 className="user-title">You Have No Past Transactions</h3>
											<p className="theme-description">
												You can browse through our projects to contribute and earn sales of
												produce return, wile collecting our NFTs.
											</p>
											<div className="user-btn">
												<RouterLink to="/projects" className="theme-btn">
													Start Contributing
												</RouterLink>
											</div>
										</div>
									) : (
										<Box className="theme-table" my={4} sx={{ minHeight: "300px" }}>
											<table style={{ width: "100%" }}>
												<thead>
													<tr>
														<th>Date</th>
														<th>Type</th>
														<th>Description</th>
													</tr>
												</thead>
												<tbody>
													{pageData.map((transaction, index) => (
														<tr key={`transaction-${index}`}>
															<td>{transaction.date}</td>
															<td>{transaction.type}</td>
															<td
																dangerouslySetInnerHTML={{
																	__html: transaction.description,
																}}
															/>
														</tr>
													))}
												</tbody>
											</table>
											<Box my={3}>
												<Pagination
													count={totalPages}
													variant="outlined"
													color="primary"
													onChange={handlePagination}
												/>
											</Box>
										</Box>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ProfileTransactions;
