import React, { useContext, useEffect, useRef, useState } from "react";
import Loading from "src/components/Loading";
import { useListingState, useCollectionState, useSettingState, useProjectsState } from "src/state";
import Collections from "src/components/frontend/Collections";
import Projects from "src/components/frontend/Projects";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { Box } from "@material-ui/core";
import Modal from "src/components/Modal";
import YoutubeVideoPlayer from "src/components/frontend/YoutubeVideoPlayer";
import { pluck } from "src/helpers/array.helper";
import { BlockEarning, BlockGuide, BlockHero, BlockSell } from "src/components/frontend/partials/home";

const Home = () => {
	// const blockchainInfo = useContext(BlockchainContext);
	const {
		promised: isListingLoading,
		listings,
		//fetch: fetchListings,
	} = useListingState();
	const { promised: isCollectionsLoading, collections } = useCollectionState();
	const { promised: isProjectsLoading, projects } = useProjectsState();
	const featured = useRef(-1);

	const [openVideoPopup, setOpenVideoPopup] = useState(false);
	const [playingVideo, setPlayingVideo] = useState(false);
	const openVideo = videoURL => {
		setPlayingVideo(videoURL);
		setOpenVideoPopup(true);
	};

	const handleModalClose = () => {
		setOpenVideoPopup(false);
		setPlayingVideo(false);
	};

	return (
		<>
			<BlockHero />
			<section className="most-viewed-items-area page-paddings" style={{ paddingTop: 0 }}>
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="section-title text-center">
								<h6>Items</h6>
								<h2 data-watermark="Items">Items Viewed The Most</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
								<p className="subtitle">
									You can see the best and most viewed items; from all categories, we have a wide
									range of items available on our marketplace.
								</p>
							</div>
						</div>
					</div>
					<Box sx={{ py: 3 }}>
						{!process.env.REACT_APP_HAVE_PROJECTS && isCollectionsLoading ? (
							<Loading />
						) : !process.env.REACT_APP_HAVE_PROJECTS && collections.length > 0 ? (
							<Collections
								collections={collections.filter(item => !item.archived)}
								title="Our Collections"
							/>
						) : null}
						{process.env.REACT_APP_HAVE_PROJECTS && isProjectsLoading ? (
							<Loading />
						) : process.env.REACT_APP_HAVE_PROJECTS && projects.length > 0 ? (
							<Projects projects={projects} />
						) : null}
					</Box>
				</div>
			</section>
			<BlockEarning />
			<BlockSell />
			<BlockGuide />
			<Modal
				open={openVideoPopup}
				fullWidth={true}
				onClose={handleModalClose}
				content={<YoutubeVideoPlayer youtubeUrl={playingVideo} />}
			/>
		</>
	);
};

export default Home;
