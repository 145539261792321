import { useEffect } from "react";
import { createState, useState } from "@hookstate/core";
import { fetchTransactions } from "src/db/firebase/transactions";

const transactionsState = createState({});

export function useTransactionState({ userID, skip = 0, take = 20 }) {
	const state = useState(transactionsState);
	const loading = useState(false);

	const loadTransactions = async () => {
		try {
			loading.set(true);

			const loaded = state[userID]?.get();

			if (!loaded) {
				const transactions = await fetchTransactions({ userID });
				const obj = {};
				obj[userID] = transactions ?? [];
				state.merge(obj);
			}

			loading.set(false);
		} catch (e) {}
	};

	useEffect(() => {
		loadTransactions();
	}, []);

	return {
		get promised() {
			return loading.value;
		},
		get transactions() {
			const t = state[userID]?.value;
			return t ? t : [];
		},
	};
}
