import { useState } from "react";
import { Box, Button, Container } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Loading from "src/components/Loading";
import ListView from "src/components/ListingTable";
import Toolbar from "src/components/projects/ProjectsToolbar";
import { admin_link } from "src/helpers/links.helper";
import { useProjectsState } from "src/state";

const Projects = () => {
  const navigate = useNavigate();
  const [filterKeyword, setFilterKeyword] = useState("");
  const { promised: loadingProjects, projects } = useProjectsState();
  const isLoading = false;

  const handleAddProject = () => {
    navigate(admin_link("/projects/new"));
  };

  const handleEdit = (id) => {
    navigate(admin_link("/projects/edit/" + id));
  };

  const filterProjects = () => {};

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          {isLoading || loadingProjects ? (
            <Loading />
          ) : (
            <>
              <Toolbar
                onPressAddButton={handleAddProject}
                onSearch={filterProjects}
              />
              <Box sx={{ pt: 3 }}>
                <ListView
                  items={projects.filter((project) => {
                    const filterBy = new RegExp(filterKeyword, "i");
                    return (
                      filterKeyword === "" ||
                      project.project_name.search(filterBy) >= 0
                    );
                  })}
                  fields={[
                    { key: "project_name", title: "Project Name" },
                    { key: "collection_count", title: `Added ${process.env.REACT_APP_COLLECTION_TITLE}s`, type: "number" },
                    { key: "created_at", title: "Created At", type: "date" },
                  ]}
                  showLoadingIfEmpty="alwaysShow"
                  customLoadingComponent={(props) => (
                    <Box sx={{ textAlign: "right" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(props.id)}
                      >
                        Edit
                      </Button>
                    </Box>
                  )}
                />
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Projects;
