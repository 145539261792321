import { useEffect, useRef, useState } from "react";
import { FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import { Alert, Box, Button, Container, TextField, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { uploadFile as uploadFileToCloudinary } from "src/plugins/Cloudinary";
import { useSessionState, useGlobalState } from "src/state";
import MultilineField from "src/components/MultilineField";
import moment from "moment";
import { asyncForEach } from "src/helpers/array.helper";

export default ({ onSave }) => {
	const { setProfile, profile, setFarmerProfile, farmerProfile } = useSessionState();
	const [isLoading, setIsLoading] = useState(true);

	const seminars = useRef([]);
	const cropTypes = useRef([]);

	const setSeminars = newValues => {
		seminars.current = newValues;
	};

	useEffect(() => {
		setIsLoading(false);
		if (!farmerProfile) return;

		if (profile && profile.accountType === "farmer" && !seminars.current.length) {
			seminars.current = farmerProfile.seminarsAttended ?? [];
		}
		if (profile && profile.accountType === "farmer" && !cropTypes.current.length) {
			cropTypes.current = farmerProfile.experience ?? [];
		}
	}, [profile, farmerProfile]);

	const setCropTypes = newValues => {
		cropTypes.current = newValues;
	};

	if (isLoading) return null;

	return (
		<Formik
			initialValues={{
				educationLevel: "",
				numberOfHousehold: "",
				farmerType: "",
				farmHours: "",
				seminarsAttended: [],
				experience: [],
				totalYearsOfFarming: "",
				incomeOutsideFarming: "",
				farmSize: "",
				...farmerProfile,
			}}
			// validationSchema={Yup.object().shape({
			// 	username: Yup.string().max(255).required("Username is required"),
			// })}
			onSubmit={async (values, { setSubmitting }) => {
				// remove userID, email from the data
				const { userID, email, ...rest } = values;

				// upload any selected files
				await asyncForEach(seminars.current, async (seminar, index) => {
					if (seminar["certificate-selected-file"]) {
						const url = await uploadFileToCloudinary(
							seminar["certificate-selected-file"],
							`${profile.userID}-${moment().unix()}`
						);
						seminars.current[index]["certificate-file"] = url;
						delete seminars.current[index]["certificate-selected-file"];
					}
				});

				setFarmerProfile(profile.userID, {
					...rest,
					seminarsAttended: seminars.current,
					experience: cropTypes.current,
				});
				setSubmitting(false);

				onSave && onSave();

				// const redirectTo = getRedirectToAfterConnect();
				// if (redirectTo) {
				// 	setRedirectToAfterConnect(null);
				// 	navigate(redirectTo, { replace: true });
				// }
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, dirty, isValid }) => (
				<form onSubmit={handleSubmit}>
					<Grid container>
						<Grid item xs={12} md={6} px={3}>
							<Box mb={3}>
								<Typography variant="h5" mb={2}>Education Level</Typography>
								<FormControl fullWidth>
									<Select className="nolegend" name="educationLevel" value={values.educationLevel} onChange={handleChange} onBlur={handleBlur}>
										<MenuItem value="college_graduate">College Graduate</MenuItem>
										<MenuItem value="college_under_graduate">College Under Graduate</MenuItem>
										<MenuItem value="high_school">High School</MenuItem>
										<MenuItem value="elementary">Elementary</MenuItem>
										<MenuItem value="none">None</MenuItem>
									</Select>
								</FormControl>
							</Box>

							<FormControl fullWidth>
								<Typography variant="h5" mb={2}>Number of Household</Typography>
								<Select className="nolegend" name="numberOfHousehold" value={values.numberOfHousehold} onChange={handleChange} onBlur={handleBlur}>
									<MenuItem value="1-3">1-3 People</MenuItem>
									<MenuItem value="4-6">4-6 People</MenuItem>
									<MenuItem value="6 Up">6 Up People</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6} px={3}>
							<Box mb={3}>
								<FormControl fullWidth>
									<Typography variant="h5" mb={2}>Farmer Type</Typography>
									<Select className="nolegend" name="farmerType" value={values.farmerType} onChange={handleChange} onBlur={handleBlur}>
										<MenuItem value="Existing Type">I am currently doing Farming</MenuItem>
										<MenuItem value="Returning Type">I want to return back to Farming</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<FormControl fullWidth>
								<Typography variant="h5" mb={2}>Hours can spend on farms (daily)</Typography>
								<Select className="nolegend" name="farmHours" value={values.farmHours} onChange={handleChange} onBlur={handleBlur}>
									<MenuItem value="1 - 3 Hours">1 - 3 Hours</MenuItem>
									<MenuItem value="4 - 6 Hours">4 - 6 Hours</MenuItem>
									<MenuItem value="More than 6 hours">More than 6 hours</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="theme-form-title text-center">
								<h3 className="theme-title">
									<span>Seminars Attended</span>
								</h3>
							</div>
						</div>
					</div>
					<Box sx={{ color: "#989898" }} px={3}>
						<MultilineField
							items={seminars.current}
							onChange={setSeminars}
							fields={[
								{
									name: "title",
									title: "Seminar Name",
								},
								{
									name: "value",
									title: "Date",
								},
								{
									name: "certificate",
									title: "With Certificate",
									type: "file",
									//options: [{ title: "Yes", value: "yes" }],
									mode: "optional",
								},
							]}
							addButtonText="Add another Seminar"
							addButtonAlignment="left"
						/>
					</Box>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="theme-form-title text-center">
								<h3 className="theme-title">
									<span>Experience</span>
								</h3>
							</div>
						</div>
					</div>
					<Box sx={{ marginBottom: "25px" }} px={3}>
						<MultilineField
							items={cropTypes.current}
							onChange={setCropTypes}
							fields={[
								{
									name: "title",
									title: "Type of Farming",
									type: "select",
									options: [
										{ "key": "Rice", "title": "Palay/Rice" },
										{ "key": "Corn", "title": "Corn" },
										{ "key": "Banana", "title": "Banana" },
										{ "key": "Sugarcane", "title": "Sugarcane" },
										{ "key": "Pig", "title": "Pig Farm" },
										{ "key": "Poultry", "title": "Poultry" },
										{ "key": "Fish", "title": "Fish Farm" },
										{ "key": "Other", "title": "Other Farming" },
									]
								},
								{
									name: "value",
									title: "Years",
								},
							]}
							addButtonText="Add More"
							addButtonAlignment="left"
						/>
					</Box>
					<Box px={3}>
						<div className="row">
							<div className="col-md-6 col-sm-12 col-12">
								<TextField
									fullWidth
									label="Total Years of Farming"
									margin="normal"
									name="totalYearsOfFarming"
									type="text"
									variant="outlined"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.totalYearsOfFarming}
								/>
							</div>
							<div className="col-md-6 col-sm-12 col-12">
								<TextField
									fullWidth
									label="Income outside farming"
									margin="normal"
									name="incomeOutsideFarming"
									type="text"
									variant="outlined"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.incomeOutsideFarming}
								/>
							</div>
							<div className="col-md-6 col-sm-12 col-12">
								<TextField
									fullWidth
									label="Farm Size in Sq. Meters"
									margin="normal"
									name="farmSize"
									type="text"
									variant="outlined"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.farmSize}
								/>
							</div>
						</div>
					</Box>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="theme-input-box text-center" style={{ marginTop: "30px" }}>
								<Button
									className="theme-btn"
									disabled={isSubmitting}
									size="large"
									type="submit"
									variant="contained"
								>
									Save & Continue
								</Button>
							</div>
						</div>
					</div>
				</form>
			)}
		</Formik>
	);
};
