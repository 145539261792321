import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Box, Button, Container, TextField, Typography } from "@material-ui/core";
import { useSessionState } from "src/state";

const Favourites = () => {
	const navigate = useNavigate();
	const sessionState = useSessionState();

	const [errorMessage, setErrorMessage] = useState("");

	return (
		<>
			<section className="min-item-area page-paddings">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="section-title text-center">
								<h2 data-watermark="Items">My Favourites</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="user-panel-main">
								<div className="user-store-area">
									<div className="user-no-record-found">
										<div className="user-no-record-icon">
											<i className="fas fa-store-alt"></i>
										</div>
										<h3 className="user-title">You Have No Favourites.</h3>
										<p className="theme-description">
											Browse through the projects and add to your favourite, the projects you find
											of your interest!
										</p>
										<div className="user-btn">
											<Link to="/projects" className="theme-btn">
												Browse Projects
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Favourites;
