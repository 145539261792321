import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Box, Button, CircularProgress, Container, Grid, TextField, Typography } from "@material-ui/core";
import { useErrorState, useSessionState } from "src/state";
import ImagePicker from "src/components/ImagePicker";
import { uploadFile as uploadFileToCache } from "src/plugins/Cloudinary";

const Certificate = () => {
  const navigate = useNavigate();

  const { updateProfile, profile } = useSessionState();
  const { setErrorMessage } = useErrorState();

  const selectedImage = useRef(null);

  const [isEnrolling, setIsEnrolling] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadingProcess, setUploadingProcess] = useState(false);
  const [usingPaymentMode, setUsingPaymentMode] = useState("manual");

  const handleStartEnroll = () => {
    setIsEnrolling(true);
  };

  const handleFileSelect = (filename, file) => {
    selectedImage.current = file;
  };

  const handleProceedEnroll = async () => {
    // check if user already filled in the profile and got avatar image
    if (!profile.avatar) {
      setErrorMessage("You must set your profile image before you can proceed with the certificate.", () =>
        navigate("/profile/settings")
      );
      return;
    }

    if (profile.farmerProfile?.experience?.length < 1 || !profile.farmerProfile.totalYearsOfFarming) {
      setErrorMessage(
        "You must update your Portfolio and Experience before you can proceed with the certificate.",
        () => navigate("/profile/settings")
      );
      return;
    }

    if (!Array.isArray(profile.farmLandProfile) || profile.farmLandProfile?.length < 1) {
      setErrorMessage("You must update your Farms before you can proceed with the certificate.", () =>
        navigate("/profile/settings")
      );
      return;
    }

    if (usingPaymentMode === "manual") {
      setIsProcessing(true);
      return;
    }

    setUploadingProcess(true);

    try {
      const response = await fetch("https://admin.worldfoodchain.io/starpay-api.php", {
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          action: "create_order",
          user_id: profile.userID,
        }), // body data type must match "Content-Type" header
      });

      const data = await response.json();
      if (data.success) {
        window.location.href = data.paymentURL;
      } else {
        setUploadingProcess(false);
        setErrorMessage("Unable to process the payment at the moment. Please try again later.");
      }

      console.log(data);
    } catch (e) {
      setUploadingProcess(false);
    }

    //
  };

  const handleSubmitRequest = () => {
    if (selectedImage.current && selectedImage.current.type.match("^image/")) {
      setUploadingProcess(true);

      uploadFileToCache(selectedImage.current, `payment-${profile.userID}`, "image")
        .then((url) => {
          updateProfile(profile.userID, { paymentReceipt: url });
        })
        .catch((error) => {
          setErrorMessage("Unable to get your payment receipt, please try again!");
        })
        .finally(() => {
          setUploadingProcess(false);
        });
    } else {
      setErrorMessage("Please select an image (jpg/png)");
    }
  };

  return (
    <>
      <section className="min-item-area page-paddings">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center">
                <h2 data-watermark="My Certificate">
                  {isEnrolling ? "Enroll for FarmLink Certification" : "My Certificate"}
                </h2>
                <div className="em_bar">
                  <div className="em_bar_bg"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="user-panel-main">
                {profile.paymentReceipt ? (profile.certificateURL ? (
                  <div className="user-store-area">
                    <Box textAlign="center" mt={5}>
                      <img src={profile.certificateURL} style={{maxWidth: "100%"}} />
                    </Box>
                    <Box textAlign="center" mt={3}>
                      <a href={profile.certificateURL} download target="_blank">Download Certificate</a>
                    </Box>
                  </div>
                ) : (
                  <div className="user-store-area">
                    <Box textAlign="center" mt={10}>
                      <h3 className="user-title">
                        Thanks! We have received your enrollment request.
                      </h3>
                      <p className="theme-description">
                        Your FoodStarter Certification request is under process. We will notify once your certificate is ready.
                      </p>
                    </Box>
                  </div>
                )) : (
                  <div className="user-store-area">
                    <div className="user-no-record-found">
                      {!isEnrolling && true && (
                        <>
                          <div className="user-no-record-icon">
                            <i className="fas fa-store-alt"></i>
                          </div>
                          <h3 className="user-title">You Have Not Enrolled to FoodStarter Certified Farmers.</h3>
                          <p className="theme-description">
                            Please continue below to be part of our Farmer Certification program, and receive your
                            FarmLink NFT
                          </p>
                          <div className="user-btn">
                            <Button variant="contained" size="large" className="theme-btn" onClick={handleStartEnroll}>
                              Register with FarmLink
                            </Button>
                          </div>
                        </>
                      )}
                      {isEnrolling && !isProcessing && usingPaymentMode !== "starpay" && (
                        <Box textAlign="center">
                          <Typography>There is one time enrollment fee of PHP 200.</Typography>
                          <Typography>
                            Please use the following QR code to pay using your gCash or PayMaya wallet.
                          </Typography>
                          <Box p={3}>
                            <img src="/assets/images/cindy-wallet.jpeg" />
                          </Box>
                          <Box mb={3}>
                            <Typography variant="caption">Pay By</Typography>
                            <Box>
                              <Grid container direction="column" gap={3} alignItems="center">
                                <Grid item>
                                  <img src="/assets/images/gcash.png" alt="gcash" />
                                </Grid>
                                <Grid item>
                                  <img src="/assets/images/maya.png" alt="maya" />
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                          <Button variant="contained" size="large" onClick={handleProceedEnroll}>
                            Attach Payment Screenshot
                          </Button>
                        </Box>
                      )}
                      {isEnrolling && !isProcessing && usingPaymentMode === "starpay" && (
                        <Box textAlign="center">
                          <Typography>There is one time enrollment fee of PHP 200.</Typography>
                          <Typography mb={10}>
                            Please click below to continue paying securly through StarPay.
                          </Typography>
                          <Box mt={2}>
                            <Button
                              disabled={uploadingProcess}
                              variant="contained"
                              size="large"
                              onClick={handleProceedEnroll}
                            >
                              Continue Payment with StarPay
                            </Button>
                          </Box>
                          {uploadingProcess && <CircularProgress />}
                          <Box my={3}>
                            <Typography variant="caption">Powered By</Typography>
                            <Box mt={2}>
                              <img
                                src="https://sp-payment-gateway-assets.s3-ap-southeast-1.amazonaws.com/sp-logo.png"
                                style={{ height: "30px" }}
                                alt="StarPay"
                              />
                            </Box>
                          </Box>
                          <Button variant="outlined" onClick={() => setUsingPaymentMode("manual")}>Not using StarPay?</Button>
                        </Box>
                      )}
                      {isProcessing && (
                        <Box textAlign="center">
                          <Typography mb={3}>Please attach you transaction confirmation screenshot</Typography>
                          <ImagePicker
                            withDragDrop
                            singleColumn
                            name="payment_receipt"
                            onFileSelect={handleFileSelect}
                          />
                          <Box mt={5} mb={3}>
                            <Button size="large" variant="contained" onClick={handleSubmitRequest}>
                              Submit Application
                            </Button>
                          </Box>
                          {uploadingProcess && <CircularProgress />}
                        </Box>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Certificate;
