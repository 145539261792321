import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSessionState } from "src/state";
const UserNavigation = () => {
	// check to show only on user's profile page

	const {profile} = useSessionState()

	const location = useLocation();
	const showUserNav = location.pathname.search("profile/");

	return showUserNav >= 0 ? (
		<div className="user-header-bottom-main clearfix">
			<nav className="navbar navbar-expand-lg navbar-light">
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarForUser"
					aria-controls="navbarForUser"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<i className="fas fa-bars"></i>
				</button>
				<div className="collapse navbar-collapse" id="navbarForUser">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<RouterLink to="/profile/settings" className="nav-link">
								<i className="fas fa-store-alt"></i> My Profile
							</RouterLink>
						</li>
						<li className="nav-item">
							<RouterLink to="/profile/tokens" className="nav-link">
								<i className="fas fa-wallet"></i> My NFTs
							</RouterLink>
						</li>
						<li className="nav-item">
							<RouterLink to="/profile/earnings" className="nav-link">
								<i className="fas fa-money-bill-alt"></i> My Earnings
							</RouterLink>
						</li>
						<li className="nav-item single-menu">
							<RouterLink to="/profile/transactions" className="nav-link">
								<i className="fas fa-shopping-cart"></i> My Transactions
							</RouterLink>
						</li>
						<li className="nav-item single-menu">
							<RouterLink to="/profile/projects" className="nav-link">
								<i className="fas fa-list"></i> My Projects
							</RouterLink>
						</li>
						{profile?.accountType === "farmer" && 
						<li className="nav-item single-menu">
							<RouterLink to="/profile/certificate" className="nav-link">
								<i className="fas fa-certificate"></i> My Certificate
							</RouterLink>
						</li>}
						{/* <li className="nav-item">
							<a className="nav-link" href="history.html">
								<i className="fas fa-history"></i> History
							</a>
						</li> */}
					</ul>
				</div>
			</nav>
		</div>
	) : null;
};

export default UserNavigation;
