import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../components/Logo";
import { LockedModeContext } from "src/providers/LockedModeProvider";
import { useContext } from "react";
import { useGlobalState, useSessionState } from "src/state";
import { Button } from "@material-ui/core";
import UserHeaderMenu from "src/components/frontend/partials/UserHeaderMenu";
import UserNavigation from "src/components/frontend/partials/userpanel/UserNavigation";

const MainNavbar = props => {
	const lockModeInfo = useContext(LockedModeContext);
	const { setRedirectToAfterConnect, setValue } = useGlobalState();
	const { loggedInUser, logout } = useSessionState();

	const location = useLocation();

	const navigate = useNavigate();

	const connect = () => {
		setRedirectToAfterConnect(location.pathname);

		//if (blockchainInfo) blockchainInfo.connectToBlockchain();
		navigate("/connect-wallet");
	};

	const search = value => {
		navigate("/search/" + value);
	};

	return (
		<>
			{/* header main */}
			<header className="header-main">
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-9">
							<div className="header-logo">
								{!lockModeInfo.lockedMode ? (
									<RouterLink className="logo-container" to="/">
										<Logo mode="small" />
									</RouterLink>
								) : (
									<Logo mode="small" />
								)}
							</div>
						</div>
						<div className="col-md-9 col-3">
							{!lockModeInfo.lockedMode && (
								<div className="header-menu-box clearfix">
									<div className="header-navbar-menu clearfix">
										<nav className="navbar navbar-expand-lg navbar-light">
											<button
												className="navbar-toggler"
												type="button"
												data-toggle="collapse"
												data-target="#navbarSupportedContent"
												aria-controls="navbarSupportedContent"
												aria-expanded="false"
												aria-label="Toggle navigation"
											>
												<i className="fas fa-bars"></i>
											</button>
											<div className="collapse navbar-collapse" id="navbarSupportedContent">
												<ul className="navbar-nav mr-auto">
													<li className="nav-item menu-menu-parent">
														<RouterLink
															to="/projects"
															className="nav-link"
															data-toggle="collapse"
															data-target=".navbar-collapse.show"
														>
															Browse
														</RouterLink>
													</li>
													<li className="nav-item menu-menu-parent">
														<RouterLink className="nav-link" to="/coming-soon">
															Stake
														</RouterLink>
													</li>
													<li className="nav-item menu-menu-parent">
														<RouterLink className="nav-link" to="/coming-soon">
															Auction
														</RouterLink>
													</li>
													<li className="nav-item">
														<RouterLink
															to="/profile/projects/add"
															className="nav-link"
															data-toggle="collapse"
															data-target=".navbar-collapse.show"
														>
															List Your Project
														</RouterLink>
													</li>
													{/* <li className="nav-item">
													<a className="nav-link" href="vote.html">
														Vote/DAO
													</a>
												</li> */}
													{/* <li className="nav-item single-menu">
													<a className="nav-link" href="#">
														Categories <i className="fas fa-sort-down"></i>
													</a>
													<div className="dropdown-box">
														<ul className="dropdown-box-inner">
															<li>
																<a href="art.html">Art</a>
															</li>
															<li>
																<a href="collectibles.html">Collectibles</a>
															</li>
															<li>
																<a href="game-items.html">Game Items</a>
															</li>
															<li>
																<a href="music.html">Music</a>
															</li>
															<li>
																<a href="domains.html">Domains</a>
															</li>
															<li>
																<a href="templates.html">Templates</a>
															</li>
															<li>
																<a href="videos.html">Videos</a>
															</li>
														</ul>
													</div>
												</li> */}
													{loggedInUser ? (
														<>
															<li className="nav-item d-block d-md-none">
																<RouterLink
																	className="nav-link"
																	to="/profile/settings"
																	data-toggle="collapse"
																	data-target=".navbar-collapse.show"
																>
																	My Account
																</RouterLink>
															</li>
															<li className="nav-item d-block d-md-none">
																<a
																	className="nav-link"
																	onClick={logout}
																	data-toggle="collapse"
																	data-target=".navbar-collapse.show"
																>
																	Logout
																</a>
															</li>
														</>
													) : (
														<>
															<li className="nav-item d-block d-md-none">
																<RouterLink
																	className="nav-link"
																	to="/signup"
																	data-toggle="collapse"
																	data-target=".navbar-collapse.show"
																>
																	Create Account
																</RouterLink>
															</li>
															<li className="nav-item d-block d-md-none">
																<RouterLink
																	className="nav-link"
																	to="/login"
																	data-toggle="collapse"
																	data-target=".navbar-collapse.show"
																>
																	Login
																</RouterLink>
															</li>
														</>
													)}
												</ul>
											</div>
										</nav>
									</div>
									<div className="header-right-btn">
										<ul>
											<li>
												<Button
													className="theme-btn search-btn"
													onClick={() => setValue("showSearchBlock", true)}
												>
													<i className="fas fa-search"></i>
												</Button>
											</li>
											{!loggedInUser && (
												<>
													<li>
														<RouterLink className="theme-btn" to="/signup">
															Create Account
														</RouterLink>
													</li>
													<li>
														<RouterLink className="login-text" to="/login">
															Login
														</RouterLink>
													</li>
												</>
											)}
										</ul>
									</div>
									{loggedInUser && <UserHeaderMenu loggedInUser={loggedInUser} onLogout={logout} />}
								</div>
							)}
						</div>
					</div>
				</div>
				{loggedInUser && !loggedInUser.profileVerified && loggedInUser.profileSubmitted && (
					<div className="alert alert-danger text-center" style={{ margin: "0" }}>
						Your account is under verification. You will receive a notification once your account gets verified.
					</div>
				)}
				<div className="user-navigation">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<UserNavigation />
							</div>
						</div>
					</div>
				</div>
			</header>
			{/* header main */}
		</>
	);
};

export default MainNavbar;
