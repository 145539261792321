import { Link as RouterLink } from "react-router-dom";
export const BlockEarning = () => (
	<>
		{/* <!-- earn area --> */}
		<section className="earn-area page-paddings">
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="earn-main-box">
							<div className="earn-info">
								<h6>Token</h6>
								<h2>Earn Sale of Produce Returns Whenever You Contribute</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
								<p className="theme-description">
									With our projects backed by real business, real assets and real people, they are working hard to return your contribution.
								</p>
								<ul>
									<li>Contribute to food security.</li>
									<li>Select a project you want to support.</li>
									<li>If you can assist the project owner as an affliate, please contact FoodStarter.</li>
								</ul>
								<div className="slider-btn">
									<RouterLink to="/contact" className="theme-btn">
										Contact Us
									</RouterLink>
								</div>
							</div>
							<div className="earn-images">
								<img src="/assets/images/token.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!-- earn area --> */}
	</>
);