const About = () => {
	return (
		<section className="faq-area page-paddings">
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2 data-watermark="FAQ">About FoodStarter</h2>
							<div className="em_bar">
								<div className="em_bar_bg"></div>
							</div>
							<p className="subtitle">
								Food Starter is a Global Investment, farm incubator & fundraising company headquartered
								in Singapore, with subsidiaries across South East Asia.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="faq-box-main">
							<div className="faq-box">
								<p className="theme-description">
									The Food Starter Charter defines Food Starter’s roles as a Steward, shaping food
									aficionados and food enthusiasts' culture to do well, do right, and do good for the
									community around the world. As a fundraising Institution and provider of catalytic
									resources and capital, we seeks to enable solutions using blockchain, NFT and the
									Metaverse technologies to key global food security and sustainability challenges.
								</p>
								<p className="theme-description">
									With ESG and sustainability at the core of all our engagements, we actively seeks
									sustainable food security solutions to address present and future challenges, as it
									captures investible opportunities to bring about a sustainable future for all.
								</p>
								<p className="theme-description">
									The Food Starter Sustainable Foods Platform - MetaFarm, IP wholly-owned by Food
									Starter, aims to delight consumers across Asia with tasty, fresh, traceable and
									sustainable food by accelerating the commercialisation of sustainable foods in the
									region. It provides solutions and support to aspiring food-tech companies at every
									stage of their growth cycle, alleviating friction-to-funds as they advance from
									product and produce development including farming to pilot launch and commercial
									scale-up go-to-market enablement.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
