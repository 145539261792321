import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	MenuItem,
	Select,
	Typography,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import SearchBar from "src/components/SearchBar";
import { useProjectsState } from "src/state";

const _Toolbar = ({ onPressAddButton, onSearch, onToggleArchive, ...props }) => {
	const { projects, promised: isProjectsLoading } = useProjectsState();

	return (
		<Box {...props}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Typography variant="h2" color="textPrimary" gutterBottom>
					{process.env.REACT_APP_COLLECTION_TITLE}s
				</Typography>
				<Button color="primary" variant="contained" onClick={onPressAddButton}>
					Add {process.env.REACT_APP_COLLECTION_TITLE}
				</Button>
			</Box>
			<Box sx={{ mt: 3 }}>
				<Card>
					<CardContent style={{ paddingBottom: 16 }}>
						<Grid container spacing={2} sx={{ alignItems: "center" }}>
							<Grid item md={4} xs={6}>
								<SearchBar
									placeholder={`Search ${process.env.REACT_APP_COLLECTION_TITLE}s`}
									onSearch={onSearch}
								/>
							</Grid>
							<Grid item md={4} xs={6}>
								{!isProjectsLoading && (
									<Select
										fullWidth
										name="project_id"
										defaultValue="all"
										label="Project"
										variant="outlined"
										onChange={event => onSearch(event.target.value)}
									>
										<MenuItem value="all">All Projects</MenuItem>
										{projects.map((item, index) => (
											<MenuItem key={index} value={item.id}>
												{item.project_name}
											</MenuItem>
										))}
									</Select>
								)}
							</Grid>
							<Grid item md={4} xs={6}>
								<FormControlLabel
									control={<Checkbox onChange={e => onToggleArchive(e.target.checked)} />}
									label="Include Archived"
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
};

export default _Toolbar;
