import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CallMadeIcon from "@material-ui/icons/CallMade";
import moment from "moment";
import { useState } from "react";
import { useTokenState, useErrorState, useListingState } from "src/state";
import { seekTokenIDBySku, burn } from "src/plugins/Ethereum";
import { tokenLinkURL } from "src/helpers/blockchain.helper";
import { LinkableExternal } from "src/components/Linkable";
import ListButton from "src/components/collection/ListButton";
import TransferButton from "src/components/collection/TransferButton";
import { listing_link } from "src/helpers/links.helper";

const TokenActions = ({
	collectionID,
	contractType,
	chainID,
	sku,
	id: dbID,
	createdAt,
	tokenID,
	owner,
	minter,
	connectedWithAccount,
	burnt,
	allowListing = true,
	...props
}) => {
	const [seeking, setSeeking] = useState(false);

	const { updateToken, deleteFailedToken, setAsBurnt } = useTokenState(collectionID);

	const { setErrorMessage } = useErrorState();

	const { listings, getListingIDForToken } = useListingState();

	if ((moment(createdAt).isAfter(moment().subtract(1, "minutes")) && !tokenID) || seeking)
		return <CircularProgress />;

	const seekTokenID = () => {
		setSeeking(true);

		seekTokenIDBySku(contractType, collectionID, sku)
			.then(tokenID => {
				if (tokenID && parseInt(tokenID)) {
					updateToken(collectionID, dbID, {
						tokenID,
					});
				} else setErrorMessage("Unable to find Token ID for this item SKU");
			})
			.catch(error => setErrorMessage("Unable to find Token ID for this item SKU"))
			.finally(() => setSeeking(false));
	};

	const handleDeleteEntry = async () => {
		// check if the token minted for this sku
		setSeeking(true);

		const confirmDelete = () => {
			if (window.confirm("Are you sure? This will delete this record")) {
				deleteFailedToken(collectionID, dbID);
			}
		};

		seekTokenIDBySku(contractType, collectionID, sku)
			.then(tokenID => {
				if (tokenID && parseInt(tokenID)) {
					updateToken(collectionID, dbID, {
						tokenID,
					});
					setErrorMessage(
						"A token for this SKU already minted. If need to remove should use Burn function instead"
					);
				} else {
					confirmDelete();
				}
			})
			.catch(error => confirmDelete())
			.finally(() => setSeeking(false));
	};

	const burnToken = tokenID => {
		if (window.confirm("Are you sure? This will burn the token on blockchain")) {
			setSeeking(true);

			burn(contractType, collectionID, tokenID)
				.then(() => {
					setAsBurnt(collectionID, dbID, {
						burnt: true,
					});
				})
				.catch(error => setErrorMessage("Unable to burn this token at the moment. Please check back again"))
				.finally(() => setSeeking(false));
		}
	};

	const tokenListingID = burnt ? null : getListingIDForToken(collectionID, tokenID, true);

	return tokenID ? (
		<>
			<Typography variant="caption">
				Token ID: {tokenID}
				<LinkableExternal isLinked={true} to={tokenLinkURL(chainID, collectionID, tokenID)}>
					<CallMadeIcon fontSize="inherit" />
				</LinkableExternal>
			</Typography>
			{!burnt &&
				!tokenListingID &&
				connectedWithAccount === owner &&
				allowListing && ( // || connectedWithAccount === minter
					<ListButton tokenInfo={{ chainID, collectionID, tokenID, owner }} />
				)}
			{!burnt && tokenListingID && (
				<LinkableExternal
					isLinked={true}
					to={listing_link(collectionID, tokenID)}
					style={{ marginLeft: "10px" }}
				>
					View Listing
				</LinkableExternal>
			)}
			{!burnt && connectedWithAccount === minter && connectedWithAccount === owner && allowListing && (
				<TransferButton tokenInfo={{ chainID, collectionID, tokenID, dbID }} />
			)}
			{connectedWithAccount === owner && !burnt && !tokenListingID && (
				<Button
					sx={{ ml: 2 }}
					variant="contained"
					color="error"
					onClick={() => burnToken(tokenID)}
					size="small"
				>
					Burn
				</Button>
			)}
		</>
	) : (
		<>
			<Button color="primary" onClick={seekTokenID}>
				Seek Token ID
			</Button>
			<Button color="error" onClick={handleDeleteEntry}>
				Mint Failed
			</Button>
		</>
	);
};

export default TokenActions;
