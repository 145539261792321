import React, { useState, useContext, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
	Box,
	Button,
	TextField,
	Typography,
	Select,
	MenuItem,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	InputAdornment,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import MultilineField from "src/components/MultilineField";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { networkName, shortAccount } from "src/helpers/blockchain.helper";
import { useProjectsState, useErrorState, useSettingState } from "src/state";
import { round } from "src/helpers/number.helper";
import { get as getValue } from "lodash";

const CollectionForm = ({ defaultValues, onCreate, isForFrontend, ...props }) => {
	const blockchainInfo = useContext(BlockchainContext);
	const { projects, getProject } = useProjectsState();
	const { getMarketplaceFee } = useSettingState();
	const { setErrorMessage } = useErrorState();
	const [step, setStep] = useState(1);
	const [collectionType, setCollectionType] = useState("payback");

	const payouts = useRef([]);

	const setPayouts = newValues => {
		payouts.current = newValues;
	};

	const marketplaceFee = blockchainInfo ? getMarketplaceFee(blockchainInfo.networkId) : 2.5;

	useEffect(() => {
		if (defaultValues) {
			setStep(2);
			payouts.current = defaultValues.payoutSchedule
				? defaultValues.payoutSchedule.map(item => ({
						title: moment.unix(item.timestamp).format("yyyy-MM-DD"),
						value: item.percentage,
						description: item.description ?? "",
				  }))
				: [];

			if(!payouts.current.length) {
				setCollectionType("utility");
			}
		}
	}, [defaultValues]);

	const handleCollectionTypeChange = e => {
		const selected = e.target.value;
		setCollectionType(selected);
	};

	return (
		<Formik
			initialValues={{
				contract_type: "721",
				project_id: getValue(defaultValues, "projectID", ""),
				token_name: getValue(defaultValues, "name", ""),
				token_symbol: "",
				marketplace_address: "",
				campaign_commencement_date:
					defaultValues && defaultValues.campaignCommencementDate
						? moment.unix(getValue(defaultValues, "campaignCommencementDate", "")).format("yyyy-MM-DD")
						: "",
				capital_request: getValue(defaultValues, "metadata.capital_request", ""),
				offering_size: getValue(defaultValues, "offeringSize", "0"),
				sales_return_on_capital: getValue(defaultValues, "metadata.sales_return_on_capital", ""),
				sales_return_period: getValue(defaultValues, "metadata.sales_return_period", ""),
				royalty_percentage: 0.5,
			}}
			validationSchema={Yup.object().shape({
				contract_type: Yup.string()
					.max(255)
					.required("Contract type is required")
					.equals(["721"], "Only ERC721 is implemented at the moment"),
				project_id:
					!isForFrontend && process.env.REACT_APP_HAVE_PROJECTS
						? Yup.string().required("Please select a project")
						: Yup.string().nullable(true),
				token_name: Yup.string().max(100).required("Name is required"),
				token_symbol: Yup.string().max(50).required("Symbol is required"),
				royalty_percentage: Yup.number().required("Can put 0 if dont want to give any royalty"),
				capital_request:
					collectionType === "payback"
						? Yup.number().min(1).required("Please specify Capital Requst")
						: Yup.number(),
				offering_size:
					collectionType === "payback"
						? Yup.number().min(1).required("Please specify Offering Size").max(Yup.ref("capital_request"))
						: Yup.number(),
			})}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);

				let totalPayout = 0;

				const payoutSchedule = payouts.current.map(payout => {
					totalPayout += parseFloat(payout.value);
					return [
						parseInt(moment(payout.title).format("X")),
						parseFloat(payout.value),
						payout.description ?? "",
					];
				});

				if (collectionType === "payback" && totalPayout !== 100 + parseFloat(values.sales_return_on_capital)) {
					setErrorMessage(
						`Check the payout schedule, all the percentages should total up to ${
							100 + parseFloat(values.sales_return_on_capital)
						}%`
					);
					return;
				}

				onCreate &&
					onCreate({
						contractType: values.contract_type,
						projectID: values.project_id,
						name: values.token_name,
						symbol: values.token_symbol,
						campaignCommencementDate: parseInt(
							values.campaign_commencement_date && values.campaign_commencement_date != ""
								? moment(values.campaign_commencement_date).format("X")
								: 0
						),
						offeringSize: isNaN(values.offering_size) ? 0 : values.offering_size,
						payoutSchedule: payoutSchedule,
						metadata: JSON.stringify({
							capital_request: values.capital_request,
							sales_return_on_capital: values.sales_return_on_capital,
							sales_return_period: values.sales_return_period,
						}),
						owner: blockchainInfo.account,
						chainID: blockchainInfo.networkId,
						marketplaceAddress: values.marketplace_address,
						royalty: values.royalty_percentage,
					});
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
				<form onSubmit={handleSubmit}>
					{step === 1 && (
						<Box>
							<FormControl fullWidth error={Boolean(touched.contract_type && errors.contract_type)}>
								<FormLabel sx={{ mb: 2 }} component="legend">
									Select the type of contract you would like to create
								</FormLabel>
								<Select
									fullWidth
									name="contract_type"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.contract_type}
									sx={{ mb: 2 }}
								>
									<MenuItem value="721">ERC 721</MenuItem>
									<MenuItem value="1155">ERC 1155</MenuItem>
								</Select>
								{errors.contract_type && <FormHelperText>{errors.contract_type}</FormHelperText>}
							</FormControl>
							{!isForFrontend && process.env.REACT_APP_HAVE_PROJECTS && (
								<FormControl fullWidth error={Boolean(touched.project_id && errors.project_id)}>
									<FormLabel sx={{ mb: 2 }} component="legend">
										Select the project this collection belogs to
									</FormLabel>
									<Select
										fullWidth
										name="project_id"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.project_id}
										sx={{ mb: 2 }}
									>
										{projects.map((item, index) => (
											<MenuItem key={index} value={item.id}>
												{item.project_name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							<FormControl fullWidth>
								{!errors.contract_type &&
									(isForFrontend ||
										!process.env.REACT_APP_HAVE_PROJECTS ||
										(touched.project_id && !errors.project_id)) && (
										<Button variant="contained" onClick={() => setStep(2)}>
											Next
										</Button>
									)}
							</FormControl>
						</Box>
					)}
					{step === 2 && values.contract_type === "721" && (
						<Fade in={true} mountOnEnter unmountOnExit>
							<FormControl
								fullWidth
								error={Boolean(touched.contract_type && errors.contract_type)}
								component="fieldset"
							>
								{!isForFrontend && (
									<Typography mb={2} variant="h5">
										ERC {values.contract_type} For{" "}
										<strong>"{getProject(values.project_id).project_name}"</strong>
									</Typography>
								)}
								<Select
									fullWidth
									name="collection_type"
									value={collectionType}
									sx={{ mb: 2 }}
									onChange={handleCollectionTypeChange}
								>
									<MenuItem value="payback">Payback Token</MenuItem>
									<MenuItem value="utility">Utility Token</MenuItem>
								</Select>
								<TextField
									error={Boolean(touched.token_name && errors.token_name)}
									fullWidth
									helperText={touched.token_name && errors.token_name}
									label="Name"
									name="token_name"
									onBlur={handleBlur}
									onChange={handleChange}
									type="text"
									value={values.token_name}
									variant="outlined"
									margin="normal"
								/>
								<TextField
									error={Boolean(touched.token_symbol && errors.token_symbol)}
									fullWidth
									helperText={touched.token_symbol && errors.token_symbol}
									label="Symbol"
									name="token_symbol"
									onBlur={handleBlur}
									onChange={handleChange}
									type="text"
									value={values.token_symbol}
									variant="outlined"
									margin="normal"
								/>
								{collectionType === "payback" && (
									<Grid container spacing={3}>
										<Grid item md={4}>
											<TextField
												fullWidth
												label="Contract Start Date"
												margin="normal"
												name="campaign_commencement_date"
												type="date"
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.campaign_commencement_date}
											/>
										</Grid>
										<Grid item md={4}>
											<TextField
												error={Boolean(touched.capital_request && errors.capital_request)}
												fullWidth
												helperText={touched.capital_request && errors.capital_request}
												label="Project Capital Request"
												margin="normal"
												name="capital_request"
												type="number"
												variant="outlined"
												InputProps={{
													endAdornment: <InputAdornment position="end">USD</InputAdornment>,
												}}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.capital_request}
											/>
											{values.capital_request && (
												<Typography variant="caption">
													After Fee:{" "}
													{round(
														parseFloat(values.capital_request) *
															(1 - parseFloat(marketplaceFee) / 100),
														2
													)}{" "}
													USD
												</Typography>
											)}
										</Grid>
										<Grid item md={4}>
											<TextField
												error={Boolean(touched.offering_size && errors.offering_size)}
												fullWidth
												helperText={touched.offering_size && errors.offering_size}
												label="Offering Size"
												margin="normal"
												name="offering_size"
												type="number"
												variant="outlined"
												InputProps={{
													endAdornment: <InputAdornment position="end">USD</InputAdornment>,
												}}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.offering_size}
											/>
											{values.offering_size && (
												<Typography variant="caption">
													After Fee:{" "}
													{round(
														parseFloat(values.offering_size) *
															(1 - parseFloat(marketplaceFee) / 100),
														2
													)}{" "}
													USD
												</Typography>
											)}
										</Grid>
									</Grid>
								)}
								{collectionType === "payback" && (
									<Grid container spacing={3}>
										<Grid item md={6}>
											<TextField
												fullWidth
												type="number"
												label="Sales Return on Capital"
												margin="normal"
												name="sales_return_on_capital"
												variant="outlined"
												InputProps={{
													endAdornment: <InputAdornment position="end">%</InputAdornment>,
												}}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.sales_return_on_capital}
											/>
										</Grid>
										<Grid item md={6}>
											<TextField
												fullWidth
												label="Over a period of"
												margin="normal"
												name="sales_return_period"
												type="number"
												variant="outlined"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">months</InputAdornment>
													),
												}}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.sales_return_period}
											/>
										</Grid>
									</Grid>
								)}
								{collectionType === "payback" && (
									<>
										<Typography mt={3} variant="h3">
											Payouts to Contributors
										</Typography>
										<Box mb={3} sx={{ color: "#989898" }}>
											<MultilineField
												items={payouts.current}
												onChange={setPayouts}
												fields={[
													{ name: "title", title: "Payout Date", type: "date" },
													{ name: "value", title: "Payout Amount", type: "percentage" },
													{ name: "description", title: "Description" },
												]}
												addButtonText="Add Payout"
												addButtonAlignment="left"
											/>
										</Box>
									</>
								)}
								<TextField
									error={Boolean(touched.royalty_percentage && errors.royalty_percentage)}
									fullWidth
									helperText={
										isForFrontend
											? "Provide a Royalty Percentage you want to receive whenever these NFTs are sold on our marketplace"
											: "Provide a Royalty Percentage you want original minter shall receive whenever their NFT is sold on our marketplace"
									}
									label="Royalty Percentage"
									name="royalty_percentage"
									onBlur={handleBlur}
									onChange={handleChange}
									type="number"
									value={values.royalty_percentage}
									variant="outlined"
									margin="normal"
								/>
								{!isForFrontend && (
									<TextField
										fullWidth
										helperText="This will make the token tradeable on this marketplace without having to pay extra gas fee"
										label="Marketplace Address (optional)"
										name="marketplace_address"
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										value={values.marketplace_address}
										variant="outlined"
										margin="normal"
									/>
								)}
								<Box sx={{ pt: 2 }}>
									<Button
										color="primary"
										disabled={
											isSubmitting ||
											(!touched.token_name && !defaultValues) ||
											(errors.token_name && errors.token_name.length > 0) ||
											!touched.token_symbol ||
											(errors.token_symbol && errors.token_symbol.length > 0)
										}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										Create
									</Button>
								</Box>
							</FormControl>
						</Fade>
					)}
					{step === 2 && values.contract_type === "1155" && (
						<FormControl
							fullWidth
							error={Boolean(touched.contract_type && errors.contract_type)}
							component="fieldset"
						>
							<h1>1155 </h1>
						</FormControl>
					)}
					<Typography variant="caption" component="p" sx={{ mt: 2 }} color="error" align="center">
						You are connected to {networkName(blockchainInfo ? blockchainInfo.networkId : 0)} with account{" "}
						{shortAccount(blockchainInfo ? blockchainInfo.account : null)}
					</Typography>
				</form>
			)}
		</Formik>
	);
};

export default CollectionForm;
