import { Card, CardActionArea, CardContent, CardMedia, Button, Box, Grid, Link, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Loading from "src/components/Loading";
import Tabs from "src/components/Tabs";
import { useCategoryState, useCollectionState, useProjectsState } from "src/state";
import Collections from "src/components/frontend/Collections";
import moment from "moment";
import ShareButton from "src/components/ShareButton";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import { cachedVersion } from "src/helpers/file.helper";

const ProjectDetailsContainer = styled("div")(({ theme }) => ({
	padding: "50px",
	background: "#eee",
	marginLeft: -16,
	marginRight: -16,
}));

const useStyles = makeStyles(theme => ({
	projectUspContainer: {
		height: "100%",
		display: "flex",
		alignItems: "center",
	},
	projectUsp: {
		minHeight: "85px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%"
	},
}));

const ProjectHighlight = ({ name, value }) => (
	<Grid item xs={12} sm={6} md={3} mb={{ xs: 3, md: 0 }} sx={{ textAlign: "center" }}>
		<Typography variant="h3" mb={{ xs: 1, md: 3 }}>
			{name}
		</Typography>
		{value ? (
			<Typography>{value}</Typography>
		) : (
			<Typography sx={{ fontStyle: "italic" }} color="textSecondary" variant="caption">
				Will be updated shortly
			</Typography>
		)}
	</Grid>
);

const ProjectAttribute = ({ name, value }) => (
	<Box mb={2}>
		<Typography>{name}</Typography>
		<Typography variant="h3">{value}</Typography>
	</Box>
);

const GalleryItem = ({ imageSrc, noShadow }) => (
	<Box className="item-group" sx={noShadow ? { boxShadow: "none !important" } : {}}>
		<a href={imageSrc} data-lightbox="example-1">
			<div className="item-group-content">
				<div className="item-group-avtar">
					<img src={imageSrc} data-action="zoom" />
				</div>
				<div className="selling-item-info">
					<p className="theme-description">Click to View</p>
				</div>
			</div>
		</a>
	</Box>
);

const Project = () => {
	const { pid: projectID } = useParams();
	const { promised: isCategoriesLoading, getCategory } = useCategoryState();
	const { promised: isCollectionsLoading, collections } = useCollectionState();
	const { promised: isProjectsLoading, getProject } = useProjectsState();

	const classes = useStyles();

	if (isCollectionsLoading || isProjectsLoading) return <Loading />;

	const project = getProject(projectID);

	//const projectCategory = project?.category && !isCategoriesLoading ? getCategory(project.category) : null;

	const open = url => {
		window.open(url, "_blank");
	};

	return (
		<Box className="discover-area page-paddings">
			<Box className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2 data-watermark="Project Info">{project.project_name}</h2>
							<div className="em_bar">
								<div className="em_bar_bg"></div>
							</div>
							<p className="subtitle">
								{project.owner_name}, {project.project_country}
								<br />
								Started with a Capital Request of <strong>{project.capital_request}</strong>
							</p>
						</div>
					</div>
				</div>
				<div className="discover-main-box">
					<Grid container sx={{ pb: 2, alignItems: "center" }}>
						<Grid item xs={6}>
							<Link component={RouterLink} to={"/projects"}>
								<ChevronLeftIcon sx={{ verticalAlign: "text-top" }} />
								Back to Projects
							</Link>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: "right" }}>
							<ShareButton title="Share" />
						</Grid>
					</Grid>
					<Card sx={{ background: "none" }}>
						<CardMedia
							component="img"
							height="350"
							image={cachedVersion(project.banner_image ?? project.project_image)}
							alt="green iguana"
						/>
						<CardContent sx={{ background: "#FFF" }}>
							{/* <Box mb={4}>
								<Typography variant="h2">{project.project_name}</Typography>
								<p
									style={{
										fontSize: 12,
										marginTop: 5,
										fontStyle: "italic",
									}}
								>
									Project created in{" "}
									{moment(project.created_at).format("MMM YYYY")}
								</p>
								<Typography variant="bod2">
									{project.owner_name}, {project.project_country}
								</Typography>
								<Typography variant="h5" mt={2}>
									{project.capital_request} Capital Request
								</Typography>
							</Box> */}
							<Box>
								<Tabs tabPanelSx={{ p: 0 }}>
									<Box label="Campaigns">
										<Box py={4}>
											<Collections
												collections={collections.filter(
													item => item.projectID === projectID && !item.archived
												)}
											/>
										</Box>
									</Box>
									<Box label="Project Details">
										{project.project_summary && (
											<ProjectDetailsContainer>
												<Typography variant="h2" mb={3}>
													Project Summary
												</Typography>
												<Typography sx={{ whiteSpace: "pre-line" }}>
													{project.project_summary}
												</Typography>
												<Box>
													<Grid container spacing={2} mt={2}>
														<Grid item xs={6}>
															<ProjectAttribute
																name="Owner Name"
																value={project.owner_name}
															/>
															{/* <ProjectAttribute
																name="Capital Request"
																value={project.capital_request}
															/>
															<ProjectAttribute
																name="Sales Return"
																value={project.sales_return}
															/> */}
															<ProjectAttribute
																name="Country"
																value={project.project_country}
															/>
															{project.registration_cert_updated && (
																<ProjectAttribute
																	name="Reg. Cert."
																	value={project.registration_cert_updated}
																/>
															)}
															{project.tax_license_no && (
																<ProjectAttribute
																	name="Tax License No."
																	value={project.tax_license_no}
																/>
															)}
														</Grid>
														<Grid item xs={6}>
															<ProjectAttribute
																name="Project Address"
																value={project.project_address}
															/>
															{project.licenses && (
																<ProjectAttribute
																	name="Licenses"
																	value={project.licenses}
																/>
															)}
															{project.distribution_partners && (
																<ProjectAttribute
																	name="Distribution Partners"
																	value={project.distribution_partners}
																/>
															)}
															{project.prove_assets_registration && (
																<ProjectAttribute
																	name="Prove of Asset Registration"
																	value={project.prove_assets_registration}
																/>
															)}
														</Grid>
													</Grid>
												</Box>
											</ProjectDetailsContainer>
										)}
										{(project.project_gallery_1 ||
											project.project_gallery_2 ||
											project.project_gallery_3) && (
											<Box className="top-selling-store" py={3} px={1}>
												<Grid container>
													{project.project_gallery_1 && (
														<Grid item xs={12} md={4} p={1}>
															<GalleryItem
																imageSrc={cachedVersion(project.project_gallery_1)}
																noShadow={true}
															/>
														</Grid>
													)}
													{project.project_gallery_2 && (
														<Grid item xs={12} md={4} p={1}>
															<GalleryItem
																imageSrc={cachedVersion(project.project_gallery_2)}
																noShadow={true}
															/>
														</Grid>
													)}
													{project.project_gallery_3 && (
														<Grid item xs={12} md={4} p={1}>
															<GalleryItem
																imageSrc={cachedVersion(project.project_gallery_3)}
																noShadow={true}
															/>
														</Grid>
													)}
												</Grid>
											</Box>
										)}
										{project.project_timeline && (
											<Box sx={{ textAlign: "center" }} p={2} mb={5}>
												<Typography variant="h1" mb={2}>
													Project Timeline
												</Typography>
												{Array.isArray(project.project_timeline) ? (
													project.project_timeline.map((item, index) => (
														<Box key={index}>
															<Typography variant="h3">{item.title}</Typography>
															<Typography mb={3}>{item.value}</Typography>
														</Box>
													))
												) : (
													<Typography variant="h3" mb={3}>
														{project.project_timeline}
													</Typography>
												)}
												{project.timeline_image && (
													<img src={cachedVersion(project.timeline_image)} />
												)}
											</Box>
										)}
										{project.use_funds && (
											<Box sx={{ textAlign: "center" }} p={2} mb={5}>
												<Typography variant="h1" mb={2}>
													Use of Funds
												</Typography>
												<img src={cachedVersion(project.use_funds)} />
											</Box>
										)}
										{project.project_map && (
											<Box sx={{ textAlign: "center" }} p={2} mb={5}>
												<Typography variant="h1" mb={2}>
													Project Map
												</Typography>
												<img src={cachedVersion(project.project_map)} />
												{/* {project.project_map_desc && (
													<Typography variant="h3">{project.project_map_desc}</Typography>
												)} */}
											</Box>
										)}
									</Box>
									<Box label="Project USP">
										<Box p={4}>
											<Grid container spacing={2}>
												{[1, 2, 3, 4, 5, 6].map(key =>
													project["usp_" + key] ? (
														<Grid item xs={12} sm={6} md={3} key={"usp_" + key}>
															<Card className={classes.projectUspContainer}>
																<CardContent
																	className={classes.projectUsp}
																	sx={{ textAlign: "center" }}
																>
																	<Typography variant="h6">
																		{project["usp_" + key]}
																	</Typography>
																</CardContent>
															</Card>
														</Grid>
													) : null
												)}
											</Grid>
										</Box>
									</Box>
									<Box label="Video">
										<Box p={4}>
											<Typography variant="h3">Coming soon!</Typography>
										</Box>
									</Box>
									<Box label="Documents">
										<Box p={4}>
											<Grid container spacing={2}>
												{[1, 2, 3, 4, 5].map(key =>
													project["project_docs_" + key] ? (
														<Grid item xs={12} sm={6} md={3} key={"project_docs_" + key}>
															<Card>
																<CardActionArea
																	href={project["project_docs_" + key]}
																	target="_blank"
																>
																	<CardContent sx={{ textAlign: "center" }}>
																		<FileDownloadIcon />
																		<Typography variant="h6">View File</Typography>
																	</CardContent>
																</CardActionArea>
															</Card>
														</Grid>
													) : null
												)}
											</Grid>
										</Box>
									</Box>
									<Box label="T&C">
										<Box p={4}>
											<Typography variant="h2" mb={3}>
												Terms &amp; Conditions
											</Typography>
											<Typography sx={{ whiteSpace: "pre-line" }}>
												{project.project_tnc}
											</Typography>
										</Box>
									</Box>
								</Tabs>
							</Box>
						</CardContent>
					</Card>
				</div>
			</Box>
		</Box>
	);
};

export default Project;
