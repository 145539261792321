import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Box, Container, Grid, Button, Paper, Typography } from "@material-ui/core";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import Toolbar from "src/components/collection/CollectionToolbar";
import CollectionCard from "src/components/collection/Card";
import Loading from "src/components/Loading";
import { useCollectionState } from "src/state";
import { admin_link } from "src/helpers/links.helper";

const CollectionsPage = () => {
	const blockchainInfo = useContext(BlockchainContext);
	const { promised: isLoading, collections } = useCollectionState();

	const navigate = useNavigate();

	const [filterKeyword, setFilterKeyword] = useState("");
	const [includeArchived, setIncludeArchived] = useState(false);

	const handleAddCollection = async () => {
		if (blockchainInfo && blockchainInfo.account) {
			// open the add collection page
			navigate(admin_link("/collections/add"));
		} else if (blockchainInfo) {
			blockchainInfo.connectToBlockchain();
		}
	};
	const filterCollections = keyword => setFilterKeyword(keyword);
	const filterArchive = include => setIncludeArchived(include);

	const listings = collections.filter(collection => {
		if (filterKeyword === "all") return true;

		if (!includeArchived && collection.archived) return false;

		const filterBy = new RegExp(filterKeyword, "i");
		return (
			filterKeyword === "" ||
			collection.name.search(filterBy) >= 0 ||
			collection.symbol.search(filterBy) >= 0 ||
			(collection.owner && collection.owner.search(filterBy) >= 0) ||
			collection.address.search(filterBy) >= 0 ||
			collection.projectID.search(filterBy) >= 0
		);
	});
	//.filter(collection => !blockchainInfo.networkId || collection.chainID === blockchainInfo.networkId);

	const duplicateCollection = fromCollectionID => {
		if (blockchainInfo && blockchainInfo.account) {
			// open the add collection page
			navigate(admin_link("/collections/duplicate/" + fromCollectionID));
		} else if (blockchainInfo) {
			blockchainInfo.connectToBlockchain();
		}
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: "background.default",
					minHeight: "100%",
					py: 3,
				}}
			>
				<Container maxWidth={false}>
					{isLoading ? (
						<Loading />
					) : (
						<>
							<Toolbar
								onPressAddButton={() => handleAddCollection()}
								onSearch={filterCollections}
								onToggleArchive={filterArchive}
							/>
							<Box sx={{ pt: 3 }}>
								<Grid container spacing={3}>
									{listings.map(collection => (
										<Grid item key={collection.address} lg={4} sm={6} xs={12}>
											<CollectionCard
												collection={collection}
												onCreateDuplicate={() => duplicateCollection(collection.address)}
											/>
										</Grid>
									))}
									{listings.length === 0 && (
										<Grid item xs={12}>
											<Paper sx={{ py: 3 }} align="center">
												<Typography variant="h3" align="center">
													No {process.env.REACT_APP_COLLECTION_TITLE}s Found
												</Typography>
												<Button onClick={handleAddCollection}>Add Now</Button>
											</Paper>
										</Grid>
									)}
								</Grid>
							</Box>
						</>
					)}
					{/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}
          >
            <Pagination
              color="primary"
              count={3}
              size="small"
            />
          </Box> */}
				</Container>
			</Box>
		</>
	);
};

export default CollectionsPage;
