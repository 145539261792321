const Privacy = () => {
	return (
		<section className="faq-area page-paddings">
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2 data-watermark="FAQ">Privacy Policy</h2>
							<div className="em_bar">
								<div className="em_bar_bg"></div>
							</div>
							<p className="subtitle">
								You agree and understand that by accessing or using the Food Starter Materials, you have
								affirmed that you are at least 18 years old, have the legal capacity to consent to this
								Privacy Policy, and agree to be bound by the policies and practices of this Privacy
								Policy in their entirety.
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="faq-box-main">
							<div className="faq-box">
								<p className="theme-description">
									<span className="c0">1. INTRODUCTION</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										This Privacy Policy, together with our Terms and Conditions, governs how Food
										Starter collects, processes, use, discloses, shares and protects Personal Data
										we collect from or in relation to you. The terms &ldquo;we&rdquo;,
										&ldquo;us&rdquo; and &ldquo;our&rdquo; refer to Food Starter and its Affiliates
										and related companies. This Privacy Policy constitutes a part of the Terms and
										Conditions which you have previously agreed to by clicking a checkbox and a
										button that contains the words &ldquo;I Agree&rdquo; when the Terms and
										Conditions and Privacy Policy were presented to you or, if earlier, by accessing
										or using the Food Starter Materials.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Personal Data as defined by the Personal Data Protection Act 2012 (No. 26 of
										2012 of Singapore) (&ldquo;PDPA&rdquo;) means data, whether true or not, about
										an individual who can be identified from that data or from that data and other
										information to which the organization has or is likely to have access. Food
										Starter values your privacy and is PDPA compliant.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										The governing language of this Privacy Policy is English. Any other language
										translation is provided for convenience only and the case of any difference
										between the English version and the translated versions of this Privacy Policy,
										the English version shall prevail.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c3">
										If you have queries, comments or concerns regarding our Privacy Policy or wish
										to: (i) withdraw any consent provided to us in respect of the collection, use
										and/or disclosure by us of Personal Data about you for any purpose; (ii) receive
										information about the Personal Data we have in relation to you; or (iii) update
										Personal Data we have in relation to you, please contact the Data Protection
										Officer (Ms Rosa Diswanty), appointed in compliance with PDPA at&nbsp;
									</span>
									<span className="c3 c6">
										<a className="c5" href="mailto:compliance@Worldfoodchain.io">
											compliance@foodstarter.io
										</a>
									</span>
									<span className="c0">.</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										By accessing or using the Food Starter Materials, you consent to our data
										practices as described in this Privacy Policy, including the collection, use
										and/or disclosure of Personal Data provided by or on behalf of you and/or
										relating to you by us and our service providers and agents described herein. If
										you do not agree with, consent to or are not comfortable with any aspect of this
										Privacy Policy, you should immediately stop accessing and/or using Food Starter
										Materials.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We reserve the right to modify this Privacy Policy at any time and/or when
										required by law. We will notify Users of any changes to this Privacy Policy by
										email (sent to the email address as specified in your Account) and/or by means
										of a notice published on the Platform. Such modifications will be effective and
										binding on you upon posting such modifications onto the Platform or such other
										timing as Food Starter may specify in writing. You acknowledge that it is your
										responsibility to check for notifications regularly and the updated Privacy
										Policy upon such notifications. You further agree that any notification of
										modifications in the manner as aforesaid shall be sufficient notice to you and
										your continued access to and/or use of the Food Starter Materials following the
										notification of any modifications to this Privacy Policy indicates your
										acceptance of any such modifications.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">2. DEFINITIONS</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Capitalized terms used in this Privacy Policy have the following meanings, and
										any capitalized terms not defined in this Privacy Policy shall be as defined in
										the Terms and Conditions:
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										&ldquo;Personal Data&rdquo;&nbsp;In addition to the definition of Personal Data
										as defined by the PDPA above, Personal Data also means any information that
										directly or indirectly can be used to identify a natural person, such as a name,
										an identification number, location data, an online identifier or to one or more
										factors specific to the physical, economic, cultural or social identity of you
										as a natural person.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">3. COLLECTION OF PERSONAL DATA</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Food Starter wants you to understand the types of information Food Starter
										collects when you register for an Account and/or access and/or use the Food
										Starter Materials. You decide whether or not to provide us with the information,
										however we may not be able to offer you some or all of our Services if you
										choose not to share certain information with us.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Personal Data and information we may collect from or in relation to you include
										but are not limited to the following:
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(i) Identification Information - full name, home address, email address, date of
										birth, age, nationality, gender, signature, photographs, tax identification
										number, passport number, driver license details, national identity card details,
										immigration visa information;
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(ii) Account Information - username, password, account settings and preferences;
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(iii) Financial Information - bank account numbers, bank statement, virtual
										currency wallet addresses, trading data;
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(iv) Verification Information - utility bills, phone bills, bank statements, tax
										returns;
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(v) Information relating to usage of our Services - device identification,
										operating system, browser type, IP address, domain name, access date and time
										via server logs; and
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(vi) Transaction Information - trading activity, order activity, deposits,
										withdrawals and account balances.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">4. HOW WE USE YOUR PERSONAL INFORMATION</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We and our service providers and agents may collect, use, process, disclose
										and/or share Personal Data we collect from or in relation to you for purposes
										including but not limited to the following:{" "}
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(i) to communicate with you (e.g. responding to queries, sending you
										newsletters, communicating with you in relation to other products or services
										offered by us and/or our partners);{" "}
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(ii) to administer, deliver, develop, improve and personalize the Food Starter
										Materials;{" "}
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">(iii) operate and manage the Food Starter Materials; </span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(iv) administering events and activities (e.g. extending and processing
										invitations);{" "}
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">(iv) for loss prevention and anti-fraud purposes; </span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(v) to generate data from any Personal Data we collect from you or in relation
										to you for market analysis, research and survey and/or research and development
										purposes;{" "}
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(vi) to fulfil, protect and enforce our contractual and legal rights and
										obligations; and{" "}
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(vii) to comply with applicable laws, regulations and other requirements (e.g.
										providing assistance to law enforcement agencies, regulatory authorities and
										other governmental agencies; performing internal audits). Some of the foregoing
										purposes are further elaborated below:
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">Legal and Compliance Regulations</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We are required by law to collect and use your Personal Data in connection with
										our Services - E.g. we need to identify and verify customers using our Services
										in order to comply with anti-money laundering and terrorist financing laws
										across different jurisdictions. In addition, we use third parties to verify your
										identity by comparing the Personal Data you provided against third-party
										databases and public records. If you do not provide this Personal Data, you will
										not be allowed to use our Services.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">Enforcement of our Terms and Conditions</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Due to the nature of sensitive information we handle, it is important for us and
										for the benefit of our customers that we are actively monitoring, investigating,
										preventing and mitigating any potentially prohibited or illegal activities,
										enforcing our agreements with third parties, and/or violations of our Terms and
										Conditions or agreements related to the Services. We may use any of your
										Personal Data collected in connection with our Services for these purposes. The
										consequences of not being able to process your Personal Data for such purposes
										is the termination of your Account as we cannot perform our Services in
										accordance with the Terms and Conditions.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">Performance of our Obligations and Provision of our Services</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We process your Personal Data in order to provide the Services to you. For
										example, when you want to buy Digital Currency, we require certain information
										such as your identification, contact information, and payment information. We
										cannot provide you with Services without such information. The consequences of
										not being able to process your Personal Data for such purposes is the
										termination of your Account as we cannot perform our Services in accordance with
										the Terms and Conditions.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We send administrative or account-related information to you to keep you updated
										about our Services, inform you of relevant security issues or updates, or
										provide other transaction-related information. Without such communications, you
										may not be aware of important developments relating to your Account that may
										affect how you can use our Services.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We process your Personal Data to provide a personalised experience, and
										implement the preferences you request. Without such processing, we may not be
										able to ensure your continued enjoyment of part or all of our Services.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We process your Personal Data when you contact us to resolve any questions,
										disputes, collect fees, or to troubleshoot problems. We may process your
										information in response to another customer&rsquo;s request, as relevant.
										Without processing your Personal Data for such purposes, we cannot respond to
										your requests and ensure your uninterrupted use of the Services.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We process your Personal Data for quality control and staff training to make
										sure we continue to provide you with accurate information. If we do not process
										Personal Data for quality control purposes, you may experience issues on the
										Services such as inaccurate transaction records or other interruptions. Our
										basis for such processing is based on the necessity of performing our
										contractual obligations with you.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">Network and Information Security</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We process your Personal Data in order to enhance security, monitor and verify
										identity or service access, combat spam or other malware or security risks and
										to comply with applicable securities laws and regulations. The threat landscape
										on the internet is constantly evolving, which makes it more important than ever
										that we have accurate and up-to-date information about your use of our Services.
										Without processing your Personal Data, we may not be able to ensure the security
										of our Services.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">To further improve our Services</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We process your Personal Data to better understand the way you use and interact
										with our Services. In addition, we use such information to customise, measure,
										and improve our Services and the content and layout of the Website and other
										Food Starter Materials, and to develop new services. Without such processing, we
										cannot ensure your continued enjoyment of our Services.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">Marketing and Communications</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Based on your communication preferences, we may send you marketing
										communications to inform you about our events or our partner events; to deliver
										targeted marketing; and to provide you with promotional offers based on your
										communication preferences. We use information about your usage of our Services
										and your contact information to provide marketing communications. We may also
										share your Personal Data with third parties to help us with our marketing and
										promotional projects or sending marketing communications.
									</span>
								</p>
								<p className="theme-description">
									<span className="c3">
										You may opt out of our marketing activities by contacting us at&nbsp;
									</span>
									<span className="c3 c6">
										<a className="c5" href="mailto:info@Worldfoodchain.io">
											info@Foodstarter.io
										</a>
									</span>
									<span className="c0">.</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">5. THIRD PARTY INFORMATION WE RECEIVE</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										As required and/or permitted by applicable law, we may obtain information about
										you from third party sources, such as public databases, credit bureaus and
										identification verification partners for purposes of verifying your identity.
										E.g. Identification verification partners use a combination of government
										records and publicly available information about you to verify your identity.
										Such information includes your name, address, job role, public employment
										profile, credit history, status on any sanctions lists maintained by public
										authorities, and other relevant data.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">6. PERSONAL DATA WE MAY SHARE WITH OTHER PARTIES</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We take care to allow Personal Data we collect from or in relation to you to be
										accessed only by those who really need to in order to perform their tasks and
										duties, and to share with third parties who have a legitimate purpose for
										accessing it. We will generally only share Personal Data we collect from or in
										relation to you in the following circumstances:
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(i) We share your Personal Data with third party identity verification services
										and transaction monitoring services to prevent fraud and other illegal
										activities. This allows us to confirm your identity by comparing the information
										you provide us to public records and other third party databases. These service
										providers may create derivative data based on your Personal Data that can be
										used solely in connection with the provision of identity verification and fraud
										prevention services.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(ii) We share your Personal Data with financial institutions and payment
										services providers with which we partner to process payments you have
										authorised.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(iii) We share your Personal Data with service providers under contract who help
										with parts of our business operations such as payment, marketing, and technology
										services. Our contracts provide for binding enforceable requirements on our
										service providers in compliance with applicable laws.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(iv) We may share Personal Data we collect from or in relation to you with
										companies or other entities that purchase our assets pursuant to a
										court-approved sale under applicable bankruptcy laws and / or where we are
										required to share we collect from or in relation to you pursuant to applicable
										insolvency law.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(v) We may share Personal Data we collect from or in relation to you with law
										enforcement agencies, regulatory authorised or other governmental authorities,
										officials, or other third parties when we are compelled to do so by a subpoena,
										court order, or similar legal procedure, or when we believe in good faith that
										the disclosure of Personal Data we collect from or in relation to you is
										necessary to prevent physical harm or financial loss, to report suspected
										illegal activity or to investigate violations of our Terms and Conditions or any
										other applicable rules, policies or procedures.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(vi) We may share Personal Data we collect from or in relation to you with
										companies or other entities that we plan to merge with or be acquired by. Should
										such a combination occur, we will require that the new combined entity follow
										this Privacy Policy with respect to Personal Data we collect from or in relation
										to you. You will receive prior notice of any change in applicable policies,
										including in the manner as set out in this Privacy Policy.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(vii) We may store and process Personal Data we collect from or in relation to
										you in our facilities in various locations, including Singapore, Hong Kong,
										Thailand, Philippines and Japan. This Privacy Policy applies regardless of the
										location in which we store and process your Personal Data.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">7. INFORMATION SECURITY</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We use our best endeavours to ensure confidentiality and protection of Personal
										Data we collect from or in relation to you, however, we cannot guarantee
										absolute security. We work hard to protect Personal Data we collect from or in
										relation to you from unauthorized access, alteration, modification, collection,
										use, disclosure, copying, disposal, destruction or similar risks. E.g. we use
										data encryption and firewalls and we restrict access to your Personal Data on a
										need to know basis for our employees, contractors and agents who require the
										information to fulfil their job responsibilities.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Further, we cannot ensure the security or confidentiality of Personal Data that
										you transmit to us or receive from us via the internet or wireless connection.
									</span>
								</p>
								<p className="theme-description">
									<span className="c3">
										If you have reason to believe that your data is no longer secure, or if you
										become aware of any unauthorised access to or use of your account, please
										contact us at the email address&nbsp;
									</span>
									<span className="c3 c6">
										<a className="c5" href="mailto:compliance@Worldfoodchain.io">
											compliance@foodstarter.io
										</a>
									</span>
									<span className="c0">.</span>
								</p>
								<p className="theme-description">
									<span className="c0">&nbsp;</span>
								</p>
								<p className="theme-description">
									<span className="c0">8. RETENTION OF PERSONAL DATA</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We take steps to ensure that we retain Personal Data no longer than is necessary
										for the fulfilment of the purpose for which it was collected, unless the further
										retention of such Personal Data is otherwise permitted or required by applicable
										laws and regulations.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">9. UNDER-AGE PERSONAL DATA</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We do not knowingly request to collect Personal Data from any person under the
										age of 18. If a User submitting Personal Data is suspected of being younger than
										18 years of age, we will require the User to close his or her account and will
										not allow the User to continue buying or selling digital currencies. We will
										also take steps to delete the Personal Data we collect from or in relation to
										such User as soon as possible. Please notify us if you know of any individuals
										under the age of 18 using our Services so we can take action to prevent access
										to our Services.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										10. EUROPEAN ECONOMIC AREA &amp; CHANNEL ISLANDS PRIVACY STATEMENT
									</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										While customers who are located in the EEA or the Channel Islands are customers
										of our Singapore entity, we recognize and, to the extent applicable to us,
										adhere to relevant EEA or the Channel Islands data protection laws.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										While Users who are located in the EEA or the Channel Islands are customers of
										our Singapore entity, we recognize and, to the extent applicable to us, adhere
										to relevant EEA or the Channel Islands data protection laws.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We may collect Personal Data from Users located in the EEA or the Channel
										Islands. To facilitate the services we provide to Users located in the EEA or
										the Channel Islands, we request explicit consent for the transfer of Personal
										Data from the EEA or the Channel Islands to Singapore. If you are an individual
										located in the EEA or the Channel Islands and you decline to consent to such
										transfer, you will no longer be able to use Food Starter Materials and our
										Services. You will have the ability to withdraw Digital Assets and Legal Tender
										from your Account; however, all other functionality will be disabled.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">Lawful Grounds to Process and Obtain Consent</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										We process the Personal Data of users who are located in the EEA or the Channel
										Islands for one or more of several lawful purposes, including:
									</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(i) To provide you with our Services, including customer service support;
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(ii) To optimize and enhance our Services for all customers or for you
										specifically;
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(iii) To comply with legal obligations, including to conduct anti-fraud and
										identity verification and authentication checks (you authorize us to share your
										Personal Data with our third-party service providers, who may also conduct their
										own searches of publicly available Personal Data about you);
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										(iv) To monitor the usage of our Services, conduct automated and manual security
										checks of our service, to protect our rights and perform our lawful obligations.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Users in the EEA or the Channel Islands may withdraw consent at any time where
										consent is the lawful basis for processing their Personal Data. Should a user
										withdraw consent for processing or otherwise object to processing that impedes
										our ability to comply with applicable laws and regulations, a user may be unable
										to avail him or herself of the Services we provide.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">AUTOMATED DECISION-MAKING</span>
								</p>
								<p className="theme-description">
									<span className="c0">We do not engage in automated decision-making.</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">Non-Disclosure of Personal Data</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Our employees are prohibited, either during or after their employment, from
										disclosing Personal Data to any person or entity outside of our company,
										including family members, except under the circumstances described above. An
										employee is only permitted to disclose the Personal Data of a user to such other
										employees who need access to such information in order to deliver our services
										to that user.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										If, as an EEA or Channel Islands Citizen, you believe that we have not
										adequately resolved any such issues, you have the right to contact your local
										supervisory authority.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">11. THIRD PARTY PERSONAL DATA</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										Where you disclose Personal Data of other individuals to us, you warrant that
										such individuals have consented to us collecting, processing, using, disclosing
										and/or sharing such Personal Data for: (i) the relevant purpose for which you
										made the disclosure or as was notified to you at the relevant time; and (ii) the
										other purposes as described below in this Privacy Policy.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">12. QUESTIONS</span>
								</p>
								<p className="theme-description">
									<span className="c3">
										If you have questions or concerns regarding this Privacy Policy, please feel
										free to email us at&nbsp;
									</span>
									<span className="c3 c6">
										<a className="c5" href="mailto:compliance@Worldfoodchain.io">
											compliance@Foodstarter.io
										</a>
									</span>
									<span className="c0">.</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										This Privacy Policy only pertains to our policies and practices with regard to
										your Personal Information. Websites linked to and/or from our website are not
										covered by this Privacy Policy.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">13. Headings and Interpretation</span>
								</p>
								<p className="theme-description">
									<span className="c0">
										The headings and subheadings herein are included for convenience and
										identification only and are not intended to interpret, define or limit the
										scope, extent or intent of this Privacy Policy in any manner whatsoever.
									</span>
								</p>
								<p className="theme-description">
									<span className="c0"></span>
								</p>
								<p className="theme-description">
									<span className="c0">
										The meaning of general words is not limited by specific examples introduced by
										expressions such as, &ldquo;including&rdquo;, &ldquo;for example&rdquo;,
										&ldquo;such as&rdquo;, or such similar expressions, and the word
										&ldquo;includes&rdquo; or &ldquo;including&rdquo; as used in this Privacy Policy
										shall be construed to mean &ldquo;includes without limitation&rdquo; or, as the
										case may be, &ldquo;including without limitation&rdquo;.
									</span>
								</p>
								<p className="theme-description">
									<span className="c7"></span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Privacy;
