import { Link as RouterLink } from "react-router-dom";
export const BlockHero = () => (
	<>
		<section className="slider-area">
			<div className="slide-animate-img">
				<img src="/assets/images/home-animation-image1.png" alt="" />
			</div>
			<div className="slide-animate-img slide-animate-down">
				<img src="/assets/images/home-animation-image1.png" alt="" />
			</div>
			<div className="slider-inner">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
							<div className="slider-information">
								<h1>Agri-business Asset-Backed NFT Marketplace</h1>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
								<p className="theme-description">
									We are forward looking WEB 3 Agri-technology NFT Marketplace to help Agri-projects in the value chain to increase their potential growth. Now you can contribute to the world's food safety and security.
								</p>
								<div className="slider-btn">
									<RouterLink to="/projects" className="theme-btn">
										Start Contributing
									</RouterLink>
									<RouterLink to="/profile/projects/add" className="theme-btn sl-sale-btn">
										List Your Project
									</RouterLink>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
							<div className="slider-images">
								<img src="/assets/images/slide-image-new.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
);
