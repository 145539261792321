import { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField, Typography, InputAdornment, Select, MenuItem, FormControl } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import ImagePicker from "./ImagePicker";

const colWidth = (index, total) => (index === total - 1 ? Math.ceil(10 / total) : Math.floor(10 / total));

const PropertyItem = ({ data, fields, onDelete, onChange }) => {
	return (
		<Grid container columnSpacing={4} rowSpacing={2} sx={{ alignItems: "center" }} mb={4}>
			{fields.map((field, index) => {
				let type = "text";
				let inputProps = {};
				if (field.type) {
					switch (field.type) {
						case "percentage":
							type = "number";
							inputProps = {
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							};
							break;
						default:
							type = field.type;
							break;
					}
				}

				if (type === "checkbox") {
					return (
						<Grid key={`${index}-${field.name}-body`} item sm={colWidth(index, fields.length)} xs={12}>
							<Typography variant="h5">{field.title}</Typography>
							{field.options.map(option => (
								<div className="form-check">
									<input
										className="form-check-input"
										id={`${index}-${field.name}-${option.value}`}
										type={type}
										name={option.name ?? field.name}
										value={option.value}
										onChange={onChange}
										checked={option.value === data[field.name]}
									/>
									<label
										htmlFor={`${index}-${field.name}-${option.value}`}
										className="form-check-label"
									>
										{option.title}
									</label>
								</div>
							))}
						</Grid>
					);
				}

				if (type === "select") {
					return (
						<Grid key={`${index}-${field.name}-body`} item sm={colWidth(index, fields.length)} xs={12}>
							<Typography variant="h5" mb={2}>{field.title}</Typography>
							<FormControl fullWidth>
								<Select className="nolegend" name={field.name} value={data ? data[field.name] : ""} onChange={onChange} onBlur={onChange}>
									{field.options.map(option => (
										<MenuItem value={option.key}>{option.title}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					);
				}

				if (type === "file") {
					return (
						<Grid key={`${index}-${field.name}-body`} item sm={colWidth(index, fields.length)} xs={12}>
							<Typography variant="h5" mb={2}>{field.title}</Typography>
							<div style={{ display: "flex", gap: "10px" }}>
								{field.mode === "optional" && (
									<div className="form-check">
										<input
											className="form-check-input"
											id={`${index}-${field.name}-yes`}
											type="checkbox"
											name={field.name}
											value="yes"
											onChange={onChange}
											checked={"yes" === data[field.name]}
										/>
										<label htmlFor={`${index}-${field.name}-yes`} className="form-check-label">
											Yes
										</label>
									</div>
								)}
								{(field.mode !== "optional" || data[field.name] === "yes") && (
									<div style={{ width: "100%" }}>
										<ImagePicker
											singleColumn={true}
											name={`${field.name}-file`}
											onFileSelect={(pickerName, file) =>
												onChange({
													target: { name: `${field.name}-selected-file`, value: file, type: "file" },
												})
											}
											value={data[`${field.name}-file`]}
										/>
									</div>
								)}
							</div>
						</Grid>
					);
				}

				return (
					<Grid key={`${index}-${field.name}-body`} item sm={colWidth(index, fields.length)} xs={12}>
						<Typography variant="h5">{field.title}</Typography>
						<TextField
							fullWidth
							multiline={type === "textarea"}
							rows={4}
							type={type}
							margin="normal"
							name={field.name}
							defaultValue={data ? data[field.name] : ""}
							onChange={onChange}
							InputProps={inputProps}
							className="nolegend"
						/>
					</Grid>
				);
			})}
			<Grid item sm={2} xs={12} className="align-right-mobile">
				<IconButton onClick={onDelete}>
					<RemoveIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};

const MultilineField = ({ items, onChange, fields, addButtonText, addButtonAlignment }) => {
	const defaultItem = {};
	fields.map(field => (defaultItem[field.name] = ""));

	const [listItems, setListItems] = useState(
		!items || !items.length
			? [{ ...defaultItem, _id: Math.random() }]
			: [...items.map(item => ({ ...defaultItem, ...item, _id: Math.random() }))]
	);

	const handleDelete = index => {
		if (listItems.length <= 1) {
			setListItems([{ ...defaultItem, _id: Math.random() }]);
			return;
		}

		setListItems(current => current.filter((item, i) => i != index));

		onChange(listItems);
	};

	const handleAddMore = () => {
		setListItems([...listItems, { ...defaultItem, _id: Math.random() }]);
	};

	const handleChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...listItems];
		if (e.target.type !== "checkbox") list[index][name] = value;
		else if (e.target.checked) list[index][name] = value;
		else list[index][name] = "";
		console.log(list);
		setListItems(list);

		onChange(list);
	};

	return (
		<>
			{/* <Grid container columnSpacing={4} mt={3} className="d-none d-sm-block">
				{fields.map((field, index) => (
					<Grid key={`${index}-${field.name}-title`} item sm={colWidth(index, fields.length)} xs={12}>
						<Typography variant="h5">{field.title}</Typography>
					</Grid>
				))}
				<Grid item sm={2} xs={12}></Grid>
			</Grid> */}

			{listItems.map((property, index) => (
				<PropertyItem
					data={property}
					onChange={e => handleChange(e, index)}
					onDelete={() => handleDelete(index)}
					fields={fields}
					key={`${index}-${property._id}`}
				/>
			))}
			<Box mt={2} sx={{ textAlign: addButtonAlignment }}>
				<Button variant="outlined" onClick={handleAddMore}>
					{addButtonText}
				</Button>
			</Box>
		</>
	);
};

MultilineField.defaultProps = {
	fields: [
		{ name: "title", title: "Type", type: "text" },
		{ name: "value", title: "Name", type: "text" },
	],
	addButtonText: "Add More",
	addButtonAlignment: "center",
};

export default MultilineField;
