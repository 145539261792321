import { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { Alert, Box, Button, Container, TextField, Typography, Grid, CircularProgress } from "@material-ui/core";
import { useSessionState, useGlobalState, useErrorState } from "src/state";
import { initial } from "src/helpers/string.helper";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import FarmerProfile from "src/components/users/FarmerProfile";
import FarmLandProfile from "src/components/users/FarmLandProfile";
import ImagePicker from "src/components/ImagePicker";
import { convertBufferToBlob, readBlobToBuffer } from "src/helpers/file.helper";
import { uploadFile as uploadFileToCache } from "src/plugins/Cloudinary";
import { updateUserPassword } from "src/plugins/FirebaseAuth";
import { fetchDefaultSession } from "src/db/firebase/users";

const SocialTextBox = ({ error, label, name, onBlur, onChange, value }) => (
	<TextField
		error={error}
		fullWidth
		helperText={error}
		label={label}
		margin="normal"
		name={name}
		onBlur={onBlur}
		onChange={onChange}
		type="text"
		value={value}
		variant="outlined"
	/>
);

const Settings = () => {
	const navigate = useNavigate();
	const { setLoggedInUser, setProfile, profile, updateProfile } = useSessionState();
	const { setErrorMessage } = useErrorState();
	const { getRedirectToAfterConnect, setRedirectToAfterConnect } = useGlobalState();
	const location = useLocation();

	const [currentTab, setCurrentTab] = useState("basic");

	const blockchainInfo = useContext(BlockchainContext);

	const [successMessage, setSuccessMessage] = useState("");
	const [uploadingProcess, setUploadingProcess] = useState(false);

	// useEffect(() => {
	//   if (!blockchainInfo.account) {
	//     navigate("/connect-wallet");
	//   }
	// }, [blockchainInfo]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currentTab])

	useEffect(() => {
		fetchDefaultSession().then(user => {
			if (user && user.userID)
				setLoggedInUser(user);
		})
	}, [])

	const handleConnectButton = () => {
		setRedirectToAfterConnect(location.pathname);

		//if (blockchainInfo) blockchainInfo.connectToBlockchain();
		navigate("/connect-wallet");
	};

	const switchTabTo = (tabID) => {
		setSuccessMessage("")
		setCurrentTab(tabID)
	}

	const TabButton = ({ selected, title, id, onClick }) => {
		return (
			<button
				className={`nav-link ${selected ? "active" : ""}`}
				id={id}
				data-bs-toggle="tab"
				type="button"
				role="tab"
				aria-selected={selected}
				onClick={onClick}
			>
				{title}
			</button>
		);
	};
	
	const handleUpdateAvatar = (filename, file) => {
		if (file && file.type.match("^image/")) {
			setUploadingProcess(true);

			uploadFileToCache(file, profile.userID, "image").then((url) => {
				updateProfile(profile.userID, {avatar: url})
			}).catch(error => {
				setErrorMessage("Unable to set your profile pic, please try again!")
			}).finally(() => {
				setUploadingProcess(false)
			});
		} else {
			setErrorMessage("Please select an image (jpg/png) as your profile pic")
		}
	}

	return (
		<>
			<section className="min-item-area page-paddings">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="section-title text-center">
								<h2 data-watermark="Items">My Profile</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
							</div>
						</div>
					</div>
					<nav>
						<div className="nav nav-tabs" id="nav-tab" role="tablist">
							<TabButton
								selected={currentTab === "basic"}
								title="Basic Info"
								id="nav-profile-basic"
								onClick={() => switchTabTo("basic")}
							/>
							{profile.accountType === "farmer" && (
								<TabButton
									selected={currentTab === "portfolio"}
									title="Portfolio"
									id="nav-profile-portfolio"
									onClick={() => switchTabTo("portfolio")}
								/>
							)}
							{profile.accountType === "farmer" && (
								<TabButton
									selected={currentTab === "farm-land"}
									title="Farms"
									id="nav-profile-farm-land"
									onClick={() => switchTabTo("farm-land")}
								/>
							)}
							<TabButton
								selected={currentTab === "security"}
								title="Security"
								id="nav-profile-security"
								onClick={() => switchTabTo("security")}
							/>
						</div>
					</nav>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="user-panel-main" style={{ padding: "50px 0" }}>
								<div className={currentTab !== "basic" ? "d-none" : ""}>
									<Formik
										initialValues={{
											...profile,
										}}
										validationSchema={Yup.object().shape({
											username: Yup.string().max(255).required("Username is required"),
										})}
										onSubmit={(values, { setSubmitting }) => {
											// remove userID, email from the data
											const { userID, email, ...rest } = values;

											setProfile(profile.userID, { ...rest });
											setSubmitting(false);
											setSuccessMessage("Profile Updated!");

											const redirectTo = getRedirectToAfterConnect();
											if (redirectTo) {
												setRedirectToAfterConnect(null);
												navigate(redirectTo, { replace: true });
												return
											}

											switchTabTo("portfolio")
										}}
									>
										{({
											errors,
											handleBlur,
											handleChange,
											handleSubmit,
											isSubmitting,
											touched,
											values,
											dirty,
											isValid,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="user-store-area">
													<div className="row">
														<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
															<div className="user-setting-box-area">
																<div className="row user-setting-box-top">
																	<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 user-setting-left">
																		<div className="setting-change-profile text-center">
																			<div className="profile-pic-container" style={{position: "relative"}}>
																			<ImagePicker
																				withDragDrop
																				singleColumn
																				name="avatar"
																				buttonText="Your Profile Pic"
																				onFileSelect={handleUpdateAvatar}
																				value={profile.avatar ?? ""}
																				buttonProps={{ color: "primary", variant: "contained" }}
																			/>
																				{uploadingProcess && <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}><CircularProgress /></div>}
																			</div>
																			{/* <h3 className="user-title">Change Image</h3> */}
																			<h3 className="user-title">
																				{profile.email}
																			</h3>
																		</div>
																	</div>
																	<div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 user-setting-right">
																		<div className="row">
																			<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
																				<div className="theme-input-box">
																					<TextField
																						error={Boolean(
																							touched.username &&
																								errors.username
																						)}
																						fullWidth
																						helperText={
																							touched.username &&
																							errors.username
																						}
																						label="Display Name"
																						margin="normal"
																						name="username"
																						onBlur={handleBlur}
																						onChange={handleChange}
																						type="text"
																						value={values.username}
																						variant="outlined"
																						autoComplete="off"
																					/>
																				</div>
																			</div>
																			{blockchainInfo &&
																				blockchainInfo.account && (
																					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
																						<div className="theme-input-box">
																							<TextField
																								fullWidth
																								label="Wallet Address"
																								margin="normal"
																								name="username"
																								type="text"
																								variant="outlined"
																								value={
																									blockchainInfo.account
																								}
																								inputProps={{
																									readOnly: true,
																								}}
																							/>
																						</div>
																					</div>
																				)}
																			<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
																				<div className="theme-input-box">
																					<p className="theme-description">
																						Let the community know more
																						about you!
																					</p>
																					<TextField
																						fullWidth
																						multiline
																						rows={4}
																						label="Bio"
																						margin="normal"
																						name="bio"
																						variant="outlined"
																						onBlur={handleBlur}
																						onChange={handleChange}
																						value={values.bio}
																					/>
																				</div>
																			</div>
																		</div>
																		{(!blockchainInfo ||
																			!blockchainInfo.account) && (
																			<>
																				<Box mt={3}>
																					<Button
																						variant="contained"
																						size="large"
																						onClick={handleConnectButton}
																					>
																						Connect Your Wallet
																					</Button>
																				</Box>
																				<Typography variant="caption">
																					To do any transactions on
																					blockchain
																				</Typography>
																			</>
																		)}
																	</div>
																</div>
																<div className="row">
																	<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
																		<div className="theme-form-title text-center">
																			<h3 className="theme-title">
																				<span>Social Media</span>
																			</h3>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
																		<div className="theme-input-box">
																			<SocialTextBox
																				name="twitter"
																				label="Twitter"
																				value={values.twitter}
																				onBlur={handleBlur}
																				onChange={handleChange}
																				error={Boolean(
																					touched.twitter && errors.twitter
																				)}
																			/>
																		</div>
																	</div>
																	{/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
																	<div className="theme-input-box">
																		<SocialTextBox
																			name="cent"
																			label="Cent"
																			value={values.cent}
																			onBlur={handleBlur}
																			onChange={handleChange}
																			error={Boolean(touched.cent && errors.cent)}
																		/>
																	</div>
																</div> */}
																	<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
																		<div className="theme-input-box">
																			<SocialTextBox
																				name="reddit"
																				label="Facebook"
																				value={values.reddit}
																				onBlur={handleBlur}
																				onChange={handleChange}
																				error={Boolean(
																					touched.reddit && errors.reddit
																				)}
																			/>
																		</div>
																	</div>
																	<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
																		<div className="theme-input-box">
																			<SocialTextBox
																				name="youtube"
																				label="Youtube"
																				value={values.youtube}
																				onBlur={handleBlur}
																				onChange={handleChange}
																				error={Boolean(
																					touched.youtube && errors.youtube
																				)}
																			/>
																		</div>
																	</div>
																	<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
																		<div className="theme-input-box">
																			<SocialTextBox
																				name="instagram"
																				label="Instagram"
																				value={values.instagram}
																				onBlur={handleBlur}
																				onChange={handleChange}
																				error={Boolean(
																					touched.instagram &&
																						errors.instagram
																				)}
																			/>
																		</div>
																	</div>
																	{/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
																	<div className="theme-input-box">
																		<div className="Listing-checkbox">
																			<input
																				className="styled-checkbox"
																				id="styled-checkbox-1"
																				type="checkbox"
																				defaultValue="value1"
																			/>
																			<label htmlFor="styled-checkbox-1">
																				<span>
																					<b>Disable email notifications.</b>{" "}
																					(You won't recieve ANY emails from
																					Mintable if you do this - including
																					important ones related to your
																					account security or purchases)
																				</span>
																			</label>
																		</div>
																	</div>
																</div> */}
																	<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
																		<div
																			className="theme-input-box text-center"
																			style={{ marginTop: "30px" }}
																		>
																			<Button
																				className="theme-btn"
																				disabled={
																					isSubmitting || !isValid
																				}
																				size="large"
																				type="submit"
																				variant="contained"
																			>
																				Save Profile & Continue
																			</Button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</form>
										)}
									</Formik>
								</div>
								{profile.accountType === "farmer" && (
									<div className={currentTab !== "portfolio" ? "d-none" : ""}>
										<FarmerProfile onSave={() => { setSuccessMessage("Profile Updated!"); switchTabTo("farm-land")} } />
									</div>
								)}
								{profile.accountType === "farmer" && (
									<div className={currentTab !== "farm-land" ? "d-none" : ""}>
										<FarmLandProfile onSave={() => { setSuccessMessage("Profile Updated!"); navigate("/profile/certificate")} } />
									</div>
								)}
								<div className={currentTab !== "security" ? "d-none" : ""}>
									<Formik
										initialValues={{
											password: "",
											confirm_password: ""
										}}
										validationSchema={Yup.object().shape({
											password: Yup.string().min(8).required("Password is required"),
											confirm_password: Yup.string().test('passwords-match', 'Passwords must match', function(value){
												return this.parent.password === value
											})
										})}
										onSubmit={(values, { setSubmitting, resetForm, }) => {
											// remove userID, email from the data
											// const { userID, email, ...rest } = values;

											// setProfile(profile.userID, { ...rest });

											// const redirectTo = getRedirectToAfterConnect();
											// if (redirectTo) {
											// 	setRedirectToAfterConnect(null);
											// 	navigate(redirectTo, { replace: true });
											// }

											updateUserPassword(values.password);
											setSubmitting(false);
											setSuccessMessage("Profile Updated!");
											resetForm()
										}}
									>
										{({
											errors,
											handleBlur,
											handleChange,
											handleSubmit,
											isSubmitting,
											touched,
											values,
											dirty,
											isValid,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
														<div className="theme-form-title text-center">
															<h3 className="theme-title">
																<span>Update Password</span>
															</h3>
														</div>
														<div className="theme-input-box">
															<p
																className="theme-description"
																style={{ textAlign: "center" }}
															>
																Type in a new password twice if you would like to change
																your current password.
															</p>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
														<div className="theme-input-box">
															<TextField
																error={Boolean(touched.password && errors.password)}
																fullWidth
																helperText={touched.password && errors.password}
																label="New Password"
																margin="normal"
																name="password"
																onBlur={handleBlur}
																onChange={handleChange}
																type="password"
																value={values.password}
																variant="outlined"
																autoComplete="off"
															/>
														</div>
													</div>
													<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
														<div className="theme-input-box">
															<TextField
																error={Boolean(
																	touched.confirm_password && errors.confirm_password
																)}
																fullWidth
																helperText={
																	touched.confirm_password && errors.confirm_password
																}
																label="Confirm Password"
																margin="normal"
																name="confirm_password"
																onBlur={handleBlur}
																onChange={handleChange}
																type="password"
																value={values.confirm_password}
																variant="outlined"
																autoComplete="off"
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
														<div
															className="theme-input-box text-center"
															style={{ marginTop: "30px" }}
														>
															<Button
																className="theme-btn"
																disabled={isSubmitting || !dirty || !isValid}
																size="large"
																type="submit"
																variant="contained"
															>
																Save Password
															</Button>
														</div>
													</div>
												</div>
											</form>
										)}
									</Formik>
								</div>
							</div>
							{successMessage && <Alert severity="success">{successMessage}</Alert>}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Settings;
