import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { registerWithEmailAndPassword } from "src/plugins/FirebaseAuth";

const EmailSignup = ({ onSignupSuccess, onSignupFailed }) => {
	const defaultSignup = {
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		confirm_password: "",
	};

	return (
		<Formik
			initialValues={{ ...defaultSignup }}
			validationSchema={Yup.object().shape({
				first_name: Yup.string().required("Your first name is required"),
				last_name: Yup.string().required("Your last name is required"),
				email: Yup.string().email("Must be a valid email").max(255).required("Your email is required"),
				password: Yup.string().max(20).min(8).required("Password is required"),
				confirm_password: Yup.string()
					.required("Please enter your password again")
					.oneOf([Yup.ref("password"), null], "Passwords didn't match"),
			})}
			onSubmit={(values, { setSubmitting }) => {
				registerWithEmailAndPassword(values).then(res => {
					if (res.error) {
						onSignupFailed(res.error);
					} else if (res && res.email) {
						onSignupSuccess({ ...res, ...values, displayName: `${values.first_name} ${values.last_name}` });
					} else {
						onSignupFailed("Unable to complete signup! Please try again.");
					}
				});

				setSubmitting(false);
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, dirty, isValid }) => (
				<form onSubmit={handleSubmit}>
					<Grid container>
						<Grid md={6} xs={12} item>
							<TextField
								error={Boolean(touched.first_name && errors.first_name)}
								fullWidth
								helperText={touched.first_name && errors.first_name}
								label="First Name"
								margin="normal"
								name="first_name"
								onBlur={handleBlur}
								onChange={handleChange}
								type="text"
								value={values.first_name}
								variant="outlined"
								autoComplete="off"
							/>
						</Grid>
						<Grid md={6} xs={12} item>
							<TextField
								error={Boolean(touched.last_name && errors.last_name)}
								fullWidth
								helperText={touched.last_name && errors.last_name}
								label="Last Name"
								margin="normal"
								name="last_name"
								onBlur={handleBlur}
								onChange={handleChange}
								type="text"
								value={values.last_name}
								variant="outlined"
								autoComplete="off"
							/>
						</Grid>
					</Grid>
					<TextField
						error={Boolean(touched.email && errors.email)}
						fullWidth
						helperText={touched.email && errors.email}
						label="Email Address"
						margin="normal"
						name="email"
						onBlur={handleBlur}
						onChange={handleChange}
						type="email"
						value={values.email}
						variant="outlined"
						autoComplete="off"
					/>
					<TextField
						error={Boolean(touched.password && errors.password)}
						fullWidth
						helperText={touched.password && errors.password}
						label="Password"
						margin="normal"
						name="password"
						onBlur={handleBlur}
						onChange={handleChange}
						type="password"
						value={values.password}
						variant="outlined"
						autoComplete="off"
					/>
					<TextField
						error={Boolean(touched.confirm_password && errors.confirm_password)}
						fullWidth
						helperText={touched.confirm_password && errors.confirm_password}
						label="Confirm Password"
						margin="normal"
						name="confirm_password"
						onBlur={handleBlur}
						onChange={handleChange}
						type="password"
						value={values.confirm_password}
						variant="outlined"
						autoComplete="off"
					/>
					<Box sx={{ py: 2 }}>
						<Button
							color="primary"
							disabled={isSubmitting || !dirty || !isValid}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
						>
							Sign Up
						</Button>
					</Box>
				</form>
			)}
		</Formik>
	);
};

export default EmailSignup;
