import { Alert, Box, Button, TextField, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { logInWithEmailAndPassword, sendPasswordReset } from "src/plugins/FirebaseAuth";
import { useState } from "react";

const EmailLogin = ({ onLoginSuccess, onLoginFailed }) => {
	const [mode, setMode] = useState("login");
	const [resetPasswordSent, setResetPasswordSent] = useState(false)

	const defaultLogin = {
		email: "",
		password: "",
	};

	const toggleForgotPassword = () => {
		if (mode === 'login')
			setMode('forgot-password')
		else {
			setMode('login')
			setResetPasswordSent(false)
		}
	}

	return (
		<>
			<Formik
				initialValues={{ ...defaultLogin }}
				validationSchema={Yup.object().shape({
					email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
					password: mode === "login" ? Yup.string().max(255).required("Password is required") : Yup.string(),
				})}
				onSubmit={(values, { setSubmitting }) => {
					if (mode === "login") {
						logInWithEmailAndPassword(values.email, values.password).then(res => {
							if (res && res.email) {
								onLoginSuccess(res);
							} else {
								onLoginFailed("Wrong username or password!");
							}
						});
					} else {
						sendPasswordReset(values.email).then(success => {
							if (success)
								setResetPasswordSent(true)
						});
					}

					setSubmitting(false);
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<TextField
							error={Boolean(touched.email && errors.email)}
							fullWidth
							helperText={touched.email && errors.email}
							label="Email Address"
							margin="normal"
							name="email"
							onBlur={handleBlur}
							onChange={handleChange}
							type="email"
							value={values.email}
							variant="outlined"
						/>
						{mode === "login" && (
							<TextField
								error={Boolean(touched.password && errors.password)}
								fullWidth
								helperText={touched.password && errors.password}
								label="Password"
								margin="normal"
								name="password"
								onBlur={handleBlur}
								onChange={handleChange}
								type="password"
								value={values.password}
								variant="outlined"
							/>
						)}
						<Box sx={{ py: 2 }}>
							<Button
								color="primary"
								disabled={isSubmitting}
								fullWidth
								size="large"
								type="submit"
								variant="contained"
							>
								{mode === "login" ? "Sign in" : "Get Password Reset Link"}
							</Button>
						</Box>
					</form>
				)}
			</Formik>
			<Box sx={{ pb: 2 }}>
				<Button
					fullWidth
					size="small"
					variant="text"
					onClick={toggleForgotPassword}
				>
					{mode === "login" ? "Forgot Password?" : "Login Now"}
				</Button>
			</Box>
			{resetPasswordSent && <Alert severity="success">Please check your email for instructions to reset your password.</Alert>}
		</>
	);
};

export default EmailLogin;
