import { Box, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// import { useTokenState, useCurrencyState } from "src/state";
// import { round } from "src/helpers/number.helper";
import { Link } from "react-router-dom";
import { cachedVersion } from "src/helpers/file.helper";
import { numberFormat } from "src/helpers/number.helper";
import { useListingState } from "src/state";
import { LinkableLocal } from "../Linkable";

const TokenItem = ({ collectionID, token, name, description, linkTo, embedded, gridOptions, ...props }) => {
	const { promised: isListingLoading, getListingByTokenID } = useListingState();

	if (!token || !token.imageUrl) return null;

	const tokenListing = getListingByTokenID(token.collectionID, token.tokenID, true);

	return (
		<Grid item {...(gridOptions ? gridOptions : { xs: 12, sm: 6, lg: 4 })}>
			<LinkableLocal
				isLinked={linkTo || token.tokenID}
				to={(linkTo ? linkTo : `/listing/${token.collectionID}/${token.tokenID}`) + (embedded ? "?view=e" : "")}
				target={embedded ? "_blank" : "_self"}
			>
				<Box className="item-group">
					<Box className="item-group-content">
						<Box className="item-group-avtar">
							<img src={cachedVersion(token.imageUrl)} />
						</Box>
						<h3 className="theme-title">{name ? name : token.itemName}</h3>
						<p className="theme-description">
							{description ? description : `@${process.env.REACT_APP_SITENAME}`}
						</p>
						<h2 className="item-price">
							{tokenListing && tokenListing.amount && !tokenListing.purchased
								? numberFormat(tokenListing.amount, 0) + " " + tokenListing.currency
								: ""}
						</h2>
						{/* <div className="item-group-timer">
							<ul className="clearfix">
								<li>
								<span>01</span> Hour
								</li>
								<li>
								<span>55</span> Min
								</li>
								<li>
								<span>00</span> Sec
								</li>
							</ul>
						</div> */}
						<Box className="item-group-btn">
							{tokenListing && !tokenListing.purchased ? (
								<Box className="theme-btn">
									{tokenListing.purchased ? (
										<>Sold For</>
									) : tokenListing.type === "auction" ? (
										<>Place Bid</>
									) : (
										<>Buy Now</>
									)}
								</Box>
							) : (
								<Box className="theme-btn">View Info</Box>
							)}
							<Box className="item-detail-btn">
								<i className="fas fa-info-circle"></i>
							</Box>
						</Box>
					</Box>
					{!token.tokenID && (
						<Box className="item-pending">
							<CircularProgress size={18} />
						</Box>
					)}
				</Box>
			</LinkableLocal>
		</Grid>
	);
};

export default TokenItem;
