import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/system";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ItemForm from "src/components/collection/ItemForm";
import { mintToken } from "src/helpers/collections.helper";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { useTokenState, useCollectionState, useProjectsState } from "src/state";

const AddTokenContainer = styled("div")(({ theme }) => ({
	maxWidth: "800px",
	margin: "0 auto",
}));

const AddToken = () => {
	const { cid: collectionID } = useParams();

	const { promised: isCollectionLoading, getCollection } = useCollectionState();
	const { promised: isProjectsLoading, getProject } = useProjectsState();
	const {
		promised: isTokenLoading,
		getTokenBySku,
		createToken,
		updateToken,
		deleteFailedToken,
	} = useTokenState(collectionID);

	const collection = getCollection(collectionID);
	const blockchainInfo = useContext(BlockchainContext);

	const navigate = useNavigate();

	const [isSavingData, setIsSavingData] = useState(false);
	const [project, setProject] = useState(null);

	useEffect(() => {
		if (!isCollectionLoading && collection.projectID && !project) {
			const projectDetails = getProject(collection.projectID);
			setProject(projectDetails);
		}
	}, [isCollectionLoading, isProjectsLoading, collection, project])

	const validateSkuBeforeAdd = item_sku => {
		const exists = getTokenBySku(collectionID, item_sku);
		if (exists) return false;

		return true;
	};

	const handleCreateItem = async data => {
		setIsSavingData(true);

		await mintToken(data, collection, blockchainInfo, createToken, updateToken, deleteFailedToken);

		navigate("/collection/" + collectionID);
	};

	return (
		<Box className="discover-area page-paddings">
			<Box className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2 data-watermark="Collection">
								{collection.name} ({collection.symbol})
							</h2>
							<div className="em_bar">
								<div className="em_bar_bg"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="browse-product-box">
					<AddTokenContainer>
						<Typography variant="h1">Create New Item</Typography>
						<ItemForm
							toValidateSku={validateSkuBeforeAdd}
							onCreateItem={handleCreateItem}
							defaultData={
								collection && collection.itemCreationData?.item_name
									? collection.itemCreationData
									: null
							}
							projectData={project && project.project_name ? project : null}
							isForFrontend={true}
						/>
					</AddTokenContainer>
				</div>
			</Box>
		</Box>
	);
};

export default AddToken;
