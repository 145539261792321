import { Box, Typography, Tabs, Tab } from "@material-ui/core";
import { useState, PropTypes } from "react";

function TabPanel(props) {
	const { children, value, index, sx = {}, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3, ...sx }}>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const CustomTabs = ({ children, tabPanelSx = {}, ...props }) => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="basic tabs example">
					{children.map((child, index) =>
						child.props ? <Tab label={child.props.label} {...a11yProps(index)} key={index} /> : null
					)}
				</Tabs>
			</Box>
			{children.map((child, index) => (
				<TabPanel sx={tabPanelSx} value={value} index={index} key={index}>
					{child}
				</TabPanel>
			))}
		</>
	);
};

export default CustomTabs;
