import { Box, Container, Typography } from "@material-ui/core";

const ComingSoon = () => (
	<>
		<section className="min-item-area page-paddings">
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2 data-watermark="Items">Coming Soon!</h2>
							<div className="em_bar">
								<div className="em_bar_bg"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="user-panel-main">
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									height: "100%",
									justifyContent: "center",
                  minHeight: "400px"
								}}
                my={4}
							>
								<Container maxWidth="md">
									<Typography align="center" variant="h2">
                    Something new, big and exciting is coming soon!
									</Typography>
                  <Typography align="center">
                    We don't want to spoil the surprise, please stay in touch so you won't miss out!
									</Typography>
								</Container>
							</Box>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
);

export default ComingSoon;
