import db from "src/plugins/Firebase";

const firestore_collection_name = "users";

const setupUserData = item => {
	const itemData = item.data();
	return {
		userID: item.id,
		username: itemData?.username ?? "",
		firstName: itemData?.firstName ?? "",
		lastName: itemData?.lastName ?? "",
		email: itemData?.email ?? "",
		bio: itemData?.bio ?? "",
		avatar: itemData?.avatar ?? "",
		emailVerified: itemData?.emailVerified ?? false,
		profileVerified: itemData?.profileVerified ?? false,
		profileSubmitted: itemData?.profileSubmitted ?? false,
		type: itemData?.type ?? "user",
		wallets: itemData?.wallets ?? [],
		connectedToWallet: itemData.connectedToWallet ?? false,
		twitter: itemData?.twitter ?? "",
		instagram: itemData?.instagram ?? "",
		onfindoApplicationID: itemData?.onfindoApplicationID ?? "",
		accountType: itemData.accountType ?? null,
		farmerProfile: itemData.farmerProfile ?? {},
		farmLandProfile: itemData.farmLandProfile ?? {},
		paymentReceipt: itemData?.paymentReceipt ?? null,
		certificateURL: itemData?.certificateURL ?? null
	};
};

const setupDataToUpdate = data => {
	// return {
	// 	username: data.username,
	// 	firstName: data?.firstName ?? "",
	// 	lastName: data?.lastName ?? "",
	// 	bio: data.bio ?? "",
	// 	twitter: data.twitter ?? "",
	// 	instagram: data.instagram ?? "",
	// 	facebook: data.facebook ?? "",
	// 	reditt: data.reditt ?? "",
	// 	youtube: data.youtube ?? "",
	// 	wallets: data.wallets ?? [],
	// 	connectedToWallet: data.connectedToWallet ?? false,
	// 	onfindoApplicationID: data.onfindoApplicationID ?? null,
	// 	accountType: data.accountType ?? null
	// };
	return {
		...data,
	};
};

export const fetchDefaultSession = async () => {
	// get from localstorage if previously logged in
	const persistantState = JSON.parse(localStorage.getItem("loggedInAs"));
	const defaultSessionState = persistantState ? persistantState : { user: null };

	if (defaultSessionState.user && defaultSessionState.user.userID) {
		const userInfo = await fetchUserProfile(defaultSessionState.user.userID);
		return userInfo;
	}

	return { user: null };
};

// Fetch Records
export const fetchUserProfile = async userID => {
	const dbData = await db.collection(firestore_collection_name).doc(userID).get();

	return setupUserData(dbData);
};

export const saveUserProfile = async (userID, data) => {
	if (!userID) return;

	var docRef = db.collection(firestore_collection_name).doc(userID);

	docRef
		.get()
		.then(doc => {
			if (doc.exists) {
				const updated = setupDataToUpdate(data);
				db.collection(firestore_collection_name).doc(userID).update(updated);
			} else {
				db.collection(firestore_collection_name)
					.doc(userID)
					.set({
						...data,
						created_at: new Date().getTime(),
					});
			}
		})
		.catch(error => {
			console.log("Error getting document:", error);
		});
};

export const fetchUsers = async () => {
	const dbResponse = db.collection(firestore_collection_name).orderBy("username", "asc");
	const dbData = await dbResponse.get();

	const data = [];

	dbData.docs.forEach(item => {
		data.push(setupUserData(item));
	});

	return data;
};
