import { cachedVersion } from "src/helpers/file.helper";
import { useSettingState } from "src/state";

const Logo = ({ mode, ...props }) => {
	const { getSetting } = useSettingState();
	const logoURL = getSetting("website_logo");
	return (
		<img
			alt="Logo"
			src={
				mode === "small"
					? "/assets/images/logo.png"
					: "/assets/images/logo-small.png"
			}
			style={{ maxWidth: "100%" }}
			{...props}
		/>
	);
};

export default Logo;
