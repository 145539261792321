import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Alert, Box, Button, Grid, Typography, TextField, CircularProgress } from "@material-ui/core";
import { admin_link } from "src/helpers/links.helper";
import { toTitleText } from "src/helpers/string.helper";
import { useSessionState } from "src/state";
import { sendEmailVerification, checkIsEmailVerified } from "src/plugins/FirebaseAuth";
import ImagePicker from "src/components/ImagePicker";
// import { init as OnfidoInit } from "onfido-sdk-ui";

import "src/layouts/assets/mainlayout/css/login.css";
// import Camera, { FACING_MODES } from "src/components/camera";
// import VideoRecorder from "src/components/videoCamera";
import { registerApplicant, getVerificationToken, getWorkflowRunID } from "src/services/onfindo";
import Countdown from "src/components/CountdownTimer";

const RadioButton = ({ name, value, label, onChange }) => {
	const handleChange = e => {
		if (onChange) onChange(name, e.target.value);
	};

	return (
		<>
			<input
				className="styled-checkbox"
				name={name}
				id={`${name}-${value}`}
				type="radio"
				value={value}
				onChange={handleChange}
			/>
			<label htmlFor={`${name}-${value}`}>{label}</label>
		</>
	);
};

const Step0Block = ({ onStepComplete }) => (
	<div className="fxt-form">
		<Typography my={2} variant="h1" sx={{ textAlign: "center" }}>
			Not Yet Verified?
		</Typography>
		<Typography my={3} sx={{ textAlign: "center" }}>
			Follow these steps:
		</Typography>
		<Typography my={2} variant="body2" sx={{ textAlign: "left" }} align="left">
			The process can take up to 15 minutes. If it looks like the process timed just reload the page. If there are
			any problems that you can not resolve please contact us at connect@foodstarter.io!
		</Typography>
		<Box mt={3}>
			<Button fullWidth variant="contained" size="large" onClick={onStepComplete}>
				Step 1 - Email Verification
			</Button>
		</Box>
	</div>
);

const Step1Block = ({ onStepError, onStepComplete }) => {
	const { loggedInUser, updateProfile } = useSessionState();
	const [verificationSent, setVerificationSent] = useState(false);
	const [resentlySent, setRecentlySent] = useState(false);

	const handleSendVerification = () => {
		sendEmailVerification();
		setVerificationSent(true);
		setRecentlySent(true);
		setTimeout(() => setRecentlySent(false), 60000);
	};

	const handleCheckVerification = async () => {
		const emailVerified = await checkIsEmailVerified()
		if (!emailVerified)
			onStepError && onStepError("Your email is still not verified. Please check your email for instructions.");
		else {
			updateProfile(loggedInUser.userID, { emailVerified: true });
			onStepComplete && onStepComplete();
		}
	};

	return (
		<Box className="animate__animated animate__fadeIn">
			<div className="fxt-form">
				<Typography my={2} variant="h1" sx={{ textAlign: "center" }}>
					Email Verification
				</Typography>
				<Typography my={3} sx={{ textAlign: "center" }}>
					Please verify your email address
				</Typography>
				<Box sx={{ textAlign: "center" }}>
					<img src="/assets/images/icon-email.svg" />
				</Box>
				{verificationSent ? (
					<Box className="animate__animated animate__fadeIn" sx={{ textAlign: "center" }}>
						<Typography my={2}>A verification email has been sent to {loggedInUser.email}</Typography>
						<Typography my={1} sx={{ fontSize: "14px !important" }}>
							Didn't recieve?
							<br />
							Please check in your spam folder as well.
						</Typography>
						<Button
							variant="contained"
							disabled={resentlySent}
							color="info"
							size="small"
							onClick={handleSendVerification}
						>
							Resend Email
						</Button>
						{resentlySent && <Countdown date={new Date(new Date().getTime() + 59000)} />}
						<Typography my={2}>Once you are done with email verification, continue with</Typography>
						<Box>
							<Button fullWidth variant="contained" size="large" onClick={handleCheckVerification}>
								Step 2 - Identity Verification
							</Button>
						</Box>
					</Box>
				) : (
					<>
						<Typography my={2}>A verification code will be sent to {loggedInUser.email}</Typography>
						<Box mt={3}>
							<Button fullWidth variant="contained" size="large" onClick={handleSendVerification}>
								Send Verification Code
							</Button>
						</Box>
					</>
				)}
			</div>
		</Box>
	);
};

const Step2aBlock = ({ onStepError, onStepComplete }) => {
	const selectType = type => onStepComplete(type);
	const { loggedInUser, logout } = useSessionState();
	const navigate = useNavigate();

	const switchAccount = e => {
		e.preventDefault();

		logout();
		navigate("/login", { replace: true });
	};

	return (
		<Box className="animate__animated animate__fadeIn">
			<Box mb={4}>
				<Typography variant="h4" my={2}>
					Please introduce yourself
				</Typography>
			</Box>
			<Box mb={4}>
				<Grid container gap={2}>
					<Grid item>
						<Button fullWidth variant="contained" size="large" onClick={() => selectType("farmer")}>
							I'm a Farmer
						</Button>
					</Grid>
					<Grid item>
						<Button fullWidth variant="contained" size="large" onClick={() => selectType("investor")}>
							I'm a Contributor
						</Button>
					</Grid>
				</Grid>
			</Box>
			<Box>
				<Typography variant="caption">
					You are logged in with {loggedInUser.email} |{" "}
					<a href="#" onClick={switchAccount}>
						Not You?
					</a>
				</Typography>
			</Box>
		</Box>
	);
};

// not used anymore
const Step2bBlock = ({ onStepError, onStepComplete }) => {
	const [verificationData, setVerificationData] = useState({});

	const setValue = (name, value) => {
		const data = { ...verificationData };
		data[name] = value;
		setVerificationData(data);
	};

	return (
		<Box className="animate__animated animate__fadeIn">
			<Box mb={4}>
				<Typography variant="h2" my={2}>
					Please verify your identity
				</Typography>
				<Typography variant="h4" my={2}>
					Select the country which have issued your identification document
				</Typography>
				<select className="custom-select" defaultValue="sg">
					<option value="sg">Singapore</option>
					<option value="id">Indonesia</option>
					<option value="ph">Phillipines</option>
				</select>
			</Box>
			<Box mb={4}>
				<Typography variant="h4" my={2}>
					Choose your document type
				</Typography>
				<Grid container gap={2}>
					<Grid item>
						<RadioButton name="documentType" value="passport" label="Passport" onChange={setValue} />
					</Grid>
					<Grid item>
						<RadioButton
							name="documentType"
							value="identity_card"
							label="Identity Card"
							onChange={setValue}
						/>
					</Grid>
					<Grid item>
						<RadioButton
							name="documentType"
							value="driving_licence"
							label="Driving Licence"
							onChange={setValue}
						/>
					</Grid>
				</Grid>
			</Box>
			{verificationData.documentType && (
				<>
					<Box mb={4}>
						<Typography>
							Take a photo of your {toTitleText(verificationData.documentType)}. The photo should be:{" "}
						</Typography>
						<Box className="user-store-info">
							<ul>
								<li>bright and clear (good quality),</li>
								<li>uncut (all corners of the document should be visible).</li>
							</ul>
						</Box>
					</Box>
					<Box mb={4}>
						{verificationData.documentType === "passport" ? (
							<Grid container sx={{ flexWrap: "nowrap" }} gap={2}>
								<Grid item>
									<img src="/assets/images/i_passport_correct.png" />
								</Grid>
								<Grid item>
									<img src="/assets/images/i_passport_incorrect.png" />
								</Grid>
								<Grid item>
									<img src="/assets/images/i_passport_incorrect_2.png" />
								</Grid>
							</Grid>
						) : (
							<Grid container sx={{ flexWrap: "nowrap" }} gap={2}>
								<Grid item>
									<img src="/assets/images/i_idcard_correct.png" />
								</Grid>
								<Grid item>
									<img src="/assets/images/i_idcard_incorrect.png" />
								</Grid>
								<Grid item>
									<img src="/assets/images/i_idcard_incorrect_2.png" />
								</Grid>
							</Grid>
						)}
					</Box>
					<Grid mb={4} gap={2} container>
						<Grid sx={{ flexGrow: 1 }} item>
							<Typography variant="h4" my={2}>
								Front
							</Typography>
							<ImagePicker
								withDragDrop
								name="image_front"
								onFileSelect={() => { }}
								buttonProps={{ color: "primary", variant: "contained" }}
								singleColumn={true}
								recommendedSize=""
							/>
						</Grid>
						<Grid sx={{ flexGrow: 1 }} item>
							<Typography variant="h4" my={2}>
								Back
							</Typography>
							<ImagePicker
								withDragDrop
								name="image_back"
								onFileSelect={() => { }}
								buttonProps={{ color: "primary", variant: "contained" }}
								singleColumn={true}
								recommendedSize=""
							/>
						</Grid>
					</Grid>
					<Button fullWidth variant="contained" size="large" onClick={onStepComplete}>
						Step 3 - Selfie
					</Button>
				</>
			)}
		</Box>
	);
};

// not used anymore
// const Step3Block = ({ onStepError, onStepComplete }) => {
// 	const [userReadyForSelfie, setUserReadyForSelfie] = useState(false);
// 	const [cameraUnavailable, setCameraUnavailable] = useState(false);
// 	const [cameraMode, setCameraMode] = useState("photo");

// 	const handleTakePhoto = dataUri => {
// 		console.log(dataUri);
// 		setCameraMode("video");
// 	};

// 	const handleCameraError = () => setCameraUnavailable(true);

// 	return (
// 		<Box className="animate__animated animate__fadeIn">
// 			<Box mb={4}>
// 				<Typography variant="h4" sx={{ textAlign: "center" }}>
// 					Selfie
// 				</Typography>
// 				{userReadyForSelfie ? (
// 					<Box className="animate__animated animate__fadeIn">
// 						{!cameraUnavailable ? (
// 							<Box my={4}>
// 								<Box my={2}>
// 									{cameraMode === "photo" ? (
// 										<Camera
// 											buttonText={cameraMode === "photo" ? "Take Selfie" : "Start Recording"}
// 											onTakePhoto={dataUri => {
// 												handleTakePhoto(dataUri);
// 											}}
// 											showSelfieMarkers={true}
// 											onCameraError={handleCameraError}
// 											idealFacingMode={FACING_MODES.ENVIRONMENT}
// 										/>
// 									) : (
// 										<VideoRecorder
// 											isOnInitially={true}
// 											onRecordingComplete={() => {}}
// 											timeLimit={5000}
// 											constraints={{ audio: false }}
// 										/>
// 									)}
// 								</Box>
// 								<Typography sx={{ textAlign: "center" }} variant="body2">
// 									{cameraMode === "photo"
// 										? "Keep your face within the frame and take a picture"
// 										: "Start recording video and slowly turn your head around in a circle."}
// 								</Typography>
// 							</Box>
// 						) : (
// 							<Box my={4}>
// 								<Typography my={4}>
// 									Camera is necessary for performing this check. Please either connect the camera and
// 									try again or repeat the check on mobile
// 								</Typography>
// 								<Button
// 									fullWidth
// 									variant="contained"
// 									size="large"
// 									onClick={() => setCameraUnavailable(false)}
// 								>
// 									Try Again
// 								</Button>
// 							</Box>
// 						)}
// 					</Box>
// 				) : (
// 					<>
// 						<Typography my={4}>
// 							Face the camera. Ensure your face is within the frame. Then, slowly turn your head around in
// 							a circle.
// 						</Typography>
// 						<Box my={4} sx={{ textAlign: "center" }}>
// 							<img
// 								src="/assets/images/selfie-guide.gif"
// 								style={{ borderRadius: "50%", overflow: "hidden" }}
// 							/>
// 						</Box>
// 						<Button fullWidth variant="contained" size="large" onClick={() => setUserReadyForSelfie(true)}>
// 							I'm Ready
// 						</Button>
// 					</>
// 				)}
// 			</Box>
// 		</Box>
// 	);
// };

const FarmerSignup = ({ onStepError, onStepComplete }) => {
	const { loggedInUser, updateProfile } = useSessionState();
	// const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	// useEffect(() => {
	// 	setTimeout(async () => {
	// 		let applicantID = null;
	// 		if (loggedInUser.onfindoApplicationID && false) {
	// 			applicantID = loggedInUser.onfindoApplicationID;
	// 		} else {
	// 			applicantID = await registerApplicant({
	// 				first_name: loggedInUser.firstName,
	// 				last_name: loggedInUser.lastName,
	// 			});

	// 			updateProfile(loggedInUser.userID, { onfindoApplicationID: applicantID });
	// 		}

	// 		const tokenID = await getVerificationToken(applicantID);
	// 		console.log(tokenID);

	// 		// get the workflow run id
	// 		const workflowRunId = await getWorkflowRunID(applicantID);
	// 		console.log(workflowRunId);

	// 		OnfidoInit({
	// 			token: tokenID,
	// 			workflowRunId: workflowRunId,
	// 			containerId: "onfido-mount",
	// 			onComplete: function (data) {
	// 				updateProfile(loggedInUser.userID, { profileSubmitted: true, accountType: "farmer" });
	// 				//navigate("/profile/settings", { replace: true });
	// 				onStepComplete && onStepComplete();

	// 				console.log("everything is complete");
	// 			},
	// 			// steps: ["welcome", "poa", "document", "face", "complete"],
	// 		});

	// 		setIsLoading(false);
	// 	}, 500);
	// }, []);

	const handleContinue = () => {
		updateProfile(loggedInUser.userID, { profileSubmitted: true, accountType: "farmer" });
		// navigate("/profile/settings", { replace: true });
		// onStepComplete && onStepComplete();
		window.location.href="/profile/settings";
	}

	return (
		<Box className="animate__animated animate__fadeIn">
			<Box mb={4}>
				<Typography variant="h4" my={2}>
					Welcome onboard! Please continue to provide more details.
				</Typography>
				<Typography variant="p">
					Data collected will be stored securily and processed to verify your identity. By submitting the
					required documents you adhere to our privacy policy.
				</Typography>
			</Box>
			<Box mb={4}>
				{/* <div id="onfido-mount"></div>
				{isLoading && <CircularProgress />} */}
				<Button fullWidth variant="contained" size="large" onClick={handleContinue}>
					Continue
				</Button>
			</Box>
		</Box>
	);
};

const ThankyouBlock = () => {
	const { loggedInUser } = useSessionState();
	const navigate = useNavigate();

	const continueToProfile = () => {
		navigate("/profile/settings", { replace: true });
	};

	return (
		<Box className="animate__animated animate__fadeIn">
			<Box mb={4}>
				<Typography variant="h4" my={2}>
					Thank You!
				</Typography>
				<Typography my={2}>Your application ID: {loggedInUser.onfindoApplicationID}</Typography>
				<Typography my={2}>
					Your application has been submitted successfully. It is being verified and we will notify you once
					the application is processed.
				</Typography>
				<Typography my={2}>
					Meanwhile please complete your profile in order to receive your FoodStarter Registration
					Certificate.
				</Typography>
			</Box>
			<Button fullWidth variant="contained" onClick={continueToProfile}>
				Continue
			</Button>
		</Box>
	);
};

const PendingBlock = () => {
	const { loggedInUser } = useSessionState();
	const navigate = useNavigate();

	const continueToHome = () => {
		navigate("/", { replace: true });
	};

	return (
		<Box className="animate__animated animate__fadeIn">
			<Box mb={4}>
				<Typography variant="h4" my={2}>
					Account Verification Pending!
				</Typography>
				<Typography my={2}>Your application ID: {loggedInUser.onfindoApplicationID}</Typography>
				<Typography my={2}>
					Your application has been submitted successfully. It is being verified and we will notify you once
					the application is processed.
				</Typography>
				<Button fullWidth variant="contained" onClick={continueToHome}>
					Back to Home
				</Button>
			</Box>
		</Box>
	);
};

const ComingSoon = ({ onStepComplete }) => {
	return (
		<Box className="animate__animated animate__fadeIn">
			<Box mb={4}>
				<Typography variant="h4" my={2}>
					This section is under maintenance!
				</Typography>
				<Typography variant="p">Please try again shortly</Typography>
			</Box>
			<Button fullWidth variant="contained" onClick={onStepComplete}>
				Ok
			</Button>
		</Box>
	);
};

const VerifyAccount = () => {
	const navigate = useNavigate();
	const { loggedInUser } = useSessionState();

	const [errorMessage, setErrorMessage] = useState("");
	const [currentStep, setCurrentStep] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		console.log("m loaded again", loggedInUser);

		if (!loggedInUser || loggedInUser.verified) {
			if (loggedInUser && loggedInUser.type === "admin") navigate(admin_link("/"), { replace: true });
			else navigate("/", { replace: true });
		}
	}, [loggedInUser]);

	useEffect(() => {
		checkIsEmailVerified().then(verifed => {
			if (verifed) {
				if (loggedInUser?.profileSubmitted) setCurrentStep("pending");
				else setCurrentStep(2);
			} else {
				setCurrentStep(1);
			}
			setIsLoading(false);
		});
	}, []);

	const moveToNextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const beginOnboarding = type => {
		if (type === "farmer") {
			setCurrentStep("farmerOnboarding");
		} else {
			setCurrentStep("comingsoon");
		}
	};

	return (
		<>
			<Box className="fxt-template-animation nft-login-template">
				<Box className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-xl-6 col-lg-7 col-sm-12 col-12 fxt-bg-color">
							<div className="fxt-content">
								<div className="fxt-home-link">
									<RouterLink to="/">
										<i className="fas fa-home"></i> Home
									</RouterLink>
								</div>
								{isLoading ? (
									<Box textAlign="center">
										<CircularProgress />
									</Box>
								) : (
									<>
										{currentStep === 0 && <Step0Block onStepComplete={moveToNextStep} />}
										{currentStep === 1 && (
											<Step1Block onStepComplete={moveToNextStep} onStepError={setErrorMessage} />
										)}
										{currentStep === 2 && <Step2aBlock onStepComplete={beginOnboarding} />}
										{/* {currentStep === 3 && <Step2bBlock onStepComplete={moveToNextStep} />} */}
										{/* {currentStep === 4 && <Step3Block onStepComplete={moveToNextStep} />} */}
										{currentStep === "farmerOnboarding" && (
											<FarmerSignup onStepComplete={() => setCurrentStep("thanks")} />
										)}
										{currentStep === "comingsoon" && (
											<ComingSoon onStepComplete={() => setCurrentStep(2)} />
										)}
										{currentStep === "thanks" && <ThankyouBlock />}
										{currentStep === "pending" && <PendingBlock />}
										{errorMessage && (
											<Alert
												className="animate__animated animate__fadeIn"
												sx={{ mt: 3 }}
												severity="error"
											>
												{errorMessage}
											</Alert>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</Box>
			</Box>
		</>
	);
};

export default VerifyAccount;
