const attributes = [
  {
    "title": "Physical Asset No.",
    "key": "item_sku"
  },
]

const metadata_object = {
  "title": "{{item_name}}",
  "type": "object",
  "description": "{{description}}",
  "external_url": "{{external_url}}",
  "name": "{{item_name}}",
  "properties": {
    "name": {
      "type": "string",
      "description": "{{item_name}}"
    },
    "description": {
      "type": "string",
      "description": "{{description}}"
    }
  },
  "attributes": [],
}

attributes.map(item => metadata_object.attributes.push(
  [{
    [`{{#if ${item.key} != ''}}`]: {
      "key": item.key,
      "trait_type": item.title,
      "value": `{{${item.key}}}`
    }
  }]
))

export default metadata_object;