import { Link as RouterLink } from "react-router-dom";
export const BlockSell = () => (
	<>
		{/* <!-- earn area --> */}
		<section className="earn-area page-background" style={{ paddingBottom: "100px" }}>
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="earn-main-box">
							<div className="earn-images">
								<img src="/assets/images/store.png" alt="" />
							</div>
							<div className="earn-info">
								<h6>Projects</h6>
								<h2>Unleash your growth potential – Right Now!</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
								<p className="theme-description">
									For Agri-business project owners who are looking to scale, create more value and contribute to food security. We welcome you to list your project!
								</p>
								<div className="earn-btn">
									<RouterLink to="/profile/projects/add" className="theme-btn">
										Start Listing <i className="fas fa-arrow-right"></i>
									</RouterLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!-- earn area --> */}
	</>
);
