import { CircularProgress, Grid, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import TokenAttribute from "src/components/collection/TokenAttribute";
import Loading from "src/components/Loading";
import { cachedVersion } from "src/helpers/file.helper";
import { numberFormat } from "src/helpers/number.helper";
import { useGlobalState, useSessionState, useProjectsState, useCategoryState, useCollectionState } from "src/state";

const ProjectItem = ({ data }) => {
	const { promised: loadingCategories, getCategory } = useCategoryState();
	const { promised: loadingCollections, getProjectCollections } = useCollectionState();

	const [projectCollections, setProjectCollections] = useState([]);

	useEffect(() => {
		if (!loadingCollections) {
			const collections = getProjectCollections(data.id);
			setProjectCollections([...collections]);
		}
	}, [loadingCollections]);

	const tokensCount = () => {
		//ToDo:: When the same tokens repeated under multiple campaigns then how to calculate this?
		let count = 0;
		projectCollections.map(collection => (count += collection.totalItems));

		return count;
	};

	const tokensSold = () => {
		//ToDo:: check if the token owner is not same as token minter, then marked it as sold
		return 0;
	};

	const capitalRequest = () => {
		if (!projectCollections.length) return "-";
		const collection = projectCollections[projectCollections.length - 1];

		return numberFormat(collection.metadata?.capital_request, 0) ?? "-";
	};

	return (
		<div className="discover-main-box">
			<div className="user-store-item">
				<div className="user-store-media">
					{data.project_image ? (
						<img src={cachedVersion(data.project_image)} />
					) : (
						<img src="/assets/images/no-orders.png" alt="" />
					)}
				</div>
				<div className="user-store-info">
					<h3 className="user-theme-title">
						{data.project_name}{" "}
						<span>
							({data.collection_count ? parseInt(data.collection_count) : 0}{" "}
							{process.env.REACT_APP_COLLECTION_TITLE}s)
						</span>
					</h3>
					<p className="theme-description">
						{loadingCategories ? <CircularProgress size={12} /> : getCategory(data.category).name}
					</p>
					<Grid gap={2} my={2} container>
						<Grid item xs={3}>
							<TokenAttribute
								style="attributeBlockAlt"
								enableTooltip={false}
								title="Items Sold"
								value="0"
							/>
						</Grid>
						<Grid item xs={3}>
							<TokenAttribute
								style="attributeBlockAlt"
								enableTooltip={false}
								title="Tokens Minted"
								value={tokensCount()}
							/>
						</Grid>
						<Grid item xs={3}>
							<TokenAttribute
								style="attributeBlockAlt"
								enableTooltip={false}
								title="Capital Request"
								value={capitalRequest()}
							/>
						</Grid>
					</Grid>
					<Grid container gap={2} my={2}>
						<Grid item>
							<Link to={`edit/${data.id}`}>
								<Button variant="contained">Edit Project</Button>
							</Link>
						</Grid>
						<Grid item>
							<Link to={`${data.id}/collections`}>
								<Button variant="contained" color="success">
									Manage {process.env.REACT_APP_COLLECTION_TITLE}s
								</Button>
							</Link>
						</Grid>
					</Grid>
				</div>
				<div>
					<PieChart
						style={{ height: "100px" }}
						data={[
							{ title: "Raised", value: tokensSold(), color: "#E38627" },
							{ title: "Target", value: tokensCount(), color: "#CCC" },
						]}
						lineWidth={30}
						rounded
					/>
				</div>
			</div>
		</div>
	);
};

const Projects = () => {
	const { setValue } = useGlobalState();
	const { profile } = useSessionState();
	const { promised: loadingProjects, userProjects } = useProjectsState();

	const navigate = useNavigate();

	const myProjects = profile ? userProjects(profile.userID) : [];

	const handleCreateProject = () => {
		// if (!profile.verified) {
		// 	setValue("showVerificationMessage", true);
		// 	return;
		// }

		navigate("/profile/projects/add");
	};

	return (
		<>
			<section className="min-item-area page-paddings">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="section-title text-center">
								<h2 data-watermark="Items">My Projects</h2>
								<div className="em_bar">
									<div className="em_bar_bg"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="user-panel-main">
								<div className="user-store-area">
									{loadingProjects ? (
										<div className="user-no-record-found">
											<Loading />
										</div>
									) : (
										<div className="row">
											<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
												{myProjects.length > 0 ? (
													myProjects.map((item, index) => (
														<ProjectItem data={item} key={index} />
													))
												) : (
													<div className="user-no-record-found">
														<div className="user-no-record-icon">
															<i className="fas fa-store-alt"></i>
														</div>
														<h3 className="user-title">You Have No Projects.</h3>
														<p className="theme-description">
															If you are a project owner and looking to start a campaign
															on {process.env.REACT_APP_SITENAME}, start now with adding
															your project!
														</p>
														<div className="user-btn">
															<a onClick={handleCreateProject} className="theme-btn">
																List Your Project
															</a>
														</div>
													</div>
												)}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Projects;
