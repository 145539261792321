import { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import MultilineField from "src/components/MultilineField";

const PropertyEditor = ({ properties, onSave }) => {
	const [listItems, setListItems] = useState(properties);

	const handleSave = () => onSave(listItems);
	const updateItems = (items) => {
		setListItems(items);
	}

	return (
		<Box>
			<Typography variant="body2">
				Properties show up underneath your item, are clickable, and can be filtered in your collection's
				sidebar.
			</Typography>
			<MultilineField items={properties} onChange={updateItems} />
			<Box mt={3}>
                <Button variant="contained" size="large" onClick={handleSave} fullWidth>
                    Save
                </Button>
            </Box>
		</Box>
	);
};

export default PropertyEditor;
