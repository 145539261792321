import { useEffect } from "react";
import { createState, useState } from "@hookstate/core";
import { fetchUserTokens } from "src/db/firebase/collections";

const tokensState = createState({});

export function useUserTokenState(userID) {
	const state = useState(tokensState);
	const loading = useState(false);

	const loadTokens = async () => {
		try {
			loading.set(true);

			const loaded = state[userID]?.get();

			if (!loaded) {
				const tokens = await fetchUserTokens(userID);
				const obj = {};
				obj[userID] = tokens ?? [];
				state.merge(obj);
			}

			loading.set(false);
		} catch (e) {}
	};

	useEffect(() => {
		loadTokens();
	}, []);

	return {
		get promised() {
			return loading.value;
		},
		get tokens() {
			const t = state[userID]?.value;
			return t ? t : [];
		},
	};
}
