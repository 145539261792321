import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Box, Container, Typography, Grid } from "@material-ui/core";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { useCollectionState, useTokenState, useGlobalState, useErrorState, useProjectsState } from "src/state";
import ListView from "src/components/ListingTable";
import Toolbar from "src/components/collection/TokensToolbar";
import TokenActions from "src/components/collection/TokenActions";
import { useParams } from "react-router";
import Modal from "src/components/Modal";
import ItemForm from "src/components/collection/ItemForm";
import TransferForm from "src/components/collection/TransferForm";
import CollectionSettingsForm from "src/components/collection/CollectionSettingsForm";
import Loading from "src/components/Loading";
import { mint, loadAllTokens, transferToken } from "src/plugins/Ethereum";
import { readFileByHash } from "src/plugins/ipfs";
import { admin_link, hash_to_ipfs_link } from "src/helpers/links.helper";
import { isValidAddress } from "src/helpers/blockchain.helper";
import { mintToken } from "src/helpers/collections.helper";
import moment from "moment";

const CollectionTokens = () => {
	const { id: collectionID } = useParams();
	const blockchainInfo = useContext(BlockchainContext);
	const { promised: isCollectionLoading, getCollection, updateSettings, archiveCollection } = useCollectionState();
	const { promised: isProjectsLoading, getProject } = useProjectsState();
	const { toggleListingModalVisible, isTransferModalVisible, toggleTransferModalVisible, transferForToken } =
		useGlobalState();

	const navigate = useNavigate();

	const {
		promised: isTokenLoading,
		tokens,
		createToken,
		updateToken,
		updateOwner,
		deleteFailedToken,
		getTokenBySku,
		getToken,
	} = useTokenState(collectionID);

	const { setErrorMessage } = useErrorState();

	const [showAddTokenForm, setShowAddTokenForm] = useState(false);
	const [showErrorMinting, setShowErrorMinting] = useState(false);
	const [showCollectionConfig, setShowCollectionConfig] = useState(false);
	const [filterKeyword, setFilterKeyword] = useState("");
	const [isSavingData, setIsSavingData] = useState(false);

	const validateSkuBeforeAdd = item_sku => {
		const exists = getTokenBySku(collectionID, item_sku);
		if (exists) return false;

		return true;
	};

	const collection = getCollection(collectionID);

	const handleAddToken = async () => {
		if (blockchainInfo && blockchainInfo.account) {
			// check if account have minting right, for now only owner can mint
			if (blockchainInfo.account === collection.owner) setShowAddTokenForm(true);
			else setShowErrorMinting(true);
		} else if (blockchainInfo) {
			blockchainInfo.connectToBlockchain();
		}
	};
	const handleConfig = () => {
		setShowCollectionConfig(true);
	};
	const handleRefresh = () => {
		if (blockchainInfo && blockchainInfo.account) {
			setIsSavingData(true);
			loadAllTokens(collection.contract_type, collection.address)
				.then(tokens => {
					if (tokens.length > 0) {
						tokens.map(async token => {
							// check if token ID already in DB
							const tokenExists = await getToken(collectionID, token.tokenID);

							if (!tokenExists || !tokenExists.tokenID) {
								const metaHash = token.tokenMetaHash;

								//fetch meta data
								const metadata = await readFileByHash(metaHash);
								let sku = "";
								if (metadata?.attributes) {
									metadata.attributes.map(attribute => {
										if (attribute.trait_type === "Item Code") sku = attribute.value;
									});
								}
								if (sku != "")
									await createToken(collectionID, {
										token_id: token.tokenID,
										sku,
										image_url: metadata.image,
										metadata_url: hash_to_ipfs_link(metaHash),
										minter: token.minter,
										owner: token.owner,
									});
							}
						});
					}
				})
				.finally(() => {
					setIsSavingData(false);
				});
		}
	};
	const handleModalClose = () => {
		showAddTokenForm && setShowAddTokenForm(false);
		showErrorMinting && setShowErrorMinting(false);
		showCollectionConfig && setShowCollectionConfig(false);
		if (isTransferModalVisible) toggleTransferModalVisible();
	};
	const filterTokens = keyword => setFilterKeyword(keyword);
	const handleCreateItem = async data => {
		setShowAddTokenForm(false);
		setIsSavingData(true);

		await mintToken(data, collection, blockchainInfo, createToken, updateToken, deleteFailedToken);

		setIsSavingData(false);
	};

	const handleTransferToken = async data => {
		if (isTransferModalVisible) toggleTransferModalVisible();

		//check if the address provided is valid
		if (!isValidAddress(data.walletAddress)) {
			setErrorMessage("This is not a valid wallet address");
			return;
		}

		setIsSavingData(true);

		const tokenInfo = transferForToken;

		const { collectionID, tokenID, dbID } = tokenInfo;

		transferToken({
			contractType: collection.contract_type,
			contractAddress: collection.address,
			tokenID,
			trasferToWalletAddress: data.walletAddress,
		})
			.then(() => {
				updateOwner(collectionID, dbID, {
					owner: data.walletAddress,
				});

				setIsSavingData(false);
			})
			.catch(e => {
				console.log(e);
				setErrorMessage("Not able to transfer this NFT, please try again later");
				setIsSavingData(false);
			});
	};

	const handleSaveCollectionSettings = async data => {
		if (showCollectionConfig) setShowCollectionConfig(false);

		if (data.archived) {
			await archiveCollection(collectionID);
			navigate(admin_link("/collections"));
			return;
		}

		updateSettings(collectionID, data);
	};

	const project = getProject(collection.projectID);

	return (
		<>
			<Box
				sx={{
					backgroundColor: "background.default",
					minHeight: "100%",
					py: 3,
				}}
			>
				<Container maxWidth={false}>
					{isSavingData && <Loading />}
					{isCollectionLoading || isTokenLoading ? (
						<Loading />
					) : (
						<>
							<Toolbar
								collection={collection}
								project={project}
								onPressAddButton={handleAddToken}
								onPressConfigButton={handleConfig}
								onPressRefreshButton={handleRefresh}
								onSearch={filterTokens}
							/>
							{collection.campaignCommencementDate > 0 && (
								<Box sx={{ background: "#f1f1f1" }} p={2} mt={3}>
									<Typography variant="h4" mb={2}>
										{process.env.REACT_APP_COLLECTION_TITLE} Details
									</Typography>
									<Grid container spacing={2}>
										<Grid item sx={{ flex: 1, minWidth: "200px" }}>
											<strong>Contract Start Date</strong>
											<br />
											{moment.unix(collection.campaignCommencementDate).format("DD MMM YYYY")}
										</Grid>
										{collection.metadata && collection.metadata.capital_request && (
											<Grid item sx={{ flex: 1, minWidth: "200px" }}>
												<strong>Capital Request</strong>
												<br />
												{collection.metadata.capital_request} USD
											</Grid>
										)}
										{collection.metadata && collection.metadata.sales_return_on_capital && (
											<Grid item sx={{ flex: 1, minWidth: "200px" }}>
												<strong>Sales Return on Capital</strong>
												<br />
												{collection.metadata.sales_return_on_capital}%
											</Grid>
										)}
										{collection.metadata && collection.metadata.sales_return_period && (
											<Grid item sx={{ flex: 1, minWidth: "200px" }}>
												<strong>Sales Return Period</strong>
												<br />
												{collection.metadata.sales_return_period} months
											</Grid>
										)}
										{collection.offeringSize && (
											<Grid item sx={{ flex: 1, minWidth: "200px" }}>
												<strong>Offering Size</strong>
												<br />
												{collection.offeringSize} USD
											</Grid>
										)}
									</Grid>
								</Box>
							)}
							<Box sx={{ pt: 3 }}>
								<ListView
									items={tokens
										.filter(token => {
											const filterBy = new RegExp(filterKeyword, "i");
											return filterKeyword === "" || token.itemName.search(filterBy) >= 0;
										})
										.sort((a, b) => (a.tokenID > b.tokenID ? 1 : b.tokenID > a.tokenID ? -1 : 0))}
									fields={[
										{ key: "itemName", title: "Item Name" },
										{ key: "minter", title: "Minted By" },
										{ key: "owner", title: "Owned By" },
									]}
									linkTitleTo="metadataUrl"
									isSelectable={false}
									showLoadingIfEmpty="alwaysShow"
									customLoadingComponent={props => (
										<TokenActions
											contractType={collection.type}
											collectionID={collectionID}
											chainID={collection.chainID}
											connectedWithAccount={blockchainInfo.account}
											allowListing={
												(!collection.campaignCommencementDate ||
													moment
														.unix(collection.campaignCommencementDate)
														.isSameOrAfter(moment())) &&
												!collection.archived
											}
											{...props}
										/>
									)}
									customClasses={[{ burntToken: "burnt" }]}
								/>
							</Box>
						</>
					)}
				</Container>
			</Box>
			<Modal
				open={showAddTokenForm}
				onClose={handleModalClose}
				title="Add Item Details"
				content={
					<ItemForm
						toValidateSku={validateSkuBeforeAdd}
						onCreateItem={handleCreateItem}
						defaultData={
							collection && collection.itemCreationData?.item_name ? collection.itemCreationData : null
						}
						collectionData={collection}
						projectData={project && project.project_name ? project : null}
					/>
				}
			/>
			<Modal
				open={showErrorMinting}
				onClose={handleModalClose}
				title="Sorry!"
				summary="You are not authorized to mint tokens for this collection. Switch to another account."
				showOkButton={true}
			/>
			<Modal
				open={showCollectionConfig}
				onClose={handleModalClose}
				title="Collection Settings"
				content={
					collection ? (
						<CollectionSettingsForm
							defaultData={{
								...collection,
							}}
							onSave={handleSaveCollectionSettings}
						/>
					) : null
				}
			/>
			<Modal
				open={isTransferModalVisible}
				onClose={handleModalClose}
				title="Transfer NFT"
				content={<TransferForm onTransfer={handleTransferToken} />}
			/>
		</>
	);
};

export default CollectionTokens;
