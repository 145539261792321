import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import {
	useListingState,
	useTokenState,
	useCollectionState,
	useErrorState,
	useSettingState,
	useCategoryState,
	useGlobalState,
	useSessionState,
} from "src/state";
import { Box, Typography } from "@material-ui/core";
import {
	addressLinkURL,
	contractLinkURL,
	networkName,
	shortAccount,
	tokenLinkURL,
	transactionLinkURL,
	tryAddToWallet,
} from "src/helpers/blockchain.helper";
import Loading from "src/components/Loading";
import moment from "moment";

import { BlockchainContext } from "src/providers/BlockchainProvider";
import { approveFunds, buyToken, placeBid, gotSufficientBalance, pickAuctionWinner } from "src/plugins/Ethereum";
import { updateCollectionToken } from "src/db/firebase/collections";
import ActivityBlock from "src/components/frontend/partials/ActivityBlock";
import ListingViewOnBlock from "src/components/frontend/partials/ListingViewOnBlock";
import { ViewIPFSMetaIcon } from "src/components/frontend/icons";
import BidForm from "src/components/frontend/modals/BidForm";
import Modal from "src/components/Modal";
import TokenAttribute from "src/components/collection/TokenAttribute";
import { cachedVersion, convertBufferToBlob, uploadFileToStorage } from "src/helpers/file.helper";
import ListingBuyBlock from "src/components/frontend/partials/ListingBuyBlock";
import { attributeValueFormatter } from "src/helpers/string.helper";
import { sortByArray } from "src/helpers/array.helper";
import { numberFormat } from "src/helpers/number.helper";
import { ipfs_link_to_hash, storage_link } from "src/helpers/links.helper";

const PayoutSchedule = ({ data }) => {
	const [payoutSchedule, setPayoutSchedule] = useState("");

	useEffect(() => {
		let schedule = "";
		data.map(item => (item.key === "payout_schedule" ? (schedule += item.value) : null));

		setPayoutSchedule(schedule);
	}, [data]);

	return payoutSchedule ? (
		<Box my={2}>
			<Typography variant="h2" my={1}>
				Payback Schedule
			</Typography>
			<Typography className="theme-description" sx={{ whiteSpace: "pre-line" }}>
				{payoutSchedule}
			</Typography>
		</Box>
	) : null;
};

const Listing = () => {
	const { cid: collectionID, id: tokenID } = useParams();
	const blockchainInfo = useContext(BlockchainContext);

	const {
		promised: isListingLoading,
		getListing,
		getListingByTokenID,
		getListingIDForToken,
		updateListing,
		addListingOffer,
	} = useListingState();

	const { promised: isCategoriesLoading, getCategory, categories } = useCategoryState();

	const { promised: isTokenLoading, getToken } = useTokenState(collectionID);
	const { promised: isCollectionLoading, getCollection } = useCollectionState();
	const { getMarketplaceAddress } = useSettingState();
	const { profile } = useSessionState();

	const { setErrorMessage } = useErrorState();

	const location = useLocation();
	const { setRedirectToAfterConnect, setValue } = useGlobalState();

	const token = getToken(collectionID, tokenID);
	const navigate = useNavigate();

	const collection = getCollection(collectionID);

	const marketplaceAddress = blockchainInfo ? getMarketplaceAddress(blockchainInfo.networkId) : null;

	const [isInProgress, setIsInProgress] = useState(false);
	const [metadata, setMetadata] = useState(null);
	const [showBidForm, setShowBidForm] = useState(false);

	const connect = () => {
		//save the current URL to state
		//to redirect user back to this page
		setRedirectToAfterConnect(location.pathname);

		if (!profile) {
			navigate("/login");
			return;
		}

		// if (!profile.verified) {
		// 	setValue("showVerificationMessage", true);
		// 	return;
		// }

		if (!blockchainInfo.account) {
			navigate("/connect-wallet#buy");
			return;
		}

		navigate("/profile/settings");
	};

	const handleBuyNow = async () => {
		if (isInProgress) return;

		setIsInProgress(true);

		let check = null;
		try {
			check = await gotSufficientBalance(minBidAmount, listing.currency, collection.chainID);
		} catch (e) {
			console.log(e);
		}

		if (!check) {
			setErrorMessage(
				`you do not have sufficient balance in your wallet. Please add in atleast ${numberFormat(
					listing.amount,
					0
				)} ${listing.currency}`
			);
			setIsInProgress(false);
			return;
		}

		//get buyer's approval to deduct the currency from wallet ballance
		const approved = await approveFunds(marketplaceAddress, listing.amount, listing.currency, collection.chainID);

		//const approved = true;

		if (approved) {
			//trigger payment
			buyToken({
				contractType: collection.contract_type,
				contractAddress: collection.address,
				tokenID: listing.tokenID,
				amount: listing.amount,
				currency: listing.currency,
				marketplaceAddress,
				royaltyPercentage: collection.royalty,
				networkID: collection.chainID,
			})
				.then(transactionHash => {
					markListingAsPurchased(transactionHash, blockchainInfo.account);
				})
				.catch(e => {
					console.log(e);
					setErrorMessage(`Sorry! Unable to complete transaction, please try again`);
				})
				.finally(() => {
					setIsInProgress(false);
				});
		} else {
			setErrorMessage(`Sorry! Unable to process this transaction at this moment. Please try again later.`);
			setIsInProgress(false);
		}
	};

	const handlePlaceBid = async () => {
		if (isInProgress) return;

		setIsInProgress(true);

		let check = null;
		try {
			check = await gotSufficientBalance(minBidAmount + 0.01, listing.currency, collection.chainID);
		} catch (e) {
			console.log(e);
		}

		if (!check) {
			setErrorMessage(
				`you do not have sufficient balance in your wallet. Please add in atleast ${numberFormat(
					listing.amount + 0.01,
					2
				)} ${listing.currency}`
			);
			setIsInProgress(false);
			return;
		}

		setShowBidForm(true);
	};

	const handlePickWinner = async () => {
		setIsInProgress(true);
		try {
			pickAuctionWinner({
				auctionAddress: listing.auctionAddress,
				networkID: collection.chainID,
			})
				.then(({ transactionHash, winnerAddress }) => {
					markListingAsPurchased(transactionHash, winnerAddress);
				})
				.catch(error => {
					setErrorMessage(`Sorry! Transaction has been rejected. Please try again`);
				})
				.finally(() => {
					setIsInProgress(false);
				});
		} catch (e) {
			console.log(e);
		}
	};

	const processBid = async ({ amount }) => {
		setShowBidForm(false);

		if (!amount || amount <= minBidAmount) {
			setErrorMessage("Please select a valid bid amount to place your Bid");
			setIsInProgress(false);
			return;
		}

		setIsInProgress(true);

		try {
			//get buyer's approval to deduct the currency from wallet ballance
			const approved = await approveFunds(listing.auctionAddress, amount, listing.currency, collection.chainID);

			if (approved) {
				placeBid({
					auctionAddress: listing.auctionAddress,
					amount: amount,
					currency: listing.currency,
					networkID: collection.chainID,
				})
					.then(transactionHash => {
						markListingOffer(transactionHash, { amount });
					})
					.catch(e => {
						console.log(e);
						setErrorMessage(`Sorry! Unable to complete transaction, please try again`);
					})
					.finally(() => {
						setIsInProgress(false);
					});
			} else {
				setErrorMessage(`Sorry! Unable to process this transaction at this moment. Please try again later.`);
				setIsInProgress(false);
			}
		} catch (err) {
			setIsInProgress(false);
		}
	};

	const markListingAsPurchased = (transactionHash, buyerAddress) => {
		updateListing(listing.listingID, {
			purchased: true,
			purchasedBy: buyerAddress,
			purchasedOn: new Date().getTime(),
			purchaseTx: transactionHash,
		});

		updateCollectionToken(collectionID, tokenID, {
			owner: buyerAddress,
			user_id: profile.userID,
		});
	};

	const markListingOffer = (transactionHash, offer) => {
		// add this offer to collection under the listing
		const offerData = {
			amount: offer.amount,
			currency: listing.currency,
			from: blockchainInfo.account,
			tx: transactionHash,
		};
		addListingOffer(listing.listingID, offerData);

		// update the highest bid value
		updateListing(listing.listingID, {
			highest_bid: offer.amount,
		});
	};

	const handleModalClose = () => {
		showBidForm && setShowBidForm(false);
		isInProgress && setIsInProgress(false);
	};

	useEffect(() => {
		try {
			if (token && token.metadataUrl && !metadata) {
				fetch(storage_link(token.metadataUrl))
					.then(response => response.json())
					.then(data => {
						//upload to storage for now
						// const jsonBuffer = Buffer.from(JSON.stringify(data));
						// const jsonBlob = convertBufferToBlob(jsonBuffer);
						// uploadFileToStorage(ipfs_link_to_hash(token.metadataUrl), jsonBlob, 'json');

						if (data) {
							const attributes = data.attributes
								? data.attributes.sort((a, b) => sortByArray(a.key, b.key, attributesAsBlocks))
								: [];
							data.attributes = attributes;
							setMetadata(data);
							//ToDo: save to db
						} else setMetadata([]);
					})
					.catch(() => {});
			}
		} catch (e) {}
	}, [token, metadata]);

	if (!token || token.burnt || !collection || collection.archived) {
		navigate("/404", { replace: true });
		return null;
	}

	const previousListings = getListingByTokenID(collectionID, tokenID);
	const listingID = getListingIDForToken(collectionID, tokenID, true);
	const listing = listingID ? getListing(listingID) : {};

	const listingCategory = collection.categoryID && !isCategoriesLoading ? getCategory(collection.categoryID) : null;

	const attributesAsBlocks = [
		"owner_name",
		"project_address",
		"project_country",
		"project_name",
		"distribution_partners",
		"project_tagline",
		"registration_cert_updated",
		"sales_return_on_capital",
		"item_sku",
		"digital_asset_number",
		"commencement_date",
		"expiry_date",
		//"capital_request",
		//"offering_size",
		//"licenses",
		"tax_license_no",
		"prove_assets_registration",
		//"target_irr",
	];

	const minBidAmount = listing
		? listing.highest_bid
			? Math.round(listing.highest_bid * 100) / 100
			: listing.amount
		: 0;

	const handleBuyButton = () => {
		if (!blockchainInfo.account || !profile || profile.username === "") connect();
		else if (listing.listingType === "sale") handleBuyNow();
		else handlePlaceBid();
	};

	return isListingLoading || isTokenLoading || isCollectionLoading ? (
		<Loading />
	) : (
		<>
			<Box className="browse-detail-area page-paddings">
				<Box className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
							<Box
								className="browse-detail-info"
								sx={{ display: { xs: "block", md: "none" }, textAlign: "center" }}
							>
								<Typography variant="h1">
									{collection.name} ({token.itemName})
								</Typography>
								<Box my={2}>
									<button className="theme-btn" onClick={() => window["scrollToBlock"]("#buybox")}>
										Buy Now
									</button>
								</Box>
							</Box>
							<div className="browse-detail-images text-center">
								<div className="browse-detail-large">
									<Box className="item-image">
										{token.animationUrl && token.animationFileType === "video" ? (
											<video width="100%" height="100%" autoPlay loop muted controls>
												<source
													src={cachedVersion(token.animationUrl, "video", 600)}
													type="video/mp4"
												/>
											</video>
										) : (
											<img
												src={
													token.animationUrl
														? cachedVersion(token.animationUrl)
														: cachedVersion(token.imageUrl)
												}
											/>
										)}
									</Box>
								</div>
								<Typography variant="body2" my={1}>
									ERC{collection.contractType} NFT minted in{" "}
									{moment(collection.createdAt).format("MMM YYYY")} on{" "}
									{networkName(collection.chainID)}
								</Typography>
								<Box className="browse-detail-info" sx={{ display: { xs: "block", md: "none" } }}>
									{metadata && metadata.description && (
										<Typography className="theme-description" sx={{ whiteSpace: "pre-line" }}>
											{metadata.description}
										</Typography>
									)}
								</Box>
								<div className="browse-thumblain">
									{metadata && metadata.attributes && metadata.attributes.length > 0 && (
										<ul className="clearfix">
											{metadata.attributes
												.filter(
													item =>
														process.env.REACT_APP_SITENAME !== "FoodStarter" ||
														attributesAsBlocks.includes(item.key)
												)
												.map((attribute, index) =>
													attribute.trait_type && attribute.value != "" ? (
														<li key={index}>
															<TokenAttribute
																title={attribute.trait_type}
																value={attributeValueFormatter(
																	attribute.value,
																	attribute.key
																)}
															/>
														</li>
													) : null
												)}
										</ul>
									)}
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
							<div className="browse-detail-info">
								<Box sx={{ display: { xs: "none", md: "block" } }}>
									<Typography variant="h1">
										{collection.name} ({token.itemName})
									</Typography>
									{collection.description && (
										<Typography className="theme-description" sx={{ whiteSpace: "pre-line" }}>
											{collection.description}
										</Typography>
									)}
									{metadata && metadata.description && (
										<Typography className="theme-description" sx={{ whiteSpace: "pre-line" }}>
											{metadata.description}
										</Typography>
									)}
								</Box>
								{/* {metadata && <PayoutSchedule data={metadata.attributes} />} */}
								<Box id="buybox">
									{listing?.listingType && (
										<ListingBuyBlock
											listing={listing}
											minBidAmount={minBidAmount}
											isInProgress={isInProgress}
											isOwner={blockchainInfo.account === token.owner}
											isWrongChain={
												blockchainInfo.networkId &&
												collection.chainID !== blockchainInfo.networkId
											}
											onClickBuyButton={handleBuyButton}
											onClickApproveButton={handlePickWinner}
										/>
									)}
									{blockchainInfo.networkId && collection.chainID !== blockchainInfo.networkId && (
										<div className="browse-bid-footer text-center">
											<p className="theme-description">
												You are connected to wrong network!
												<br />
												<strong>{`Switch to ${networkName(collection.chainID)}`}</strong>
											</p>
										</div>
									)}
								</Box>
								<div className="browse-tag">
									<ul className="clearfix">
										{token.certificateUrl && (
											<li>
												<ListingViewOnBlock
													linkTo={token.certificateUrl}
													title="View Certificate on IPFS"
													//icon={ViewOnIFPSIcon}
												/>
											</li>
										)}
										{token.descriptionUrl && (
											<li>
												<ListingViewOnBlock
													linkTo={token.descriptionUrl}
													title="View Description on IPFS"
													icon={ViewIPFSMetaIcon}
												/>
											</li>
										)}
										<li>
											<ListingViewOnBlock
												linkTo={tokenLinkURL(collection.chainID, collectionID, tokenID)}
												title={`View on ${networkName(collection.chainID)}`}
												//icon={ViewOnChainIcon}
											/>
										</li>
										<li>
											<ListingViewOnBlock
												linkTo={token.imageIPFSUrl}
												title="View on IPFS"
												//icon={ViewOnIFPSIcon}
											/>
										</li>
										<li>
											<ListingViewOnBlock
												linkTo={token.metadataUrl}
												title="View IPFS Metadata"
												//icon={ViewIPFSMetaIcon}
											/>
										</li>
										<li>
											<ListingViewOnBlock
												title="Add to your Wallet"
												//icon={AddToWalletIcon}
												onClick={() => tryAddToWallet(collection)}
											/>
										</li>
									</ul>
								</div>
								<Box my={3}>
									<h2>Activity</h2>
									<Box mt={2} className="css-1zj86l">
										{listing && listing.purchased && (
											<ActivityBlock
												title="Purchased by "
												address={shortAccount(listing.purchasedBy)}
												addressLink={addressLinkURL(collection.chainID, listing.purchasedBy)}
												timestamp={moment(listing.purchasedOn).format(
													"MMM Do YYYY [at] h:mm a"
												)}
												amount={
													listing.listingType === "auction"
														? listing.highest_bid
														: listing.amount
												}
												currency={listing.currency}
												blockchainLink={transactionLinkURL(
													collection.chainID,
													listing.purchaseTx
												)}
											/>
										)}

										{listing &&
											listing.listingType === "auction" &&
											listing.offers &&
											[...listing.offers]
												.reverse()
												.map((offer, idx) => (
													<ActivityBlock
														key={idx}
														title={`Offer by`}
														address={shortAccount(offer.from)}
														addressLink="#"
														timestamp={moment(offer.createdAt).format(
															"MMM Do YYYY [at] h:mm a"
														)}
														amount={offer.amount}
														currency={listing.currency}
														blockchainLink={
															offer.tx
																? transactionLinkURL(collection.chainID, offer.tx)
																: null
														}
													/>
												))}

										{listing && listing.listingType && (
											<ActivityBlock
												title={`Listed for ${
													listing.listingType === "sale" ? "Sale" : "Auction"
												} by`}
												address={shortAccount(listing.creator) || " Owner"}
												addressLink={addressLinkURL(collection.chainID, listing.creator)}
												timestamp={moment(listing.createdAt).format("MMM Do YYYY [at] h:mm a")}
												amount={listing.amount}
												currency={listing.currency}
											/>
										)}

										{previousListings
											.filter(item => !listing || item.listingID !== listingID)
											.map((prevListing, index) => (
												<React.Fragment key={index}>
													<ActivityBlock
														title={
															prevListing.listingType === "auction"
																? "Won in Auction by"
																: "Purchased by"
														}
														address={shortAccount(prevListing.purchasedBy)}
														addressLink={addressLinkURL(
															collection.chainID,
															prevListing.purchasedBy
														)}
														timestamp={moment(prevListing.purchasedOn).format(
															"MMM Do YYYY [at] h:mm a"
														)}
														amount={
															prevListing.listingType === "auction"
																? prevListing.highest_bid
																: prevListing.amount
														}
														currency={prevListing.currency}
														blockchainLink={transactionLinkURL(
															collection.chainID,
															prevListing.purchaseTx
														)}
													/>
													<ActivityBlock
														title={`Listed for ${
															prevListing.listingType === "sale" ? "Sale" : "Auction"
														} by`}
														address={shortAccount(prevListing.creator) || " Owner"}
														addressLink={addressLinkURL(
															collection.chainID,
															prevListing.creator
														)}
														timestamp={moment(prevListing.createdAt).format(
															"MMM Do YYYY [at] h:mm a"
														)}
														amount={prevListing.amount}
														currency={prevListing.currency}
													/>
												</React.Fragment>
											))}

										<ActivityBlock
											title="Minted by"
											address={shortAccount(token.minter)}
											addressLink={contractLinkURL(collection.chainID, token.minter)}
											timestamp={moment(token.createdAt).format("MMM Do YYYY [at] h:mm a")}
											blockchainLink={tokenLinkURL(collection.chainID, collectionID, tokenID)}
										/>
									</Box>
								</Box>
							</div>
						</div>
					</div>
				</Box>
			</Box>
			<Modal
				open={showBidForm}
				onClose={handleModalClose}
				content={
					<BidForm
						minAmount={Math.round((minBidAmount + 0.01) * 100) / 100}
						currency={listing.currency}
						onPlaceBid={processBid}
					/>
				}
			/>
		</>
	);
};

export default Listing;
