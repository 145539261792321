import { useEffect, useRef, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ProjectItem from "src/components/frontend/ProjectItem";

const ProjectsContainer = styled("div")(({ theme }) => ({
  // padding: "50px 0",
}));

const Projects = ({ projects, title, ...props }) => {
  return (
    <ProjectsContainer>
      <Box>
        {title && <h2 style={{ marginBottom: "15px" }}>{title}</h2>}
        <Grid container spacing={3}>
          {projects.map((project, index) =>
            project ? (
              <ProjectItem
                key={project.id}
                project={project}
                gridOptions={{xs: 12, sm: 6, md: 3, lg: 3}}
              />
            ) : null
          )}
        </Grid>
        {/* <Box sx={{ textAlign: "center", my: 3 }}>
          {Projects.length > displayProjects.length && (
            <Button variant="contained" color="primary" onClick={loadMore}>
              Load More
            </Button>
          )}
        </Box> */}
      </Box>
    </ProjectsContainer>
  );
};

export default Projects;
