import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Container, Typography } from "@material-ui/core";
import Loading from "src/components/Loading";
import CollectionForm from "src/components/collection/CollectionForm";
import { admin_link } from "src/helpers/links.helper";
import { cerateAndDeployCollection } from "src/helpers/collections.helper";
import { useCollectionState, useProjectsState, useErrorState } from "src/state";

const AddCollecton = () => {
	const [isSavingData, setIsSavingData] = useState(false);
	const { promised: isCollectionLoading, getCollection, createCollection, removeCollection } = useCollectionState();

	const { incrementCollectionCount } = useProjectsState();
	const { setErrorMessage } = useErrorState();

	const navigate = useNavigate();

	const { collectionID } = useParams();

	const duplicateFrom = collectionID && !isCollectionLoading ? getCollection(collectionID) : null;

	const handleOnCreateCollection = async data => {
		setIsSavingData(true);

		cerateAndDeployCollection(data, createCollection, removeCollection)
			.then(() => {
				//update project collection count if, added to project
				if (data.projectID) {
					incrementCollectionCount(data.projectID);
				}

				//navigate back to collections page
				navigate(admin_link("/collections"));
			})
			.catch(error => {
				setErrorMessage("Error! Please check your data and try again.");

				console.log("error", error);
			})
			.finally(() => {
				setIsSavingData(false);
			});
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: "background.default",
					minHeight: "100%",
					py: 3,
				}}
			>
				<Container maxWidth={false}>
					<Typography variant="h2" color="textPrimary" gutterBottom>
						Add New {process.env.REACT_APP_COLLECTION_TITLE}
					</Typography>
					<Box sx={{ pt: 3 }}>
						{isSavingData && <Loading />}
						<Box sx={{ maxWidth: "800px" }}>
							{!isCollectionLoading && <CollectionForm onCreate={handleOnCreateCollection} defaultValues={duplicateFrom} />}
						</Box>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default AddCollecton;
