import { createState, useState } from "@hookstate/core";

// ToDo:: fetch from https://min-api.cryptocompare.com/data/price?fsym=WETH&tsyms=USD
// for each of the currencies and set the state

const currecyState = createState({
  ETHToUSD: 3303.9,
  TSTToUSD: 3303.9,
  USDTToUSD: 1,
  USDCToUSD: 1,
  MATICToUSD: 1.52,
  DERC20ToUSD: 1,
  BUSDToUSD: 1
});

export function useCurrencyState() {
  const state = useState(currecyState);

  return {
    get EthToUsd() {
      return state.ETHToUSD.get();
    },
    getExRateInUSD(currency) {
      switch (currency) {
        case "USDC":
          return state.USDCToUSD.get();
        case "USDT":
        case "BUSD":
          return state.USDTToUSD.get();
        case "WETH":
          return state.ETHToUSD.get();
        case "TST":
          return state.TSTToUSD.get();
        case "MATIC":
          return state.MATICToUSD.get();
        case "DERC20":
          return state.DERC20ToUSD.get();
        default:
          return state.USDTToUSD.get();
      }
    },
  };
}
