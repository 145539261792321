import { useState } from "react";
import PropTypes from "prop-types";
import {
	Avatar,
	Box,
	Card,
	Checkbox,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	// TablePagination,
	TableRow,
	Typography,
} from "@material-ui/core";
import moment from "moment";
import { LinkableExternal as Linkable } from "./Linkable";

const _ListingTable = ({
	items,
	fields,
	append,
	linkTitleTo,
	isSelectable,
	showLoadingIfEmpty,
	customLoadingComponent,
	customClasses,
	onRowClick,
	...rest
}) => {
	const [selectedItemIds, setSelectedItemIds] = useState([]);
	//const [limit, setLimit] = useState(10);
	//const [page, setPage] = useState(0);
	const limit = 100;

	const handleSelectAll = event => {
		let newSelectedItemIds;

		if (event.target.checked) {
			newSelectedItemIds = items.map(item => item.id);
		} else {
			newSelectedItemIds = [];
		}

		setSelectedItemIds(newSelectedItemIds);
	};

	const handleSelectOne = (event, id) => {
		const selectedIndex = selectedItemIds.indexOf(id);
		let newSelectedItemIds = [];

		if (selectedIndex === -1) {
			newSelectedItemIds = newSelectedItemIds.concat(selectedItemIds, id);
		} else if (selectedIndex === 0) {
			newSelectedItemIds = newSelectedItemIds.concat(selectedItemIds.slice(1));
		} else if (selectedIndex === selectedItemIds.length - 1) {
			newSelectedItemIds = newSelectedItemIds.concat(selectedItemIds.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedItemIds = newSelectedItemIds.concat(
				selectedItemIds.slice(0, selectedIndex),
				selectedItemIds.slice(selectedIndex + 1)
			);
		}

		setSelectedItemIds(newSelectedItemIds);
	};

	// const handleLimitChange = (event) => {
	//   setLimit(event.target.value);
	// };

	// const handlePageChange = (event, newPage) => {
	//   setPage(newPage);
	// };

	return (
		<Card {...rest}>
			<>
				<Box sx={{ minWidth: 1050 }}>
					<Table>
						<TableHead>
							<TableRow>
								{isSelectable && (
									<TableCell padding="checkbox">
										<Checkbox
											checked={selectedItemIds.length === items.length}
											color="primary"
											indeterminate={
												selectedItemIds.length > 0 && selectedItemIds.length < items.length
											}
											onChange={handleSelectAll}
										/>
									</TableCell>
								)}
								{fields.map((field, index) => (
									<TableCell key={index}>{field.title}</TableCell>
								))}
								{showLoadingIfEmpty && <TableCell></TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{items.slice(0, limit).map(item => (
								<TableRow
									hover
									key={item.id}
									selected={selectedItemIds.indexOf(item.id) !== -1}
									className={
										customClasses
											? customClasses
													.map(i => (item[i[Object.keys(i)[0]]] ? Object.keys(i)[0] : null))
													.join(" ")
											: ""
									}
									onClick={onRowClick ? () => onRowClick(item.id) : null}
								>
									{isSelectable && (
										<TableCell padding="checkbox">
											<Checkbox
												checked={selectedItemIds.indexOf(item.id) !== -1}
												onChange={event => handleSelectOne(event, item.id)}
												value="true"
											/>
										</TableCell>
									)}
									{fields.map((field, index) =>
										field.key === "itemName" ? (
											<TableCell key={index}>
												<Box
													sx={{
														alignItems: "center",
														display: "flex",
													}}
												>
													{item.imageUrl && (
														<Linkable
															isLinked={Boolean(linkTitleTo)}
															to={item[linkTitleTo]}
														>
															<Avatar src={item.imageUrl} sx={{ mr: 2 }}>
																{append ? append : ""}
																{item.itemName}
															</Avatar>
														</Linkable>
													)}
													<Linkable isLinked={Boolean(linkTitleTo)} to={item[linkTitleTo]}>
														<Typography color="textPrimary" variant="body1">
															{append ? append : ""}
															{item.itemName}
														</Typography>
													</Linkable>
												</Box>
											</TableCell>
										) : (
											<TableCell key={index}>
												{field.type === "date"
													? moment(item[field.key]).format("DD MMM YYYY")
													: field.type === "number"
													? (item[field.key] ? parseInt(item[field.key]) : '0')
													: item[field.key]}
											</TableCell>
										)
									)}
									{showLoadingIfEmpty && (
										<TableCell>
											{!item[showLoadingIfEmpty] &&
												(customLoadingComponent ? (
													customLoadingComponent(item)
												) : (
													<CircularProgress />
												))}
										</TableCell>
									)}
								</TableRow>
							))}
							{items.length === 0 && (
								<TableRow>
									<TableCell align="center" colSpan={fields.length}>
										<Typography color="textPrimary" variant="caption">
											No Items Found
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</Box>
			</>
			{/* <TablePagination
        component="div"
        count={items.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
		</Card>
	);
};

_ListingTable.propTypes = {
	items: PropTypes.array.isRequired,
};

export default _ListingTable;
