import { Box, Typography, Tooltip } from "@material-ui/core";
import SquareBlock from "src/components/frontend/SquareBlock";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
	attributeBlock: {
		padding: "10px !important",
		//border: "1px solid #CCC",
		background: "#eee",
		display: "flex",
		flexDirection: "column !important",
		justifyContent: "center",
		borderRadius: "20px",
		boxShadow: "var(--st--shadows-0)",
	},
	attributeContent: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		wordBreak: "break-word",
	},
	attributeBlockAlt: {
		padding: "10px !important",
		border: "2px solid #989898",
		borderRadius: "5px",
		color: "#FFF",
	},
}));

const TokenAttribute = ({ title, value, enableTooltip = true, style = null }) => {
	const classes = useStyles();

	return (
		<Box className={style ? classes[style] : classes.attributeBlock}>
			<SquareBlock>
				<div className={classes.attributeContent}>
					<Tooltip disableHoverListener={!enableTooltip} placement="top" title={value}>
						<Typography
							variant="h3"
							align="center"
							sx={{
								pb: 1,
								fontSize: style === "attributeBlockAlt" ? "18px" : "14px",
								maxHeight: "70px",
								overflow: style === "attributeBlockAlt" ? "inherit" : "hidden",
							}}
						>
							{value}
						</Typography>
					</Tooltip>
					<Typography
						variant="caption"
						align="center"
						sx={{
							lineHeight: "normal",
							fontSize: "11px",
						}}
						color={style === "attributeBlockAlt" ? "#989898" : "textSecondary"}
					>
						{title}
					</Typography>
				</div>
			</SquareBlock>
		</Box>
	);
};

export default TokenAttribute;
