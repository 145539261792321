import { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { Alert, Box, Button, Container, TextField, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import moment from "moment";
import { useSessionState, useGlobalState } from "src/state";
import MultilineField from "src/components/MultilineField";
import { uploadFile as uploadFileToCloudinary } from "src/plugins/Cloudinary";
import { asyncForEach } from "src/helpers/array.helper";

export default ({ onSave }) => {
	const { setProfile, profile, farmLandProfile, setFarmLandProfile } = useSessionState();
	const [isLoading, setIsLoading] = useState(true);

	const farms = useRef([]);

	useEffect(() => {
		setIsLoading(false);
		if (!farmLandProfile) return;

		if (profile && profile.accountType === "farmer" && !farms.current.length) {
			farms.current = farmLandProfile ?? [];
		}
	}, [profile, farmLandProfile]);

	const setFarms = newValues => {
		farms.current = newValues;
	};

	if (isLoading) return null;

	return (
		<Formik
			initialValues={{}}
			onSubmit={async (values, { setSubmitting }) => {
				// upload any selected files

				await asyncForEach(farms.current, async (farm, index) => {
					if (farm["title_paper-selected-file"]) {
						const url = await uploadFileToCloudinary(
							farm["title_paper-selected-file"],
							`farm-${profile.userID}-${moment().unix()}`
						);
						farms.current[index]["title_paper-file"] = url;
						delete farms.current[index]["title_paper-selected-file"];
					}
				});

				setFarmLandProfile(profile.userID, farms.current);

				setSubmitting(false);

				onSave && onSave();
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, dirty, isValid }) => (
				<form onSubmit={handleSubmit}>
					<MultilineField
						items={farms.current}
						onChange={setFarms}
						fields={[
							{
								name: "farm_type",
								title: "Farm Type",
								type: "select",
								options: [
									{ "key": "Crop", "title": "Crop Farm" },
									{ "key": "Pig", "title": "Pig Farm" },
									{ "key": "Poultry", "title": "Poultry" },
									{ "key": "Fish", "title": "Fish Farm" },
									{ "key": "Other", "title": "Other Farm" },
								]
							},
							{
								name: "soil_type",
								title: "Soil Type",
								type: "select",
								options: [
									{ "key": "Ultisols", "title": "Ultisols" },
									{ "key": "Alfisols", "title": "Alfisols" },
									{ "key": "Inseptisols", "title": "Inseptisols" },
									{ "key": "Vertisols", "title": "Vertisols" },
									{ "key": "Antisols", "title": "Antisols" },
									{ "key": "Oxisols", "title": "Oxisols" },
									{ "key": "Not Applicable", "title": "Not Applicable" }
								]
							},
							{
								name: "title_paper",
								title: "Title Paper Copy",
								type: "file",
							},
							{
								name: "address",
								title: "Address",
								type: "textarea"
							},
						]}
						addButtonText="Add another Farm"
						addButtonAlignment="left"
					/>

					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="theme-input-box text-center" style={{ marginTop: "30px" }}>
								<Button
									className="theme-btn"
									disabled={isSubmitting}
									size="large"
									type="submit"
									variant="contained"
								>
									Save Details
								</Button>
							</div>
						</div>
					</div>
				</form>
			)}
		</Formik>
	);
};
