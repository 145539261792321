import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import { Box, Button, Typography, Grid } from "@material-ui/core";
import Listings from "src/components/frontend/Listings";
import Modal from "src/components/Modal";

import {
	useCollectionState,
	useCategoryState,
	useGlobalState,
	useTokenState,
	useListingState,
	useErrorState,
	useProjectsState,
	useSessionState,
} from "src/state";
import Loading from "src/components/Loading";

import { shortAccount, tokenLinkURL, contractLinkURL, networkName } from "src/helpers/blockchain.helper";
import YoutubeVideoPlayer from "src/components/frontend/YoutubeVideoPlayer";

import emptyIcon from "src/themes/default/assets/img/empty-list-bg.png";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import Timeline from "src/components/frontend/Timeline";
import { numberFormat } from "src/helpers/number.helper";

const Collection = ({ mode }) => {
	const { cid: collectionID } = useParams();
	const { promised: isCollectionLoading, getCollection } = useCollectionState();
	const { promised: isProjectLoading, getProject } = useProjectsState();
	const { promised: isTokenLoading, tokens } = useTokenState(collectionID);
	const { promised: isListingLoading, listings: allListings } = useListingState();
	const { setRedirectToAfterConnect } = useGlobalState();
	const { profile } = useSessionState();
	const { setErrorMessage } = useErrorState();

	const navigate = useNavigate();
	const location = useLocation();

	const blockchainInfo = useContext(BlockchainContext);

	const [openVideoPopup, setOpenVideoPopup] = useState(false);
	const [playingVideo, setPlayingVideo] = useState(false);

	const { promised: isCategoriesLoading, getCategory, categories } = useCategoryState();

	const collection = !isCollectionLoading ? getCollection(collectionID) : {};

	if (!collection) {
		navigate("/404", { replace: true });
		return null;
	}

	const project = !isProjectLoading ? getProject(collection.projectID) : {};
	if (collection.archived && (!profile || !profile.userID || project.created_by !== profile.userID)) {
		navigate(profile?.userID ? "/profile/projects" : "/404", { replace: true });
		return null;
	}

	const listingCategory = collection.categoryID && !isCategoriesLoading ? getCategory(collection.categoryID) : null;

	const listings = isListingLoading
		? []
		: allListings.filter(item => item.collectionID === collectionID && !item.purchased);

	const openVideo = videoURL => {
		setPlayingVideo(videoURL);
		setOpenVideoPopup(true);
	};

	const handleModalClose = () => {
		setOpenVideoPopup(false);
		setPlayingVideo(false);
	};

	const handleAddTokenButton = () => {
		if (
			blockchainInfo &&
			blockchainInfo.account === collection.owner &&
			blockchainInfo.networkId === collection.chainID
		)
			navigate("add-item");
		else setErrorMessage(`Connect to "${networkName(collection.chainID)}" to add items under this collection`);
	};

	const handleConnect = () => {
		if (
			blockchainInfo &&
			(blockchainInfo.account !== collection.owner || blockchainInfo.networkId === collection.chainID)
		) {
			setErrorMessage(
				`Connect to "${networkName(collection.chainID)}" with ${shortAccount(
					collection.owner
				)} wallet to add items under this collection`
			);
			return;
		}

		setRedirectToAfterConnect(location.pathname);
		navigate("/connect-wallet");
	};

	const AddTokenButton = () => {
		return (
			blockchainInfo &&
			blockchainInfo.account === collection.owner &&
			(blockchainInfo.networkId === collection.chainID ? (
				<Button variant="contained" onClick={handleAddTokenButton}>
					{tokens.length ? "Add More" : "Add Now"}
				</Button>
			) : (
				<Typography variant="h6">
					Connect to <strong>"{networkName(collection.chainID)}"</strong> to add items under this collection
				</Typography>
			))
		);
	};

	return isCollectionLoading ? (
		<Loading />
	) : (
		<Box className={`discover-area page-paddings ${mode === "embed" ? "embedded-view" : ""}`}>
			<Box className="container">
				{mode !== "embed" && (
					<>
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="section-title text-center">
									<h2 data-watermark="Collection">
										{collection.name} ({collection.symbol})
									</h2>
									<div className="em_bar">
										<div className="em_bar_bg"></div>
									</div>
									<Typography className="subtitle">
										NFT Collection (ERC{collection.contractType}) (
										<a href={tokenLinkURL(collection.chainID, collection.address)} target="_blank">
											{shortAccount(collection.address)}
										</a>
										)
									</Typography>
									<Typography color="textSecondary" variant="body2">
										created in {moment(collection.createdAt).format("MMM YYYY")} on{" "}
										{networkName(collection.chainID)} by{" "}
										<a href={contractLinkURL(collection.chainID, collection.owner)} target="_blank">
											{shortAccount(collection.owner)}
										</a>
									</Typography>
									{collection.campaignCommencementDate > 0 && (
										<Box sx={{ display: { xs: "block", md: "none" } }} my={2}>
											<button
												className="theme-btn"
												onClick={() => window["scrollToBlock"]("#collections")}
											>
												Contribute Now
											</button>
										</Box>
									)}
								</div>
							</div>
						</div>
						{collection.campaignCommencementDate > 0 && (
							<div className="row">
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
									<div className="mintable-guides-box text-center">
										<div className="mintable-guides-media">
											<i className="fa-regular fa-clock"></i>
										</div>
										<div className="mintable-guides-info">
											<h3 className="theme-title">
												{moment.unix(collection.campaignCommencementDate).format("DD MMM YYYY")}
											</h3>
											<p className="theme-description">Contract Start Date</p>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
									<div className="mintable-guides-box text-center">
										<div className="mintable-guides-media">
											<i className="fa-solid fa-money-bill-transfer"></i>
										</div>
										<div className="mintable-guides-info">
											<h3 className="theme-title">
												{numberFormat(collection.metadata.capital_request, 0)} USD
											</h3>
											<p className="theme-description">Capital Request</p>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
									<div className="mintable-guides-box text-center">
										<div className="mintable-guides-media">
											<i className="fa-solid fa-money-bill-trend-up"></i>
										</div>
										<div className="mintable-guides-info">
											<h3 className="theme-title">
												{collection.metadata.sales_return_on_capital}% in{" "}
												{collection.metadata.sales_return_period} months
											</h3>
											<p className="theme-description">Sales Return on Capital</p>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
									<div className="mintable-guides-box text-center">
										<div className="mintable-guides-media">
											<i className="fa-solid fa-dollar-sign"></i>
										</div>
										<div className="mintable-guides-info">
											<h3 className="theme-title">
												{numberFormat(collection.offeringSize, 0)} USD
											</h3>
											<p className="theme-description">Offering Size</p>
										</div>
									</div>
								</div>
							</div>
						)}
						{collection.youtube && <YoutubeVideoPlayer youtubeUrl={collection.youtube} />}
						{collection.description && (
							<Typography className="collection-description" sx={{ whiteSpace: "pre-line" }}>
								{collection.description}
							</Typography>
						)}
						{collection.payoutSchedule.length > 0 && (
							<Box my={3}>
								<Timeline
									title="Campaign Timeline"
									startsAt={collection.campaignCommencementDate}
									scheudle={collection.payoutSchedule}
								/>
							</Box>
						)}
					</>
				)}
				{collection.archived && (
					<div className="archived-message">
						This campaign has been archived and no longer available for further contributions
					</div>
				)}
				<div className="browse-product-box" id="collections">
					{isTokenLoading ? (
						<Loading />
					) : !tokens || !tokens.length ? (
						<Box sx={{ textAlign: "center" }}>
							<img src={emptyIcon} style={{ marginBottom: 32 }} />
							<Typography variant="h3" align="center" mb={3}>
								There are no items available in this collection!
							</Typography>
							<AddTokenButton />
						</Box>
					) : (
						<>
							<Listings
								tokens={tokens}
								collectionID={collectionID}
								title=""
								embedded={mode === "embed"}
								owner={`${shortAccount(collection.owner)} on ${networkName(collection.chainID)}`}
								showAddButton={
									mode !== "embed" && blockchainInfo && blockchainInfo.account === collection.owner
								}
								showConnectButton={
									profile &&
									mode !== "embed" &&
									!collection.archived &&
									project.created_by === profile.userID
								}
								onClickAddButton={handleAddTokenButton}
								onClickConnectButton={handleConnect}
							/>
						</>
					)}
				</div>
			</Box>
			<Modal
				open={openVideoPopup}
				fullWidth={true}
				onClose={handleModalClose}
				content={<YoutubeVideoPlayer youtubeUrl={playingVideo} />}
			/>
		</Box>
	);
};

export default Collection;
