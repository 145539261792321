const Terms = () => {
	return (
		<section className="faq-area page-paddings">
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2 data-watermark="FAQ">Terms &amp; Conditions</h2>
							<div className="em_bar">
								<div className="em_bar_bg"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="faq-box-main">
							<div className="faq-box">
								<p className="c7">
									<span className="c4 c1 c3">
										These Terms and Conditions (&ldquo;Terms and Conditions&rdquo;) and any terms
										expressly incorporated herein apply to your access to and/or use of any services
										made available by Food Starter Pte Ltd, a company incorporated and organized
										under the laws of Singapore (&ldquo;Food Starter &rdquo;) through foodstarter.io
										(&ldquo;Website&rdquo;), mobile applications and/or APIs/Developer Tools and any
										other related services provided by Food Starter , including the Platform and the
										Services (&ldquo;Food Starter Services&rdquo;).
										<br />
										<br />
										The terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to Food
										Starter and its Affiliates and related companies.
										<br />
										<br />
										We reserve the right to modify these Terms and Conditions at any time and/or
										when required by law. We will notify Users of any changes to these Terms and
										Conditions by email (sent to the email address as specified in your Account)
										and/or by means of a notice published on the Platform. Such modifications will
										be effective and binding on you upon posting such modifications onto the
										Platform or such other timing as Food Starter may specify in writing.{" "}
									</span>
								</p>
								<p className="c7">
									<span className="c1 c3">
										You acknowledge that it is your responsibility to check for notifications
										regularly and the updated Terms and Conditions upon such notifications. You
										further agree that any notification of modifications in the manner as aforesaid
										shall be sufficient notice to you and your continued access to and/or use of the
										Food Starter Services following the notification of any modifications to these
										Terms and Conditions indicates your acceptance of any such modifications.
										<br />
										<br />
										These Terms and Conditions constitute a binding agreement between you and Food
										Starter. By clicking a checkbox or a button that contains words such as &ldquo;I
										Agree&rdquo; when these Terms and Conditions are presented to you or, if
										earlier, by accessing or using the Food Starter Services, you agree to be bound
										by, and to comply with, these Terms and Conditions. If you do not accept these
										Terms and Conditions, you must stop accessing and/or using the Food Starter
										Services. The Privacy Policy and Cookies Policy and all other applicable rules,
										policies and procedures posted on the Platform constitute a part of and are
										hereby incorporated by reference in their entirety into these Terms and
										Conditions.
										<br />
										<br />
										If you have queries, comments or concerns regarding the use of the Website, any
										Services or these Terms and Conditions, please contact&nbsp;
									</span>
									<span className="c0">
										<a className="c2" href="mailto:support@Worldfoodchain.io">
											support@foodstarter.io
										</a>
									</span>
									<span className="c1 c3">
										<br />
										<br />
										1. GENERAL TERMS
										<br />
										The Food Starter Exchange is a digital platform (the &ldquo;Platform&rdquo;)
										that enables storage, transfer, obtaining, exchanging and trading of Crypto and
										Fiat currencies (hereinafter the&nbsp;&lsquo;Services&rsquo;).
										<br />
										<br />
										You acknowledge and agree that, whenever a transaction takes place between you
										and other user(s), the Platform does not act as an intermediary but only
										provides a Platform to complete the transaction(s) between the two parties, the
										buyer and the seller.
										<br />
										<br />
										2.{" "}
									</span>
									<span className="c1 c3">ELIGIBILITY</span>
									<span className="c1 c3">
										<br />
										You represent and warrant that you are not listed on the Monetary Authority of
										Singapore&rsquo;s Lists of Designated Individuals and Entities, on any trade or
										economic sanctions lists, such as the UN Security Council Sanctions list,
										designated as a &ldquo;Specially Designated National&rdquo; by OFAC (Office of
										Foreign Assets Control of the U.S. Treasury Department) nor placed on the U.S.
										Commerce Department&rsquo;s &ldquo;Denied Persons List&rdquo;.
										<br />
										<br />
										Your place of residence determines the availability of the certain functions on
										the Website. It is your sole responsibility to get informed about those rules
										and regulations in your place of residence and to abide by them.
										<br />
										<br />
										(a) Individuals. You agree and understand that by accessing, or using the Food
										Starter Services, you have affirmed that you are at least 18 years old, have the
										legal capacity to consent to these Terms and Conditions, and agree to be bound
										by the policies and practices of these Terms and Conditions in their entirety.
										<br />
										<br />
										(b) Institutions. If you represent a User that is a legal entity
										(&ldquo;Institution&rdquo;), you have affirmed that you, as an individual is an
										authorized representative of the Institution with the authority to bind the
										Institution to these Terms and Conditions and the Institution has full power and
										authority to enter into these Terms and Conditions. By accepting these Terms and
										Conditions, you are accepting them on behalf of the Institution. In these Terms
										and Conditions, &ldquo;you&rdquo; and &ldquo;yourself&rdquo; include both you
										and such Institution.
										<br />
										<br />
										3.{" "}
									</span>
									<span className="c1 c3">FEES</span>
									<span className="c1 c3">
										<br />
										You agree to pay all fees described herein or otherwise posted on the Platform,
										as may be updated by Food Starter at its sole discretion from time to time. Any
										updates to fees will apply prospectively to any trades or transactions that take
										place on the Platform following the effective date of such updates. You hereby
										authorize Food Starter to deduct from your Account any applicable fees owed by
										you to Food Starter under these Terms and Conditions and you agree that Food
										Starter shall be entitled to set-off against any payment due from or payable to
										Food Starter hereunder, any sums you are obliged to pay or credit to Food
										Starter hereunder and Food Starter may by notice to you reduce any fees or any
										other charges, sums or monies due or payable to you hereunder by any amounts you
										are obliged to pay or credit to Food Starter&nbsp; hereunder. You are solely
										responsible for any fees charged by third parties in connection with your use of
										the Services, including without limitation fees charged by financial
										institutions in connection with your transfer of Legal Tender or from an
										External Account.
										<br />
										<br />
										4.{" "}
									</span>
									<span className="c1 c3">RISK DISCLOSURE</span>
									<span className="c1 c3">
										<br />
										You should be aware that the risk of loss in trading or holding Digital
										Currencies can be substantial. By using the Services, you accept all risks of
										acquiring, trading, holding or otherwise transacting with Digital Currencies
										through the Services (including Digital Currencies Transactions). You may lose
										Digital Currencies, your Digital Currencies may lose some or all of their value,
										you may be unable to acquire, transfer, trade, lend or access Digital
										Currencies, you may, as a result of your trading activities, owe Digital
										Currencies greater than the amount available in your Account and you may suffer
										other types of financial loss. You should not acquire any Digital Currencies
										unless you can afford to lose the entire value of those Digital Currencies. You
										are responsible for making an independent evaluation of the merits and risks,
										and the security, integrity, operation and platform of any Digital Currencies
										that you decide to acquire or trade. You represent and warrant that you have
										sufficient knowledge, experience, understanding and information to make such
										evaluations and have taken such professional advice as you deem necessary.
										<br />
										<br />
										5.{" "}
									</span>
									<span className="c1 c3">DEFINITIONS</span>
									<span className="c1 c3">
										<br />
										&ldquo;Account&rdquo; or &ldquo;Food Starter Account&rdquo; means an individual
										or corporate account to use the Services.
										<br />
										<br />
										&ldquo;Affiliate&rdquo; means in relation to a party, any company, partnership,
										trust or other entity directly or indirectly controlled by, controlling or under
										common control with that party, where &ldquo;control&rdquo; or its derivatives
										or variants for the purpose of this definition shall mean with regard to an
										party, the legal, beneficial or equitable ownership, directly or indirectly, of
										fifty percent (50%) or more of the issued capital or voting rights (or other
										ownership interest, if not a corporation) of such entity, or the equivalent
										right under contract or otherwise, to control or cause the direction of
										management and policy decisions of such entity with regard to relevant subjects.
										<br />
										<br />
										&rdquo;Business Day&rdquo; means a day on which banks are open for general
										banking business in Singapore (not being a Saturday, Sunday or public holiday in
										Singapore).
										<br />
										<br />
										&ldquo;Digital Currency&quot; or &quot;Digital Currencies&rdquo; means a digital
										representation of value based on (or built on top of) a cryptographic protocol
										of a computer network (including without limitation those referred to as
										&ldquo;cryptocurrency&rdquo;, &ldquo;token&rdquo;, &ldquo;virtual
										currency&rdquo;, &ldquo;digital currency&rdquo; or &ldquo;virtual
										commodity&rdquo;), such as Bitcoin or Ethereum.
										<br />
										<br />
										&ldquo;Digital Currency Transactions&rdquo; means the following types of
										transaction involving a Digital Currency and undertaken on the Platform: (i) the
										transfer of Digital Currencies into a User&rsquo;s Account; (ii) the withdrawal
										of Digital Currencies from a User&rsquo;s Account; and (iii) the trading of
										Digital Currencies for Legal Tender or other Digital Currencies.
										<br />
										<br />
										&ldquo;External Bank Account&rdquo; means an account in your name at a financial
										institution from or to which you intend to transfer or transfer Legal Tender.
										<br />
										<br />
										&ldquo;Funds&rdquo; means, in relation to each User, the balance of Digital
										Currencies and/or Legal Tender, as the context requires, in such User&rsquo;s
										Account.
										<br />
										<br />
										&ldquo;Legal Tender&rdquo; means a currency designated as legal tender in a
										particular country or jurisdiction, such as Singapore Dollars or United States
										Dollars.
										<br />
										<br />
										&ldquo;Legal Tender Transaction&rdquo; means the following types of transaction
										involving Legal Tender and undertaken on the Platform: (i) the transfer of Legal
										Tender into a User&rsquo;s Account; (ii) the withdrawal of Legal Tender from a
										User&rsquo;s Account; and (iii) the trading of Legal Tender for Digital
										Currency(ies).
										<br />
										<br />
										&ldquo;Order&rdquo; means a request by a User on the Platform to buy or sell the
										relevant Approved Currencies.
										<br />
										<br />
										&ldquo;Password&rdquo; means, in relation to each User, the valid password that
										such User may use in conjunction with the relevant Username to access and/or use
										the Services.
										<br />
										<br />
										&ldquo;Trade&rdquo; or &ldquo;Trading&rdquo; means the acquisition or
										disposition of Approved Currencies by a User in exchange for other Approved
										Currencies or Legal Tender of another User on the Platform.
										<br />
										<br />
										&ldquo;User&rdquo; refers to a registered user of the Services.
										<br />
										<br />
										&ldquo;Username&rdquo; means, in relation to each User, the unique login
										identification name or code which identifies such User.
										<br />
										<br />
										6.{" "}
									</span>
									<span className="c1 c3">ACCOUNT REGISTRATION &amp; REQUIREMENTS</span>
									<span className="c1 c3">
										<br />
										<br />
										(a) Registration
										<br />
										In order to access and/or use the Services, you will need &nbsp;to register for
										an account on the Platform (an &ldquo;Account&rdquo; or a &ldquo;Food Starter
										Account&rdquo;) by providing your full legal name and email address, as well as
										accept these Terms and Conditions, our Privacy Policy and Cookies Policy and all
										other applicable rules, policies and procedures. You agree to provide complete
										and accurate information when registering for an Account and agree to promptly
										update any information you provide to Food Starter so that such information is
										complete and accurate at all times. Food Starter may, in its sole discretion,
										refuse to open an Account for you.
										<br />
										<br />
										If you request to create an Account, a Username and Password may either be: (a)
										determined by and issued to you by Food Starter ; or (b) provided by you and
										accepted by Food Starter&nbsp; in its sole discretion, in connection with the
										access to and/or use of the Services. Food Starter may at any time, in its sole
										discretion, forthwith invalidate the Username and/or Password without giving any
										reason or prior notice and without prejudice to other provision in these Terms
										and Conditions, Food Starter shall not be held liable for any damage, loss or
										harm arising out of such invalidation. The Username and Password is for each
										User&rsquo;s personal use only and neither may be transferred to any other
										person or entity.
										<br />
										<br />
										(b) User Identity Verification
										<br />
										You agree to provide us with the information we request for the purposes of
										identity verification and the detection of money laundering, terrorist
										financing, fraud or any other financial crime and permit us to keep a record of
										such information. You will need to complete certain certification procedures
										before you are permitted to use the Services and your access to one or more
										Services, and the limits that apply to your use of the Services, may be altered
										as a result of information collected on an ongoing basis. The information we
										request may include certain personal information, including but not limited to,
										your name, address, telephone number, email address, date of birth, taxpayer
										identification number, government identification number, and information
										regarding your bank account (including the name of the bank, account type,
										routing number and account number). In providing us with this or any other
										information that may be required, you confirm that the information is accurate
										and authentic. You agree to keep us updated if any of the information you
										provide changes. If there is any reasonable doubt that any information provided
										by you is wrong, untruthful, outdated or incomplete, Food Starter shall have the
										right to send you a notice to demand corrections, remove relevant information
										directly and, as the case may be, terminate all or part of the Services to you.
										You shall be solely and fully responsible for any loss or expenses incurred
										during the use of the Services if you cannot be reached through the contact
										information provided.
										<br />
										<br />
										The information we collect is used for the detection of money laundering,
										terrorist financing, fraud and other financial crimes on the Platform. We will
										collect, use and share this information in accordance with our Privacy Policy.
										In addition to providing this information, to facilitate compliance with global
										industry standards for data retention, you agree to permit us to keep a record
										of such information for the lifetime of your Account plus five (5) years beyond
										the closing of your Account.
										<br />
										<br />
										BY SIGNING UP FOR AN ACCOUNT YOU HEREBY AUTHORIZE FOOD STARTER TO MAKE
										INQUIRIES, WHETHER DIRECTLY OR THROUGH THIRD PARTIES, THAT FOOD STARTER
										CONSIDERS NECESSARY TO VERIFY YOUR IDENTITY OR PROTECT YOU AND/OR FOOD STARTER
										AGAINST FRAUD OR OTHER FINANCIAL CRIMES, AND TO TAKE ACTION FOOD STARTER
										REASONABLY DEEMS NECESSARY BASED ON THE RESULTS OF SUCH INQUIRIES. YOU ALSO
										ACKNOWLEDGE AND AGREE THAT YOUR PERSONAL INFORMATION MAY BE DISCLOSED TO CREDIT
										REFERENCE AND FRAUD PREVENTION OR FINANCIAL CRIME AGENCIES AND THAT THESE
										AGENCIES MAY RESPOND TO OUR INQUIRIES IN FULL.
										<br />
										<br />
										(c) Account Security
										<br />
										Food Starter strives to maintain the safety of those user funds entrusted to us
										and has implemented industry standard protections for the Services. In a bid to
										provide a safe trading environment and advanced security for every Food Starter
										Account, Food Starter has in place a Two-Factor Authentication which is
										mandatory for every User to enable. In the event a User disables the Two-Factor
										Authentication for any reason whatsoever, the User must re-enable the Two-Factor
										Authentication before using the Services.
										<br />
										<br />
										However, there are risks that are created by individual user actions. You agree
										to consider your access credentials such as Username and Password as
										confidential information and not to disclose such information to any third
										party. You also agree that you alone are responsible for taking necessary safety
										precautions to protect your own Account and personal information.
										<br />
										<br />
										You shall be solely responsible for the safekeeping of your Food Starter Account
										and the relevant Username and Password on your own, and you shall be fully
										responsible for all instructions, requests, transactions and activities under
										such Food Starter Account (whether authorised by you or not), and Food Starter
										shall not be under any duty to investigate, enquire into or ascertain whether
										any such instructions, requests, transactions or activities are authorized,
										authentic, legal, valid or enforceable or whether any transaction or the
										underlying contract connected with such transaction is void or voidable, or
										whether any transaction is permitted under the applicable terms and conditions
										governing the relevant Approved Currency (if any). Food Starter shall be
										entitled to assume that all activities are authorised, authentic, legal, valid
										and enforceable (as the case may be), that all transactions and the underlying
										contracts connected with such transactions are not void or voidable, and that
										all transactions are permitted under the applicable terms and conditions
										governing the relevant Approved Currency (if any), and Food Starter shall be
										under no duty to reject, reverse, adjust or modify any instruction, request,
										transaction or activity by reason that it was not legal, valid or enforceable,
										that any transaction or the underlying contract connected with such transaction
										is void or voidable, or that any transaction is not permissible under the
										applicable terms and conditions governing the relevant Approved Currency (if
										any). Food Starter will not be responsible for any loss or consequences of
										authorized or unauthorized access and/or use of your Account credentials
										(including Username and Password) including but not limited to information
										disclosure, information posting, consent to or submission of various rules and
										agreements by clicking on the Website, online renewal of agreement, etc.
										<br />
										<br />
										By creating an Account, you hereby agree that: (i) you will notify Food Starter
										immediately if you are aware of any unauthorized access and/or use of your Food
										Starter Account and the relevant Username and Password by any person or entity
										or any other violations to the security rules or breach of security; (ii) you
										will strictly observe the security, authentication, dealing, charging,
										withdrawal mechanism or procedures of the Platform and/or Service; (iii) you
										will log out from the Platform by taking proper steps at the end of every visit;
										(iv) any access or use (or purported access or use) of the Platform and any
										information, data, instructions or communications referable to your Username and
										Password shall be deemed to be, as the case may be: (a) access or use of the
										Platform by you; and/or (b) information, data, instructions or communications
										carried out, transmitted or validly issued by you. Food Starter shall be
										entitled (but not obliged) to act upon, rely on and/or hold you solely
										responsible and liable in respect thereof as if the same were carried out,
										transmitted or validly issued by you. You further agree and acknowledge that you
										shall be solely responsible for and bound by any access or use of the Platform,
										any information, data, instructions or communications referable to your Username
										and Password, and any activity that takes place under your Account, whether
										authorised by you or not. (v) you authorise Food Starter to perform the Services
										in the manner described in these Terms and Conditions and other applicable terms
										and conditions; (vi) any records created and maintained by Food Starter of the
										information, data, instructions, communications, transactions or operations made
										or performed, processed or effected through the Platform or in relation to
										Platform, Accounts, Approved Currencies and/or Legal Tender by you or any other
										User or any person purporting to be you or such other User, acting on behalf or
										purportedly acting on behalf of you or such other User, shall be binding on you
										or such other User for all purposes whatsoever and shall be conclusive evidence
										of such information, data, instructions, communications, transactions or
										operations; (vii) the Funds available in your Account as determined by Food
										Starter from its records shall, save for manifest error, be deemed to be
										conclusive and binding against you; and (viii) you will honour all Orders placed
										through your Account and all transactions executed involving your Account.
										<br />
										<br />
										7. Know-Your-Client (&ldquo;KYC&rdquo;), Anti-Money Laundering and Counter
										Terrorism Financing (&ldquo;AML/CTF&rdquo;)
										<br />
										Food Starter reserves the right to perform due diligence checks on you pursuant
										to any regulatory requirements, and requests for you to provide such
										documentation/information as may be necessary to satisfy itself of your
										identity, and the purposes of use of the Food Starter Services (&ldquo;Customer
										Due Diligence&rdquo;). Such Customer Due Diligence may include and are not
										limited to taking enhanced due diligence measures on politically exposed persons
										and/or customers which present a higher risk for money laundering or terrorism
										financing as will be determined by Food Starter &rsquo;s internal guidelines.
										Food Starter further reserves the right to impose certain trading limits on you
										where Food Starter have not yet conducted any Customer Due Diligence on. In
										connection therewith, Food Starter reserves the right to suspend and/or
										terminate your Account if you fail to comply or cooperate with Food Starter or
										fail to satisfy in relation to the due diligence checks and/or requests under
										this Clause 7. Further, Food Starter reserves the right to file suspicious
										transaction reports in confidentiality to the relevant government agencies.
										<br />
										<br />
										8. Currencies
										<br />
										Food Starter will in its sole discretion determine which types of Digital
										Currencies and Legal Tender will be supported for transfer, storage, lending,
										trading or any other activities through the Services (collectively,
										&ldquo;Currencies&rdquo;). Food Starter gives no assurances that any Digital
										Currency which is the subject of a fork (hard, soft, velvet or otherwise) will
										be supported through the Services.
										<br />
										<br />
										Among the Currencies, the specific Currencies that you are permitted to
										transfer, store, trade or otherwise interact with through the Services are
										referred to as &ldquo;Approved Currencies&rdquo;. When you access the Services,
										only transactions involving Approved Currencies will be available to you. The
										Approved Currencies available to you are based on the jurisdiction of which you
										are a citizen or resident and any other factors determined by Food Starter in
										its sole discretion. The Approved Currencies available to you may differ from
										the Approved Currencies available to other Users.
										<br />
										<br />
										Without notice and for any reason, Food Starter may suspend, discontinue or
										terminate support for a Currency on the Services, remove a Currency from the
										list of Approved Currencies or otherwise suspend or terminate your ability to
										withdraw, transfer, trade, store or otherwise interact with any Approved
										Currency. However, Food Starter will use commercially reasonable efforts to
										notify you at least 14 days (where practicable) prior to taking any action that
										would permanently (or for such duration as Food Starter may determine at its
										sole discretion) prevent you from withdrawing the affected Funds from your
										Account. If you do not withdraw the affected Funds from your Account before your
										access to an applicable Currency through the Service is suspended or terminated,
										then you may irrevocably lose your ability to withdraw, transfer, trade, store
										or otherwise access or control the affected Funds. Food Starter will not be
										liable to you for any loss, liability or expenses related to its decision to
										suspend, discontinue or terminate support for a Currency on the Services, remove
										a Currency from the list of Approved Currencies or otherwise suspend or
										terminate your ability to withdraw, transfer, trade, store or otherwise interact
										with any Currency.
										<br />
										<br />
										9. Digital Currency Services
										<br />
										You represent and warrant that you will comply with the terms and conditions
										governing the relevant Approved Currencies (if any) and all terms and conditions
										applicable to any Digital Currency wallets that you use to transfer Approved
										Currencies to or from your Food Starter Account. Food Starter is not responsible
										for the management and security of any such wallets or the failure of any
										transfer of Approved Currencies between your Food Starter Account and such
										Digital Currency wallets (including where such transaction is not supported by
										such Digital Currency wallets).
										<br />
										<br />
										The value of Digital Currencies which Users transfer to Food Starter does not
										earn any interest, and Food Starter does not carry any specific insurance in
										relation to such Digital Currencies.
										<br />
										<br />
										(a) Digital Currency Transactions
										<br />
										<br />
										(i) Transfer of Approved Currencies into Food Starter Account
										<br />
										Subject to applicable laws, the availability of the Services and any other
										limitations or conditions contained in these Terms and Conditions, on the
										Website or of the Platform or otherwise (including Digital Currencies and the
										sending and receiving Digital Currency wallets), you may at any time transfer
										one or more Approved Currencies from your Digital Currency wallet into your
										Account by sending such amount of Approved Currencies to the receiving addresses
										designated by Food Starter to you from time to time for the relevant Approved
										Currencies (&ldquo;Digital Currency Address&rdquo;). Your Food Starter Account
										balance will be updated to reflect such quantity of Approved Currencies received
										by Food Starter at the relevant Digital Currency Address. The Approved
										Currencies that are reflected in your Food Starter Account balance at any time
										will be located at Digital Currency Addresses (or such replacement addresses)
										owned and managed by Food Starter , and such Digital Currencies may be held by
										Food Starter together with Food Starter &rsquo;s own Digital Currencies and
										those of other Users. Food Starter and/or its third party service provider store
										and manage the Digital Currency private keys associated with such Digital
										Currency Addresses, which are necessary for Digital Currency Transactions to be
										approved. You will not have access to such private keys. Food Starter and/or its
										third party service provider may store such private keys and related information
										in a combination of online and offline storage. You acknowledge that the
										retrieval of information from offline storage may delay the initiation,
										processing or execution of Digital Currency Transactions, as further set out in
										Clause 9(c) below.
										<br />
										<br />
										(ii) Withdrawal of Approved Currencies from Food Starter Account
										<br />
										Subject to applicable laws, the availability of the Services and any other
										limitations or conditions contained in these Terms and Conditions, on the
										Website or of the Platform or otherwise (including Digital Currencies and the
										sending and receiving wallets), you may submit a request on the Platform to
										withdraw balance Digital Currencies from your Account at any time to any of your
										designated Digital Currency wallet addresses.
										<br />
										<br />
										You hereby authorize Food Starter to transfer the type and amount of Approved
										Currencies specified by you from your Account to your designated Digital
										Currency wallet addresses specified by you at the time of the submission of the
										relevant withdrawal request and to deduct any fees applicable to such withdrawal
										that Food Starter or its third-party service providers may charge from such
										transferred amount.
										<br />
										<br />
										Without prejudice to Clause 9(c), as we will manually verify and process all
										withdrawals of Digital Currencies, we will endeavour to process your withdrawal
										request within three (3) Business Days. We may suspend the submission and/or
										processing of withdrawal requests at any time for system maintenance or in any
										such situations where it is reasonable to do so. For the avoidance of doubt,
										this shall include a situation where a technical failure arises in the protocol
										of the relevant Digital Currency.
										<br />
										<br />
										You agree that it is your sole responsibility to provide us with the accurate
										withdrawal details (i.e. withdrawing Digital Currency wallet address) when
										submitting your request to withdraw your balance Digital Currencies from your
										Account. Food Starter is not able to reverse any transfers. Without prejudice to
										other provision in these Terms and Conditions, you agree that Food Starter shall
										not be held liable for any claims, losses or damages which may arise as a result
										of your failure to receive the Digital Currencies which you have withdrawn, due
										to your provision of any incorrect and/or outdated withdrawal details.
										<br />
										<br />
										(iii)Transmission Delays
										<br />
										Food Starter makes no guarantee regarding the amount of time it may take to
										complete any Digital Currency Transaction. Food Starter and/or its third party
										service provider store Digital Currency private keys associated with Digital
										Currency Addresses, in a combination of online and offline storage, and such
										private keys are the means by which we can securely execute a Digital Currency
										Transaction. As a result of our security protocols, it may be necessary for us
										to retrieve private keys or related information from offline storage in order to
										facilitate and process a Digital Currency Transaction in accordance with your
										instructions, and you acknowledge that this may delay the initiation, debiting
										and crediting or execution of such Digital Currency Transaction. Without
										prejudice to other provision in these Terms and Conditions, you agree and
										acknowledge that Food Starter shall not be held liable for any claims, losses or
										damages which may arise from such delays.
										<br />
										<br />
										(iv) Incorrect Transactions
										<br />
										You agree that it is your sole responsibility to ensure that any Digital
										Currency Transaction which you undertake shall be accurate, well-formatted and
										denominated in the correct cryptocurrency. Without prejudice to other provision
										in these Terms and Conditions, you agree that Food Starter shall not be held
										liable for any claims, losses or damages arising as a result of any incorrect
										transfers, withdrawals, Orders, Trades and/or other transactions made through
										the Services.
										<br />
										<br />
										10. Legal Tender Transactions (Where Applicable)
										<br />
										External Bank Accounts must be in your name and not the name of a third party.
										Food Starter may limit the number of External Bank Accounts that you may
										designate to be associated with your Food Starter Account. You are solely
										responsible for your use of any External Bank Account, and you represent and
										warrant that you will comply with all terms and conditions applicable to any
										External Bank Account that you use to transfer Legal Tender to or from your Food
										Starter Account. Food Starter is not responsible for the management and security
										of any External Bank Account or the failure of any transfer of Legal Tender
										between your Food Starter Account and an External Bank Account (including where
										such transaction is not supported by the External Bank Account).
										<br />
									</span>
									<span className="c1">
										<br />
									</span>
									<span className="c1 c8">
										Any and/or such transfer of Legal Tender between an External Bank Account and
										your Food Starter Account will be powered by checkout.com. As such, please also
										refer to the checkout.com Terms of Service and Privacy Policy for further
										information.
										<br />
										<br />
									</span>
									<span className="c1 c3">
										The value of Legal Tender which Users transfer to Food Starter does not earn any
										interest and Food Starter does not carry any specific insurance in relation to
										such Legal Tender.
										<br />
										<br />
										(a) Transfer of Legal Tender into Food Starter Account
										<br />
										Subject to applicable laws, the availability of the Services and any other
										limitations or conditions contained in these Terms and Conditions, on the
										Website or of the Platform or otherwise, you may at any time initiate a transfer
										of Legal Tender from your External Bank Account into your Food Starter Account.
										Your Food Starter Account balance will be updated to reflect such amount of
										Legal Tender received by Food Starter in the relevant bank account designated by
										Food Starter .<br />
										<br />
										(b) Withdrawal of Legal Tender from Food Starter Account
										<br />
										Subject to applicable laws, the availability of the Services and any other
										limitations or conditions contained in these Terms and Conditions, on the
										Website or of the Platform or otherwise, you may submit a request on the
										Platform to withdraw balance Legal Tender from your Food Starter Account at any
										time to a External Bank Account at any time.
										<br />
										<br />
										You hereby authorize Food Starter to transfer the type and amount of Legal
										Tender specified by you from your Food Starter Account to a verified external
										bank account specified by you at the time of the submission of the relevant
										withdrawal request via the Services and to deduct any fees applicable to such
										withdrawal. Food Starter or its third-party service providers may charge from
										such transferred amount.
										<br />
										<br />
										You agree that it is your sole responsibility to provide us with the accurate
										withdrawal details (i.e. External Bank Account) when submitting your request to
										withdraw your balance Legal Tender from your Food Starter Account. Food Starter
										is not able to reverse any transfers. Without prejudice to other provision in
										these Terms and Conditions, you agree that Food Starter shall not be held liable
										for any claims, losses or damages which may arise as a result of your failure to
										receive the Legal Tender which you have withdrawn, due to your provision of any
										incorrect and/or outdated withdrawal details.
										<br />
										<br />
										(c) Transmission Delays
										<br />
										Food Starter makes no guarantee regarding the amount of time it may take to
										complete any Legal Tender Transaction, which is dependent on a variety of
										factors, including the performance of third parties responsible for maintaining
										the applicable External Bank Account.
										<br />
										<br />
										(d) Incorrect Transactions
										<br />
										You agree that it is your sole responsibility to ensure that any Legal Tender
										Transaction which you undertake shall be accurate, well-formatted and
										denominated in the correct currency. Without prejudice to other provision in
										these Terms and Conditions, you agree that Food Starter shall not be held liable
										for any claims, losses or damages arising as a result of any incorrect
										transfers, withdrawals, Orders, Trades and/or other transactions made through
										the Services. <br />
										<br />
										11. Trading
										<br />
										(a) Funds
										<br />
										If your Account has been opened and activated, and you have sufficient Funds in
										your Account, you will be able to engage in Trading on the Platform. You will be
										required to maintain in your Account a sufficient balance of the applicable
										Funds to satisfy any open Orders, your required margin and applicable fees. If
										you have an insufficient amount of Funds in your Account to fulfill an Order,
										Food Starter may cancel the entire Order or may fulfil an Order partially to the
										extent it can be covered by the Funds in your Account (after deducting any fees
										payable to Food Starter in connection with the Trade).
										<br />
										<br />
										(b) Orders
										<br />
										To initiate a Trade, you must submit an Order. Once you have submitted an Order,
										we will attempt to match your Order with another Order submitted by another
										User. We are under no obligation to match any Order submitted by you, and we
										maintain sole discretion over whether any Order is matched and whether any Trade
										is executed. There will be no contract between you and another User until the
										Order has been matched and a Trade has been executed.
										<br />
										<br />
										Further to the above, you agree that: (i) it is your sole responsibility to
										ensure the accuracy of each Order submitted through your Account; and (ii) you,
										and for all purposes all Users shall be deemed to have, have personal knowledge
										of every Order placed through your/their Account(s).
										<br />
										<br />
										(c) Counterparty
										<br />
										If your Order is successfully matched with the Order submitted by another User,
										and a Trade has been executed (including where we have not exercised our
										discretion to prevent that Order from being fulfilled), such User shall become
										your counterparty to the executed Trade (your &ldquo;Counterparty&rdquo;). Food
										Starter therefore does not serve as a central counterparty in such executed
										Trades entered between you and your Counterparty on the Platform, and does not
										have any contractual relationship with you and/or your Counterparty in relation
										to such executed Trades. Notwithstanding the above, Food Starter reserves the
										right to trade on the Platform as a User and upon execution of such Trade, as a
										Counterparty but you acknowledge and agree that in all other cases, whenever a
										transaction takes place between you and other User(s) on the Platform, Food
										Starter does not act as an intermediary but only provides a platform to complete
										the transaction(s) between the two parties, the buyer and the seller. We match
										all Users anonymously and do not provide Users with information about their
										Counterparties.
										<br />
										<br />
										You hereby authorize Food Starter to transfer the relevant type and amount of
										Approved Currencies or Legal Tender (as the case may be) from your Account to
										your Counterparty&rsquo;s Account for the purpose of settling the Trade between
										you and your Counterparty, and to deduct any fees applicable to such transfer
										and settlement that Food Starter or its third-party service providers may charge
										from your Funds.
										<br />
										<br />
										(d) Trading Hours
										<br />
										Generally, trading on the Platform will trade continuously from 0000hrs to
										2359hrs on every day of the year. We may suspend trading and/or access to the
										Platform at any time for system maintenance or for other reasons such as
										maintaining an orderly market. Without prejudice to other provision in these
										Terms and Conditions, in the event of such suspension of trading, you agree that
										Food Starter shall not be held liable for any claims, losses or damages arising
										as a result of any suspension of trading on the Platform pursuant to this Clause
										11(d).
										<br />
										<br />
										(e) Suspension of Trading
										<br />
										As part of Food Starter &rsquo;s goal to maintain an orderly market, Food
										Starter shall be entitled at its sole and absolute discretion to suspend trading
										on the Platform, in the event of any market disruption or any other relevant
										external events which may disrupt the trading on the Platform (e.g. hacking of
										the Platform). Without prejudice to other provision in these Terms and
										Conditions, in the event of such suspension of trading, you agree that Food
										Starter shall not be held liable for any claims, losses or damages arising as a
										result of any suspension of trading on the Platform pursuant to this Clause
										11(e).
										<br />
										<br />
										12. API / Developer Tools
										<br />
										Food Starter may make available certain public or authenticated development
										applications through the Services, including without limitation Food Starter
										&rsquo;s application programming interface and any accompanying or related
										documentation, source code, executable applications and other materials, and any
										other resources or services available on the Website (&ldquo;API / Developer
										Tools&rdquo;). Please refer to terms and conditions on API/Developer Tools
										contained on the Website.
										<br />
										<br />
										13. NO FINANCIAL ADVICE / Potential Risks
										<br />
										Food Starter is not your broker, intermediary, agent or advisor and has no
										fiduciary relationship or obligation to you in connection with any Trades or any
										other transactions, decisions or activities carried out by you using the Food
										Starter Services.
										<br />
										<br />
										Food Starter does not provide legal, financial, trading, tax or any other kind
										of specialised or expert advice in connection with any Digital Currencies or any
										Trades or other transactions, decisions or activities carried out by you using
										the Food Starter Services. No communication or information provided to you by
										Food Starter is intended to or shall be considered or construed as advice. You
										understand that any recommendations or commentary made by Food Starter or its
										employees or representatives or other Users should be considered general in
										nature and you must use your own judgment or seek the advice of an expert before
										taking any action as a result of any such recommendation or commentary. Food
										Starter gives no assurances as to the accuracy, reliability, performance,
										fitness for purpose, satisfactory quality, timeliness or completeness of any
										such recommendation or commentary.
										<br />
										<br />
										By using the Food Starter Services, you confirm that you have understood and
										that you accept the risks associated with Digital Currencies and Digital
										Currency Transactions, including without limitation, the following:
										<br />
										<br />
										(a) Digital Currencies are highly volatile assets and are based on their
										respective decentralized monetary protocols which are still in its experimental
										stage and may be subject to substantial changes at any time. Any malfunction,
										flaws, breakdown or abandonment of any a decentralized monetary protocol may
										have a material adverse effect on the relevant Digital Currencies. Furthermore,
										developments in cryptographic technologies and techniques or changes in
										consensus protocol or algorithms could present risks to the relevant Digital
										Currencies;
										<br />
										<br />
										(b) The trading of Digital Currencies may be subject to market risk that
										investment performance of the relevant cryptocurrencies cannot be predicted or
										guaranteed, and that any decisions you make to trade in Digital Currencies as
										facilitated by this Platform or the Services will be based solely on your own
										evaluation of your financial circumstances, investment objectives, risk
										tolerance, liquidity needs and any other factors that you deem relevant. You
										should pay close attention to your positions, and how you may be impacted by
										sudden and adverse shifts in trading and other market activities;
										<br />
										<br />
										(c) The trading of Digital Currencies exposes you to operational risk. This is
										the risk that you may suffer a loss because of a malfunction or disruption of
										the Services, arising from scheduled or unscheduled downtimes, matching system
										failure, database failure, Digital Currencies transfer or storage failure,
										failure or malfunction of the API, hacker attacks or other attacks by malicious
										groups or organisations which may attempt to interfere with Digital Currencies,
										Orders and/or Trades or steal Digital Currencies or other failures or
										malfunctions;
										<br />
										<br />
										(d) The holding and/or trading of Digital Currencies may expose you to
										regulatory risk. This is the risk that you may suffer a loss pursuant to the
										restriction or termination of certain aspects of the Services in response to
										existing regulations or future regulatory changes or actions. Further to the
										above, the legal status of some cryptocurrencies may be uncertain. Users shall
										be solely responsible for understanding the regulation and/or legal treatment of
										the relevant cryptocurrencies under the applicable law;
										<br />
										<br />
										(e) The tax treatment and accounting of Digital Currencies is uncertain and may
										vary amongst jurisdictions. Users shall be solely responsible for obtaining
										independent tax advice in connection with Trades, which may result in adverse
										tax consequences; and
										<br />
										<br />
										(f) Digital Currencies may only be accessed with a combination of private key
										and password. Loss of requisite private key(s) associated with the relevant
										Digital Currency Addresses may result in the loss of the relevant Digital
										Currencies which will be unrecoverable and permanently lost.
										<br />
										<br />
										14. Account Termination
										<br />
										You may terminate your Account at any time and for whatever reason upon giving
										us written notice of such termination, by contacting&nbsp;
									</span>
									<span className="c0">
										<a className="c2" href="mailto:support@Worldfoodchain.io">
											support@Foodstarter.io
										</a>
									</span>
									<span className="c1 c3">
										<br />
										<br />
										In connection therewith, Food Starter reserves the right to seek all remedies
										available at law and/or in equity for violations of these Terms and Conditions
										and other applicable terms and conditions.
										<br />
										<br />
										You agree and accept that: (i) Food Starter can suspend, restrict or terminate
										your Account, and/or deny or limit your access to your Account and/or the Food
										Starter Services at its sole discretion without providing any explanations and
										without prior notice, including the grounds as set out below; and (ii) Food
										Starter &rsquo;s decision to take certain actions, including but not limited to
										limiting access to, suspending, or terminating your Account, may be based on
										confidential criteria that are essential to Food Starter &rsquo;s risk
										management and security protocols, and (iii) Food Starter is under no obligation
										to disclose the details of its risk management and security procedures to you or
										any other Users:
										<br />
										<br />
										(a) when the activity from or attributable to your Account violates applicable
										laws, these Terms and Conditions and/or other applicable terms and conditions
										and/or infringes the intellectual property rights of Food Starter or any
										relevant third parties;
										<br />
										<br />
										(b) a subpoena, court order, or binding order of a government authority on the
										subject has been issued in relation to you or your Account;
										<br />
										<br />
										(c) there have been actual or suspected attempts by you to access the Food
										Starter Services in an unauthorized way and/or assisting others in such
										unauthorized access;
										<br />
										<br />
										(d) there have been actual or suspected attempts by you to break the security
										features of the software comprised in Food Starter Services and/or taking
										advantage of such breaches;
										<br />
										<br />
										(e) there have been actual or suspected attempts by you to use the Food Starter
										Services for any illegal or unlawful activities, including but not limited to
										money laundering, illegal gambling, terrorism, etc.);
										<br />
										<br />
										(f) your Account is subject to any pending litigation, investigation, or
										government proceeding and/or Food Starter perceives a heightened risk of legal
										or regulatory non-compliance associated with such Account;
										<br />
										<br />
										(g) your action(s) amount to circumventing Food Starter &rsquo;s controls,
										including, but not limited to, opening multiple Accounts or abusing promotions
										which Food Starter may offer from time to time;
										<br />
										<br />
										(h) you failed to, avoided or delayed in making any fee payments;
										<br />
										<br />
										(i) you have abused or threatened other Users and/or Food Starter staff;
										<br />
										<br />
										(j) there have been actual or suspected attempts by User to manipulate the
										market and/or raise the trading volume artificially; and/or
										<br />
										<br />
										(k) there has been a request of the law enforcement institution or a government
										agency.
										<br />
										<br />
										Food Starter reserves the right to undertake any necessary technical and/or
										legal measures to prevent any User&rsquo;s access to and/or use of such
										User&rsquo;s Account and/or the Food Starter Services, if Food Starter suspect
										threats or abuses to its intellectual property and violations of these Terms and
										Conditions.
										<br />
										<br />
										You understand that if your Account is unconfirmed or inactive (i.e. have not
										been confirmed, accessed and/or used for 12 months or longer), such Account will
										also be terminated by Food Starter . It shall solely be each User&rsquo;s
										responsibility to ensure Funds in the unconfirmed or inactive Account have been
										withdrawn. Without prejudice to other provision in these Terms and Conditions,
										Food Starter shall not be held liable for terminating such unconfirmed or
										inactive Accounts, irrespective of the Funds remaining in such Accounts.
										<br />
										<br />
										The suspension, restriction or termination of your Account may result in the
										cancellation of any Orders that you have submitted and the unwinding of any
										Trades and/or positions that you have entered into. We expressly exclude any
										liability for the losses and/or profits you would have made as a result of any
										cancellation of Orders, unwinding of Trades, early closure of your positions or
										your suspension or restriction from trading on the Platform and you agree to
										fully indemnify, defend and hold harmless the Platform, Food Starter , its
										Affiliates, related companies and their directors, shareholders, officers,
										employees, agents, licensors, third party service providers, and any other third
										party providing service to Food Starter in relation to the Services whether
										directly or indirectly, from and against any and all losses, liabilities,
										claims, damages, costs and expenses (including legal fees, and any fines, fees
										or penalties imposed by any regulatory authority) arising out of or related to
										third party action resulting from your conduct, any cancellation of Orders,
										unwinding of Trades, any early closure of your positions or your suspension or
										restriction from trading on the Platform by Food Starter .<br />
										<br />
										Whilst your Account is suspended, Food Starter may conduct investigations and
										may require you to cooperate with its enquiries. During the investigation stage,
										you will not be able to make transfers or withdrawals to or from your Account
										nor will you be able to Trade or submit Orders. At the end of the investigation,
										Food Starter may, at its sole discretion, decide to close your Account for which
										Food Starter is not required to provide you with any reasons for the same.
										<br />
										<br />
										You acknowledge and agree that your Account can be blocked due to inability
										and/or denial to provide the required identification documents and/or any other
										data necessary for the Customer Due Diligence procedure and/or for any other
										reason.
										<br />
										<br />
										The termination of an Account for any reason shall not affect obligations,
										rights or liabilities which have accrued or may accrue as at the date of
										termination or thereafter and any obligations which are intended to survive the
										termination of an Account whether or not such survival is specified herein.
										<br />
										<br />
										In case of any concerns and/or questions related to the termination procedure
										please contact us via&nbsp;
									</span>
									<span className="c0">
										<a className="c2" href="mailto:support@worldfoodchain.io">
											support@foodstarter.io
										</a>
									</span>
									<span className="c1 c3">
										.<br />
										<br />
										15. Representations and Warranties
										<br />
										You hereby represent and warrant to and undertake with Food Starter that:
										<br />
										<br />
										(a) You are authorized to access and use the Food Starter Services. In
										particular, the jurisdiction where you reside, hold citizenship or conduct
										business allows you to utilise the Services;
										<br />
										<br />
										(b) You are not a United States resident;
										<br />
										<br />
										(c) You are over 18 years old and have full legal capacity to enter into these
										Terms and Conditions; (d)Without prejudice to the generality of the foregoing,
										you satisfy, and will on an ongoing basis satisfy, the eligibility requirements
										as set out in Clause 2 of these Terms and Conditions;
										<br />
										<br />
										(e) You are the legal and beneficial owner of the Approved Currencies that you
										have transferred/are transferring into your Account;
										<br />
										<br />
										(f) All the Approved Currencies transferred into your Account have been derived
										from legitimate/legal sources;
										<br />
										<br />
										(g) The access and/or use of the Food Starter Services do not infringe or
										violate any local law of your country of residence;
										<br />
										<br />
										(h) You will only apply for and hold a single Account with us;
										<br />
										<br />
										(i) (If you are using the Food Starter Services as an individual) You are not
										acting on someone else&rsquo;s behalf;
										<br />
										<br />
										(j) (If you are using the Food Starter Services on behalf of or for the benefit
										of an Institution) You are an authorized representative of the Institution with
										the full power and authority to enter into and bind the Institution to these
										Terms and Conditions, and the Institution has full power and authority to enter
										into and be bound by these Terms and Conditions, and you are authorised to
										transact on behalf of the organisation or corporate entity and the Institution
										will be liable for your actions including any breach of this Agreement; and
										<br />
										<br />
										(k) You understand the risks associated with trading in a cryptocurrency
										exchange and you agree that the use of the Food Starter Services is at your own
										risk. You agree that Food Starter is not liable for any damage, loss or harm
										arising out of your access and/or use of the Food Starter Services.
										<br />
										<br />
										(l) Notwithstanding the fact that Food Starter administers the Services and runs
										the Website, you understand and agree that Food Starter may also trade as a
										Counterparty on the Website; and
										<br />
										<br />
										You agree that:
										<br />
										(a) The information provided on the Food Starter Services is for general
										information purposes only and is given in good faith. The information should not
										be construed as a recommendation to trade or engage the Service provided by Food
										Starter in a particular manner. Nothing in the Food Starter Services shall be
										construed as an offer to sell or a solicitation of an offer to purchase any
										securities where it would be illegal to do so;
										<br />
										<br />
										(b) Notwithstanding the fact that Food Starter administers the Services and runs
										the Platform, you understand and agree that Food Starter may also trade as a
										User on the Platform;
										<br />
										<br />
										(c) You shall cooperate with Food Starter and provide such necessary documents
										and/or information as may be requested by Food Starter as part of the Customer
										Due Diligence process and/or the relevant investigations as may be performed by
										Food Starter ;<br />
										<br />
										(d) Food Starter gives no warranty about the Food Starter Services. Without
										limitation to the foregoing, Food Starter does not warrant that the Food Starter
										Services will meet your requirements, that it will be suitable for your
										purposes, accurate, reliable or correct or that the Food Starter Services will
										be available any particular time or location, and Food Starter does not warrant
										that any defects or errors will be corrected. For the avoidance of doubt, all
										statutory or implied conditions or warranties are excluded unless otherwise
										prohibited under applicable laws, including any warranties of title,
										non-infringement of third party rights, merchantability or satisfactory quality;
										<br />
										<br />
										(e) Food Starter does not warrant that the Food Starter Services and access
										and/or use thereof will be uninterrupted, timely, secure or error free. Among
										other things, the operation and availability of the systems used for accessing
										the Food Starter Services, computer equipment/networks (which may be provided by
										a third party) and the Internet, can be unpredictable and may from time to time
										interfere with or prevent access to the Food Starter Services. Without prejudice
										to other provision in these Terms and Conditions, you agree that Food Starter
										shall not be held liable in any way for any such interference that prevents your
										access or use of the Food Starter Services; and
										<br />
										<br />
										(f) Food Starter may, from time to time and without giving any reason or prior
										notice, upgrade, modify, suspend or discontinue the provision of or remove the
										Food Starter Services (or part thereof), and shall not be liable if any such
										upgrade, modification, suspension or discontinuation prevents any User from
										accessing the Food Starter Services (or part thereof).
										<br />
										<br />
										Notwithstanding other provisions in these Terms and Conditions, Food Starter
										warrants that when it trades as a User on the Platform, it shall at all times
										trade in good faith with the relevant Users and refrain from doing any act which
										might either directly or indirectly give rise to a conflict of interest with
										such Users, always provided that nothing in this Clause 14 will prevent Food
										Starter from carrying out its normal business activities on behalf of itself
										where no such conflict exists.
										<br />
										<br />
										Accordingly, you agree to assume full responsibility and risk of loss resulting
										from your use of the Food Starter Services.
										<br />
										<br />
										You undertake not to:
										<br />
										(a) use or upload any software or material that contains, or which you have
										reason to suspect contains, computer virus or other malicious, destructive or
										corrupting code, agent, program or macros;
										<br />
										<br />
										(b) upload or transmit any materials through the Platform which are or may be
										illegal, misleading, erroneous, objectionable, defamatory, harassing, abusive,
										harmful, pornographic, &ldquo;x-rated&rdquo; or obscene content;
										<br />
										<br />
										(c) use the Platform other than in conformance with the acceptable use policies
										of any connected computer networks, any applicable Internet standards and any
										other applicable laws; or
										<br />
										<br />
										(d) impersonate any person or entity or to falsely state or otherwise
										misrepresent your affiliation with any person or entity.
										<br />
										<br />
										16. LIMITATION OF LIABILITY AND INJUNCTIVE RELIEF
										<br />
										THE USER UNDERSTANDS AND EXPRESSLY AGREES THAT UNLESS OTHERWISE PROHIBITED UNDER
										APPLICABLE LAWS, IN NO EVENT WILL FOOD STARTER , ITS AFFILIATES, RELATED
										COMPANIES OR ANY OF THEIR OFFICERS, EMPLOYEES, DIRECTORS, EMPLOYEES,
										SHAREHOLDERS, AGENTS, LICENSORS, THIRD PARTY SERVICE PROVIDERS, AND ANY OTHER
										THIRD PARTY PROVIDING SERVICE TO FOOD STARTER IN RELATION TO THE FOOD STARTER
										SERVICES WHETHER DIRECTLY OR INDIRECTLY, BE LIABLE TO THE USER OR ANYONE ELSE
										UNDER ANY THEORY OF LIABILITY (WHETHER IN CONTRACT, TORT, STATUTORY, OR
										OTHERWISE) FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
										EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF FUNDS,
										REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH
										PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
										DAMAGES), RESULTING FROM ANY ACCESS AND/OR USE OF THE FOOD STARTER SERVICES OR
										THE USER&rsquo;S USE OF THE ACCOUNT REGISTERED TO SUCH USER, OR THE USE OF
										ANYONE USING AN ACCOUNT REGISTERED TO THE USER, OR ANY UNAUTHORIZED USE OF THE
										USER&rsquo;S ACCOUNT OR ANY PARTS THEREOF AND INCLUDING:
										<br />
										<br />
										THE ACCURACY AND COMPLETENESS OF THE CONTENT ON THE SITE;THE ACCURACY AND
										COMPLETENESS OF THE CONTENT COMPRISED IN ANY FOOD STARTER SERVICES;THE ACCURACY
										AND COMPLETENESS OF THE CONTENT ON THE THIRD PARTY SITES LINKED TO ANY FOOD
										STARTER SERVICES (BY MEANS OF HYPERLINKS, BANNER ADS, ETC.);THE SERVICES OR THE
										SERVICES AVAILABLE ON ANY THIRD PARTY SITES LINKED TO THE PLATFORM (BY MEANS OF
										HYPERLINKS, BANNER ADS, ETC.);PERSONAL INJURIES OR PROPERTY DAMAGES OF ANY
										KIND;THIRD-PARTY CONDUCT OF ANY NATURE WHATSOEVER, INCLUDING ANY BREACH OF OR
										DELAY OR FAILURE OF ANY USER TO COMPLY WITH THESE TERMS AND CONDITIONS;ANY
										UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR CONTENT, INFORMATION (E.G.
										PERSONAL, FINANCIAL, ETC.) AND THE STORED DATA;ANY INTERRUPTION OF THE SERVICES
										OR THE SERVICES AVAILABLE ON ANY OTHER SITES LINKED TO THE PLATFORM (BY MEANS OF
										HYPERLINKS, BANNER ADS, ETC.);ANY VIRUSES, WORMS, BUGS, TROJAN HORSES, OR THE
										LIKE, WHICH MAY BE TRANSMITTED TO OR FROM THE PLATFORM OR ANY SITES LINKED TO
										THE PLATFORM (BY MEANS OF HYPERLINKS, BANNER ADS, ETC.);ANY OBJECTIONABLE,
										DEFAMATORY, HARASSING, ABUSIVE, HARMFUL, PORNOGRAPHIC, &ldquo;X-RATED&rdquo;,
										OBSCENE, ETC. CONTENT;ANY SUSPENSION, INTERRUPTION OR CLOSURE OF THE
										PLATFORM;ANY TECHNICAL, SYSTEM, SERVER OR CONNECTION FAILURE, ERROR, OMISSION
										INTERRUPTION OR DELAY IN TRANSMISSION; ANDANY TECHNICAL AND/OR SYSTEM FAILURE OF
										THE PLATFORM.
										<br />
										<br />
										WITHOUT PREJUDICE TO THE FOREGOING, THE USER ACKNOWLEDGES AND AGREES THAT ANY
										CAUSE OF ACTION RELATED TO THE USE OF THE FOOD STARTER SERVICES OUGHT TO BE
										COMMENCED WITHIN 12 MONTHS FROM THE TIME THE CAUSE OF THE ACTION HAS ARISEN.
										OTHERWISE, SUCH CAUSE OF ACTION WILL BE BARRED PERMANENTLY.
										<br />
										<br />
										WITHOUT PREJUDICE TO THE FOREGOING, THE USER EXPRESSLY ACCEPTS AND AGREES THAT
										IN NO EVENT WILL FOOD STARTER &rsquo;S TOTAL AGGREGATE LIABILITY FOR ANY AND ALL
										CLAIMS, SUITS, DEMANDS, ACTIONS OR OTHER LEGAL PROCEEDINGS UNDER, ARISING OUT OF
										OR RELATING TO THESE TERMS AND CONDITIONS OR RELATING TO OR ARISING IN
										CONNECTION WITH THE FOOD STARTER SERVICES, WHETHER BASED ON AN ACTION OR EVENT
										OR SERIES OF CONNECTED ACTIONS OR EVENTS ARISING FROM THE SAME FACTUAL ACT OR
										OMISSION, IN CONTRACT, NEGLIGENCE, TORT OR OTHERWISE, SHALL EXCEED THE TOTAL
										TRANSACTION FEE PAID BY THE USER TO FOOD STARTER FOR THE PARTICULAR SERVICE(S)
										THAT ARE SUBJECT TO THE CAUSE OF ACTION. THE FOREGOING LIMITATION OF LIABILITY
										APPLIES, UNLESS OTHERWISE PROHIBITED UNDER APPLICABLE LAWS, AND WILL SURVIVE ANY
										TERMINATION OR EXPIRATION OF THESETERMS AND CONDITIONS, OR THE ACCESS OR USE OF
										THE FOOD STARTER SERVICES.
										<br />
										<br />
										IT IS HEREBY ACKNOWLEDGED AND EXPRESSLY AGREED BY THE USER THAT FOOD STARTER OR
										THE PLATFORM IS NOT LIABLE FOR ANY TRANSACTIONS INITIATED BY THE USER ON THE
										PLATFORM.
										<br />
										<br />
										NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, THE USER HEREBY IRREVOCABLY
										WAIVES ANY RIGHT OR REMEDY TO SEEK AND/OR OBTAIN INJUNCTIVE OR OTHER EQUITABLE
										RELIEF OR ANY ORDER WITH RESPECT TO, AND/OR TO ENJOIN OR RESTRAIN OR OTHERWISE
										IMPAIR IN ANY MANNER, THE PRODUCTION, DISTRIBUTION, EXHIBITION OR OTHER
										EXPLOITATION OF ANY OF FOOD STARTER OR ANY OF ITS AFFILIATE OR RELATED COMPANY
										OR RELATED SERVICES, OR THE USE, PUBLICATION OR DISSEMINATION OF ANY ADVERTISING
										IN CONNECTION WITH SUCH SERVICES.
										<br />
										<br />
										THE USER EXPRESSLY AGREES TO FULLY INDEMNIFY, DEFEND AND HOLD HARMLESS THE
										PLATFORM, FOOD STARTER , ITS AFFILIATES, RELATED COMPANIES AND THEIR DIRECTORS,
										OFFICERS, EMPLOYEES, SHAREHOLDERS, AGENTS, LICENSORS, THIRD PARTY SERVICE
										PROVIDERS, AND ANY OTHER THIRD PARTY PROVIDING SERVICE TO FOOD STARTER IN
										RELATION TO THE SERVICES WHETHER DIRECTLY OR INDIRECTLY, FROM AND AGAINST ANY
										AND ALL LOSSES, LIABILITIES, CLAIMS, DAMAGES, COSTS AND EXPENSES (INCLUDING
										LEGAL FEES, AND ANY FINES, FEES OR PENALTIES IMPOSED BY ANY REGULATORY
										AUTHORITY) ARISING OUT OF OR RELATED TO YOUR BREACH OF THESE TERMS AND
										CONDITIONS ASSERTED AGAINST OR INCURRED BY FOOD STARTER THAT RISE OUT OF, RESULT
										FROM, OR MAY BE PAYABLE BY VIRTUE OF, ANY BREACH OR NON-PERFORMANCE OF ANY TERMS
										OF THESE TERMS AND CONDITIONS INCLUDING ANY REPRESENTATION, WARRANTY, COVENANT
										OR AGREEMENT MADE OR OBLIGATION TO BE PERFORMED BY THE USER PURSUANT TO THESE
										TERMS AND CONDITIONS OR YOUR VIOLATION OF ANY APPLICABLE LAW, RULE OR REGULATION
										OR THE RIGHTS OF ANY THIRD PARTY. THIS CLAUSE WILL SURVIVE ANY TERMINATION OR
										EXPIRATION OF THESE TERMS AND CONDITIONS, OR THE ACCESS OR USE OF THEFOOD
										STARTER SERVICES.
										<br />
										<br />
										17. Force Majeure
										<br />
										Without prejudice to other provision in these Terms and Conditions, Food Starter
										and/or the Platform shall not be liable for any failure to perform any of its
										obligations under these Terms and Conditions or provide the Food Starter
										Services or any part thereof if the performance is prevented, interrupted or
										delayed by a Force Majeure Event (defined below) and in such case its
										obligations shall be suspended for so long as the Force Majeure Event continues.
										<br />
										<br />
										&quot;Force Majeure Event&quot; means any event due to any cause beyond the
										reasonable control of Food Starter , including, without limitation, interruption
										in telecommunications or Internet services or network provider services, breach
										or virus in the systems, fire, flood, explosion, acts of God, civil commotion,
										act of civil or military authorities, riots, insurrection, war, acts of
										government.
										<br />
										<br />
										18. Governing Law
										<br />
										These Terms and Conditions shall be governed by and construed in accordance with
										Singapore law.
										<br />
										<br />
										19. Severability
										<br />
										If any provision of these Terms and Conditions is determined to be illegal,
										void, invalid or unenforceable by any legislation to which it is subject, in
										whole or in part under, such illegality, voidness, invalidity or
										unenforceability shall apply only to such provision or part of such provision
										and the remaining part of such provision and all other provisions of this
										Agreement shall continue to be in full force and effect.
										<br />
										<br />
										20. Assignment
										<br />
										You may not assign or transfer any right to access and/or use the Food Starter
										Services or any of your rights or obligations under these Terms and Conditions
										without prior written consent from us, including by operation of law or in
										connection with any change of control. Food Starter may assign or transfer any
										or all of its rights or obligations under these Terms and Conditions, in whole
										or in part, without notice or obtaining your consent or approval.
										<br />
										<br />
										21. Waiver
										<br />
										The failure or delay of a party to exercise or enforce its rights conferred upon
										it under these Terms and Conditions or require performance of any provision
										shall not be deemed to be a waiver of any such rights or operate so as to bar
										the exercise or enforcement thereof at any subsequent time or times nor shall a
										waiver of any breach or default of these Terms and Conditions or any provision
										of these Terms and Conditions constitute a waiver of any subsequent breach or
										default or a waiver of the provision itself.
										<br />
										<br />
										22. Third-Party Website Disclaimer
										<br />
										Any links to third-party websites from the Platform and/or Services do not imply
										endorsement by Food Starter of any products, services or information presented
										therein, nor does Food Starter guarantee the accuracy of the information
										contained on them. In addition, since Food Starter has no control over the terms
										of use or privacy practices of third-party websites, you should read and
										understand those policies carefully.
										<br />
										<br />
										23. English language controls
										<br />
										Any translation provided is solely for the User&rsquo;s convenience. The
										meanings of terms, conditions and representations herein are subject to
										definitions and interpretation in the English language. Any translation provided
										may not accurately represent the information in the original English. In case of
										any difference between the English version and the translated versions of these
										Terms and Conditions, the English version shall prevail.
										<br />
										<br />
										24. Headings
										<br />
										The headings and subheadings herein are included for convenience and
										identification only and are not intended to interpret, define or limit the
										scope, extent or intent of these Terms and Conditions in any manner whatsoever.
										<br />
										<br />
										The meaning of general words is not limited by specific examples introduced by
										expressions such as, &ldquo;including&rdquo;, &ldquo;for example&rdquo;,
										&ldquo;such as&rdquo;, or such similar expressions, and the word
										&ldquo;includes&rdquo; or &ldquo;including&rdquo; as used in these Terms and
										Conditions shall be construed to mean &ldquo;includes without limitation&rdquo;
										or, as the case may be, &ldquo;including without limitation&rdquo;.
										<br />
										<br />
										25. Entire Agreement
										<br />
										These Terms and Conditions constitute the entire agreement, and supersede all
										prior understandings, oral or written, between the parties regarding the subject
										of these Terms and Conditions.
										<br />
										<br />
										26. Support
										<br />
										In the event the User has any concern or questions in relation to any
										information uploaded on the Website, Food Starter Services and/or these Terms
										and Conditions and User&rsquo;s rights and obligations or with respect to
										processing of information or use of Website etc., the User may submit a written
										request to&nbsp;
									</span>
									<span className="c0">
										<a className="c2" href="mailto:support@worldfoodchain.io">
											support@foodstarter.io
										</a>
									</span>
									<span className="c1 c3 c4">
										. We shall endeavour to expeditiously redress the same, but within reasonable
										time as may be provided in applicable laws.
										<br />
										<br />
										27. Intellectual Property
										<br />
										Food Starter grants you a non-transferable, non-exclusive, revocable, limited
										license to use and access the Food Starter Services solely for your own
										personal, informational and non-commercial use, provided that you do not modify
										the Food Starter Services and that you retain all copyright and other
										proprietary notices contained in the Food Starter Services.
										<br />
										<br />
										Food Starter or its licensor(s) reserves and retains all rights (including
										copyrights, trademarks, patents as well as any other intellectual property
										right) in relation to the Food Starter Services. You may not do anything that
										will violate or infringe such intellectual property rights and, in particular,
										you shall not copy, download, publish, distribute, transmit, disseminate, sell,
										broadcast, circulate, exploit (whether for commercial benefit or otherwise) or
										reproduce any of the information or content contained in or provided on or via
										the Food Starter Services in any form without the prior written permission of
										Food Starter or its licensor(s). Further, no part or parts of the Food Starter
										Materials may be reproduced, distributed, republished, displayed, broadcast,
										hyperlinked, mirrored, framed, transferred or transmitted in any manner or by
										any means or stored in an information retrieval system without the prior written
										permission of Food Starter or its licensor(s).
										<br />
										<br />
										28. Rights of Third Parties
										<br />A person or entity who is not a party to these Terms and Conditions shall
										have no right under the Contracts (Rights of Third Parties) Act, Chapter 53B of
										Singapore or other similar laws to enforce any of these Terms and Conditions,
										regardless of whether such person or entity has been identified by name, as a
										member of a class or as answering a particular description. Food Starter
										&rsquo;s right to vary these Terms and Conditions may be exercised without the
										consent of any person or entity who is not a party to these Terms and
										Conditions. For the avoidance of doubt, nothing in this clause shall affect the
										rights of any permitted assignee or transferee of these Terms and Conditions.
										<br />
										<br />
										29. No Internet Access
										<br />
										The User agrees and acknowledges that these Terms and Conditions and the Food
										Starter Services do not include the provision of Internet access or other
										telecommunication services by Food Starter . Any Internet access or
										telecommunications services (such as mobile data connectivity) required by any
										User to access and use the Food Starter Services shall be such User&rsquo;s sole
										responsibility and shall be separately obtained by such User, at his own cost,
										from the appropriate telecommunications or internet access service provider.
										<br />
										<br />
										30. No Successors and Assigns
										<br />
										These Terms and Conditions will bind each User and Food Starter and their
										respective successors in title and assigns and will continue to bind each User
										notwithstanding any change in Food Starter &rsquo;s name or constitution or Food
										Starter &rsquo;s merger, consolidation or amalgamation with or into any other
										entity (in which case these Terms and Conditions will bind each User to Food
										Starter &rsquo;s successor entity).
										<br />
										<br />
										31. Sub-contracting and Delegation
										<br />
										Food Starter may delegate or sub-contract the performance of any of its
										functions in connection with the Food Starter Services.
									</span>
								</p>
								<p className="c5">
									<span className="c4 c1 c3"></span>
								</p>
								<p className="c5">
									<span className="c4 c1 c3"></span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Terms;
