import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import { Button, Box } from "@material-ui/core";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { useSessionState, useProjectsState, useCollectionState, useGlobalState } from "src/state";
import Loading from "src/components/Loading";
import CollectionsBlock from "src/components/frontend/Collections";

const ProjectsCollections = () => {
	const { profile } = useSessionState();
	const { promised: loadingProjects, getProject } = useProjectsState();
	const {
		promised: loadingCollections,
		getProjectCollections,
		createCollection,
		removeCollection,
		incrementCollectionCount,
	} = useCollectionState();
	const { setRedirectToAfterConnect } = useGlobalState();

	const location = useLocation();
	const navigate = useNavigate();
	const blockchainInfo = useContext(BlockchainContext);

	const [project, setProject] = useState(null);

	const { id: projectID } = useParams();

	useEffect(() => {
		if (projectID) {
			const projectDetails = getProject(projectID);
			if (projectDetails && projectDetails.created_by === profile.userID) {
				setProject(projectDetails);
			} else {
				navigate("/profile/projects");
			}
		}
	}, [projectID]);

	const connect = () => {
		//save the current URL to state
		//to redirect user back to this page
		setRedirectToAfterConnect(location.pathname);

		navigate("/connect-wallet");
	};

	const handleAddCollection = () => {
		//check if connected to blockchain
		if (!blockchainInfo.account) connect();

		navigate(location.pathname+"/add");
	};

	const collections = !loadingCollections ? getProjectCollections(projectID) : [];

	return (
		<>
			<section className="min-item-area page-paddings">
				<div className="container">
					{project && (
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="section-title text-center">
									<h2 data-watermark="Items">"{project.project_name}" {process.env.REACT_APP_COLLECTION_TITLE}</h2>
									<div className="em_bar">
										<div className="em_bar_bg"></div>
									</div>
									{collections.length > 0 && (
										<p className="subtitle">
											Manage {process.env.REACT_APP_COLLECTION_TITLE}s (your fund raising blocks) for this project
										</p>
									)}
								</div>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="user-panel-main">
								<div className="user-store-area">
									{loadingProjects || loadingCollections || !project ? (
										<div className="user-no-record-found">
											<Loading />
										</div>
									) : collections.length === 0 ? (
										<div className="user-no-record-found">
											<div className="user-no-record-icon">
												<i className="fas fa-store-alt"></i>
											</div>
											<h3 className="user-title">There are no {process.env.REACT_APP_COLLECTION_TITLE}s.</h3>
											<p className="theme-description">
												You have not added any {process.env.REACT_APP_COLLECTION_TITLE}s for this project yet!
											</p>
											<div className="user-btn">
												<Button
													onClick={handleAddCollection}
													size="large"
													variant="contained"
													className="theme-btn"
												>
													Add {process.env.REACT_APP_COLLECTION_TITLE}
												</Button>
											</div>
										</div>
									) : (
										<Box my={3}>
											<CollectionsBlock
												collections={collections}
												showEmpty={true}
												showAddButton={true}
												onClickAddButton={handleAddCollection}
											/>
										</Box>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ProjectsCollections;
