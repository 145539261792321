import { Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";
import BareLayout from "./layouts/BareLayout";
import Login from "./pages/access/Login";
import Signup from "./pages/access/Signup";
import VerifyEmail from "./pages/access/VerifyEmail";
import VerifyAccount from "./pages/access/VerifyAccount";
import NotFound from "./pages/NotFound";
import ComingSoon from "./pages/ComingSoon";
import AdminDashboard from "./pages/admin/Dashboard";
import AdminCollections from "./pages/admin/collections";
import AdminAddCollection from "./pages/admin/collections/Add";
import AdminCollectionTokens from "./pages/admin/collections/Tokens";
import AdminCollectionTokenSell from "./pages/admin/collections/Sell";
import AdminCategories from "./pages/admin/Categories";
import AdminSettings from "./pages/admin/Settings";
import AdminUsers from "./pages/admin/Users";

import AdminProjects from "./pages/admin/projects";
import AdminProjectsForm from "./pages/admin/projects/Form";

import Listing from "./pages/marketplace/Listing";
import Home from "./pages/marketplace/Home";
import SearchResults from "./pages/marketplace/SearchResults";
import Collection from "./pages/marketplace/Collection";
import AddToken from "./pages/marketplace/AddToken";
import AddCollecton from "./pages/marketplace/AddCollection";
import Browse from "./pages/marketplace/Browse";
import Project from "./pages/marketplace/Project";
import ConnectWallet from "./pages/access/Connect";

import ProfileSettings from "./pages/profile/settings";
import ProfileFavourites from "./pages/profile/favourites";
import ProfileCollections from "./pages/profile/collections";
import ProfileTokens from "./pages/profile/tokens";
import ProfileProjects from "./pages/profile/projects";
import ProfileProjectsAdd from "./pages/profile/projects-add";
import ProfileProjectsCollections from "./pages/profile/projects-collections";
import ProfileTransactions from "./pages/profile/transactions";
import ProfileEarnings from "./pages/profile/earnings";
import ProfileCertificate from "./pages/profile/certificate";

import Claim from "./pages/demo/Claim";
import Settle from "./pages/demo/Settle";
import About from "./pages/content/About";
import Contact from "./pages/content/Contact";
import FAQ from "./pages/content/Faq";
import Privacy from "./pages/content/Privacy";
import Terms from "./pages/content/Terms";
import ThankYou from "./pages/ThankYou";
import PaymentFailed from "./pages/PaymentFailed";

export const admin_base_path = "/admin";
export const listing_base_path = "/listing";

const routes = loggedInUser => {
	return loggedInUser?.userID && !loggedInUser.emailVerified && false
		? [
				{
					path: "/email-verification",
					element: <BareLayout />,
					children: [{ path: "/", element: <VerifyEmail /> }],
				},
				{
					path: "*",
					element: <Navigate to="/email-verification" />,
				},
		  ]
		: loggedInUser?.userID && !loggedInUser.profileSubmitted
		? [
				{
					path: "/",
					element: <MainLayout />,
					children: [
						{
							path: "/",
							element: <Home />,
						},
						{
							path: "/profile/settings",
							element: loggedInUser.profileSubmitted ? <ProfileSettings /> : <Navigate to="/verify" />,
						},
					],
				},
				{
					path: "/verify",
					element: <BareLayout />,
					children: [{ path: "/", element: <VerifyAccount /> }],
				},
				{
					path: "*",
					element: <Navigate to="/verify" />,
				},
		  ]
		: [
				{
					path: "admin",
					element:
						loggedInUser && loggedInUser.type === "admin" ? <DashboardLayout /> : <Navigate to="/login" />,
					children: [
						{ path: "/", element: <Navigate to="dashboard" replace={true} /> },
						{ path: "/dashboard", element: <AdminDashboard /> },
						{ path: "/collections", element: <AdminCollections /> },
						{ path: "/collections/add", element: <AdminAddCollection /> },
						{ path: "/collections/duplicate/:collectionID", element: <AdminAddCollection /> },
						{
							path: "/collections/:id",
							children: [
								{ path: "/tokens", element: <AdminCollectionTokens /> },
								{ path: "/tokens/list/:tokenId", element: <AdminCollectionTokenSell /> },
							],
						},
						{
							path: "/projects",
							children: [
								{ path: "/", element: <AdminProjects /> },
								{ path: "/new", element: <AdminProjectsForm /> },
								{ path: "/edit/:id", element: <AdminProjectsForm /> },
							],
						},
						{
							path: "/categories",
							element: <AdminCategories />,
						},
						{
							path: "/users",
							element: <AdminUsers />,
						},
						{
							path: "/transactions",
							element: <Navigate to="/coming-soon" replace={true} />,
						},
						{
							path: "/settings",
							element: <AdminSettings />,
						},
						{ path: "*", element: <Navigate to="/404" replace={true} /> },
					],
				},
				{
					path: "/login",
					element: <BareLayout />,
					children: [{ path: "/", element: <Login /> }],
				},
				{
					path: "/signup",
					element: <BareLayout />,
					children: [{ path: "/", element: <Signup /> }],
				},
				{
					path: "/verify",
					element: <BareLayout />,
					children: [{ path: "/", element: <VerifyAccount /> }],
				},
				{
					path: "/404",
					element: <BareLayout />,
					children: [
						{ path: "/", element: <NotFound /> },
						{ path: "*", element: <Navigate to="/404" replace={true} /> },
					],
				},
				{
					path: "/coming-soon",
					element: <MainLayout />,
					children: [
						{ path: "/", element: <ComingSoon /> },
						{ path: "*", element: <Navigate to="/coming-soon" replace={true} /> },
					],
				},
				{
					path: "/thank-you",
					element: <MainLayout />,
					children: [
						{ path: "/", element: <ThankYou /> },
						{ path: "*", element: <Navigate to="/thank-you" replace={true} /> },
					],
				},
				{
					path: "/payment-failed",
					element: <MainLayout />,
					children: [
						{ path: "/", element: <PaymentFailed /> },
						{ path: "*", element: <Navigate to="/payment-failed" replace={true} /> },
					],
				},
				{
					path: "/embed/:cid",
					element: <BareLayout />,
					children: [{ path: "/", element: <Collection mode="embed" /> }],
				},
				{
					path: "/",
					element: <MainLayout />,
					children: [
						{
							path: "/",
							element: <Home />,
						},
						{
							path: "/listing/:cid/:id",
							element: <Listing />,
						},
						{
							path: "/search/:keyword",
							element: <SearchResults />,
						},
						{
							path: "/collection/:cid",
							children: [
								{ path: "/", element: <Collection /> },
								{ path: "/add-item", element: <AddToken /> },
							],
						},
						{
							path: "/projects",
							children: [
								{ path: "/", element: <Browse /> },
								{ path: "/:pid", element: <Project /> },
							],
						},
						{
							path: "/project/:pid",
							element: <Project />,
						},
						{
							path: "connect-wallet",
							element: <ConnectWallet />,
						},
						{
							path: "profile",
							element: loggedInUser ? null : <Navigate to="/login" />,
							children: [
								{
									path: "/settings",
									element: <ProfileSettings />,
								},
								{
									path: "/favourites",
									element: <ProfileFavourites />,
								},
								{
									path: "/tokens",
									element: <ProfileTokens />,
								},
								{
									path: "/projects",
									children: [
										{
											path: "/",
											element: <ProfileProjects />,
										},
										{
											path: "/add",
											element: <ProfileProjectsAdd />,
										},
										{
											path: "/edit/:id",
											element: <ProfileProjectsAdd />,
										},
										{
											path: "/:id/collections",
											children: [
												{
													path: "/",
													element: <ProfileProjectsCollections />,
												},
												{
													path: "/add",
													element: <AddCollecton />,
												},
											],
										},
									],
								},
								{
									path: "/transactions",
									element: <ProfileTransactions />,
								},
								{
									path: "/earnings",
									element: <ProfileEarnings />,
								},
								{
									path: "/certificate",
									element: <ProfileCertificate />,
								},
							],
						},
						{
							path: "about",
							element: <About />,
						},
						{
							path: "contact",
							element: <Contact />,
						},
						{
							path: "faq",
							element: <FAQ />,
						},
						{
							path: "privacy",
							element: <Privacy />,
						},
						{
							path: "terms",
							element: <Terms />,
						},
						{ path: "*", element: <Navigate to="/404" replace={true} /> },
					],
				},
				{
					path: "/demo",
					element: <MainLayout />,
					children: [
						{
							path: "/",
							element: <Claim />,
						},
						{
							path: "/settle",
							element: <Settle />,
						},
					],
				},
		  ];
};

export default routes;
