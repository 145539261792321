import { useState } from "react";
import { useParams } from "react-router";
import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/system";
import Loading from "src/components/Loading";
import CollectionForm from "src/components/collection/CollectionForm";
import { cerateAndDeployCollection } from "src/helpers/collections.helper";
import { useCollectionState } from "src/state";

const AddCollectionContainer = styled("div")(({ theme }) => ({
	maxWidth: "800px",
	margin: "0 auto",
}));

const AddCollecton = () => {
	const [isSavingData, setIsSavingData] = useState(false);

	const {
		promised: loadingCollections,
		getProjectCollections,
		createCollection,
		removeCollection,
		incrementCollectionCount,
	} = useCollectionState();

	const { id: projectID } = useParams();

	const handleOnCreateCollection = async data => {
		setIsSavingData(true);

		cerateAndDeployCollection(data, createCollection, removeCollection)
			.then(() => {
				//update project collection count if, added to project
				if (data.projectID) {
					incrementCollectionCount(data.projectID);
				}
			})
			.finally(() => setIsSavingData(false));
	};

	return (
		<Box className="min-item-area page-paddings">
			<Box className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2 data-watermark="Collection">Add Campaign</h2>
							<div className="em_bar">
								<div className="em_bar_bg"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="browse-product-box">
					<AddCollectionContainer>
						<CollectionForm
							onCreate={handleOnCreateCollection}
							defaultValues={{ projectID }}
							isForFrontend={true}
						/>
					</AddCollectionContainer>
				</div>
			</Box>

			{isSavingData && <Loading />}
		</Box>
	);
};

export default AddCollecton;
